import React from 'react';
import SearchBar from '../SearchBar/SearchBar';
import { translate } from '../../../infrastructure/translations/translate';
import { MultiChoiceDropdown, MultiChoiceDropdownProps } from '../../selector/MultiChoiceDropdown/MultiChoiceDropdown';
import { ToolTipLight } from '../../tip/ToolTipLight/ToolTipLight';
import { StudyDataset } from '../../../domain/data/entries/StudyDataset';
import { Study } from '../../../domain/data/entries/Study';
import { useDatasets } from '../../hooks/useDatasets';
import { useStudies } from '../../hooks/useStudies';

/**
 * @description Format datasets ids into a string containing all datasets ids separated by a dash 
*/
export const formatDatasetsIds = (datasetsIds: string[]): string => '-' + datasetsIds.sort().join('-');

export const MultiChoiceDropdownDatasetResultReady = ({
	selectDatasetsIds,
	setSelectDatasetsIds,
	functionalUnit,
	constraint,
	excludedIds = [],
}: {
    selectDatasetsIds: string[];
    setSelectDatasetsIds: (value: string[]) => void;
    functionalUnit: string;
	excludedIds?: string[];
	constraint?: string;
}): JSX.Element => {
	const { studies } = useStudies();
	const { entity: datasetEntity, datasets } = useDatasets([]);

	const [filter, setFilter] = React.useState<string>('');
	const [tooltipStyle, setTooltipStyle] = React.useState<{ top: number, left: number}>();

	let constraintCompany: string | undefined = undefined;
	if (constraint) constraintCompany = studies?.find(s => s.datasetsId.includes(constraint))?.companyFk.id;

	const list: MultiChoiceDropdownProps['list'] = [];
	const onOpen = (id: string) => {
		const openedStudy = studies?.find(s => s.id === id);
		if (!openedStudy) return;
		const openedStudyDatasetsNotLoaded = openedStudy.datasetsId.some(d => !datasets?.find(ds => ds.id === d));
		if (openedStudyDatasetsNotLoaded) {
			datasetEntity?.queryDatasets({ datasetsIds: openedStudy.datasetsId });
		}
	};
	const tooltip = (e?: HTMLButtonElement) => {
		if (!e) {
			setTooltipStyle(undefined);
			return;
		}
		const rect = e.getBoundingClientRect();
		setTooltipStyle({
			top: rect.top + 20,
			left: rect.left
		});
	};
	const setDatasetElement = (name: string, complete?: boolean) => !complete ? <div
		className="dashboard_modal_dataset"
	>
		<div className="dashboard_modal_dataset_name">{name}</div>
		<div className="dashboard_modal_dataset_info">
			<i className="dashboard_modal_dataset_info_icon fa-regular fa-circle-exclamation"/>
		</div>
	</div> : name;

	const setDatasetObj = (d: StudyDataset) => ({
		id: d.id,
		element: setDatasetElement(d.name, d.status?.impactsComplete),
		selectable: true,
		dropChildren: [],
		disabled: !d.status?.impactsComplete,
		onFocus: (e: React.FocusEvent<HTMLButtonElement>) => !d.status?.impactsComplete && tooltip(e.target),
		onBlur: () => tooltip(),
		onMouseEnter: (e: React.MouseEvent<HTMLButtonElement>) => !d.status?.impactsComplete && tooltip(e.currentTarget),
		onMouseLeave: () => tooltip(),
	});

	const getStudyDatasets = (study: Study) => {
		const ds = datasets?.filter(ds => ds.studyId === study.id)
			.sort((a, b) => a.id.localeCompare(b.id))
			.map(d => setDatasetObj(d))
			?? [];
		ds?.unshift({
			id: formatDatasetsIds(ds.map(d => d.id)),
			element: setDatasetElement(translate('all.study') as string, ds.every(d => !d.disabled)),
			selectable: true,
			dropChildren: [],
			disabled: !ds.every(d => !d.disabled),
			onFocus: (e: React.FocusEvent<HTMLButtonElement>) => ds.every(d => d.disabled) && tooltip(e.target),
			onBlur: () => tooltip(),
			onMouseEnter: (e: React.MouseEvent<HTMLButtonElement>) => ds.every(d => d.disabled) && tooltip(e.currentTarget),
			onMouseLeave: () => tooltip(),
		});
		if (excludedIds.length) {
			return ds.filter(d => !excludedIds.includes(d.id));
		}
		return ds;
	};

	if (studies && datasets && functionalUnit.length) {
		studies.filter(
			s => constraintCompany ? s.companyFk.id === constraintCompany : true
		).filter(
			s => s.functionalUnit.name === functionalUnit && (filter === '' || s.name.toLowerCase().includes(filter.toLowerCase()) ||
                s.companyFk.name.toLowerCase().includes(filter.toLowerCase()) ||
                s.year.toString().includes(filter) ||
                datasets.filter(ds => ds.studyId === s.id).some(d => d.name.toLowerCase().includes(filter.toLowerCase())))
		).forEach((s) => {
			const c = list.find(l => l.id === s.companyFk.id);
			if (c) {
				c.dropChildren.push({
					id: s.id,
					element: `${s.name} - ${s.year}`,
					selectable: false,
					onOpen,
					dropChildren: getStudyDatasets(s),
				});
			} else {
				list.push({
					id: s.companyFk.id,
					element: s.companyFk.name,
					selectable: false,
					dropChildren: [{
						id: s.id,
						element: `${s.name} - ${s.year}`,
						selectable: false,
						onOpen,
						dropChildren: getStudyDatasets(s),
					}],
				});
			}
		});
	}

	return (<>
		<SearchBar
			searchValue={filter}
			setSearchValue={setFilter}
			navigationInfo={translate('dashboard.modal.searchbar.navigation.info') as string}
		/>
		<MultiChoiceDropdown
			values={selectDatasetsIds}
			onChange={setSelectDatasetsIds}
			list={list}
		/>
		{tooltipStyle && <ToolTipLight left={tooltipStyle.left} top={tooltipStyle.top}>
			{translate('dashboard.modal.dataset.tooltip.noAllImpacts') as string}
		</ToolTipLight>}
	</>);
};
