import { Entity } from '../core/Entity';
import { IResultLauncher } from '../data/entries/IResultLauncher';
import {
	ApolloClientMutateArchiveResults,
	ApolloClientMutateManageResultLauncher,
	ApolloClientQueryComputingHistory,
	ApolloClientQueryLauncherEquipmentsVersions
} from '../../infrastructure/ApolloClass/ResultLauncherClass';
import { QUERY_COMPUTING_HISTORY } from '../../infrastructure/ApolloClient/requests/QUERY_COMPUTING_HISTORY';
import { QUERY_LAUNCHER_EQUIPMENTS_VERSIONS } from '../../infrastructure/ApolloClient/requests/QUERY_LAUNCHER_EQUIPMENTS_VERSIONS';
import { MUTATE_MANAGE_RESULT_LAUNCHER } from '../../infrastructure/ApolloClient/requests/MUTATE_MANAGE_RESULT_LAUNCHER';
import { MUTATE_ARCHIVE_RESULTS } from '../../infrastructure/ApolloClient/requests/MUTATE_ARCHIVE_RESULTS';
import { QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS_VERSIONS } from '../../infrastructure/ApolloClient/requests/QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS_VERSIONS';
import { IQueryLauncherEquipmentsVersionsInput, IQueryLauncherEquipmentsVersionsOutput } from '../interfaces/IQueryLauncherEquipmentsVersions';
import { IMutateManageResultLauncherInput } from '../interfaces/IMutateManageResultLauncher';
import { IMutateArchiveResultsInput } from '../interfaces/IMutateArchiveResults';
import {
	IQueryLifeCycleAssessmentParametersVersionsInput,
	IQueryLifeCycleAssessmentParametersVersionsOutput
} from '../interfaces/IQueryLifeCycleAssessmentParametersVersions';
import { ApolloClientQueryLifeCycleAssessmentParametersVersions } from '../../infrastructure/ApolloClass/DatasetClass';

export class ResultLaunchersEntity extends Entity {
	public data: undefined | IResultLauncher[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateArchiveResults ??= this.app.installer(ApolloClientMutateArchiveResults, MUTATE_ARCHIVE_RESULTS);
		this.app.adapter.mutateManageResultLauncher ??= this.app.installer(ApolloClientMutateManageResultLauncher, MUTATE_MANAGE_RESULT_LAUNCHER);
		this.app.adapter.queryComputingHistory ??= this.app.installer(ApolloClientQueryComputingHistory, QUERY_COMPUTING_HISTORY);
		this.app.adapter.queryLauncherEquipmentsVersions ??= this.app.installer(ApolloClientQueryLauncherEquipmentsVersions, QUERY_LAUNCHER_EQUIPMENTS_VERSIONS);
		this.app.adapter.queryLifeCycleAssessmentParametersVersions ??= this.app.installer(ApolloClientQueryLifeCycleAssessmentParametersVersions, QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS_VERSIONS);
		this.app.adapter.storeDataResultLaunchers?.({ loading: false, error: null, data: this });
	}

	get(datasetsIds?: string[], sampleId?: string): IResultLauncher[] | undefined {
		if (!this.data) {
			if (this._isQuerying || !datasetsIds || (!datasetsIds && !sampleId)) return this.data;
			this.queryComputingHistory(datasetsIds, sampleId);
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDataResultLaunchers?.({ loading: false, error: null, data: this });
	}

	set(obj: IResultLauncher[] | undefined): void {
		this.data = obj;
		this.change();
	}

	updateArchivedStatus(rl_id: string, archived: boolean): void {
		const rl = this.data?.find(r => r.id === rl_id);
		if (rl) {
			rl.archived = archived;
			this.change();
		}
	}

	update(obj: IResultLauncher ): void {
		if (!this.data) return;
		this.data = this.data.map(line => {
			if (line.id === obj.id) {
				return obj;
			}
			return line;
		});
		this.change();
	}

	private _storeError(type: Error) {
		this.app.adapter.storeDataResultLaunchers?.({
			loading: false,
			error: type,
			data: this
		});
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryComputingHistory(datasetsIds: string[], sample_id: undefined | string): Promise<IResultLauncher[]> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryComputingHistory, { datasets: datasetsIds, sample_id }).catch(err => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		// Set data to an empty array if error like PermissionDenied to avoid infinite loop calling the get method
		if (!data) {
			this.set([]);
			return [];
		}
		this.set(data.computingHistory);
		return data.computingHistory;
	}

	async queryLauncherEquipmentsVersions(input: IQueryLauncherEquipmentsVersionsInput): Promise<IQueryLauncherEquipmentsVersionsOutput> {
		return this.callApi(this.app.adapter.queryLauncherEquipmentsVersions, input);
	}

	async queryLifeCycleAssessmentParametersVersions(input: IQueryLifeCycleAssessmentParametersVersionsInput): Promise<IQueryLifeCycleAssessmentParametersVersionsOutput> {
		return this.callApi(this.app.adapter.queryLifeCycleAssessmentParametersVersions, input);
	}

	async mutateManageResultLauncher(input: IMutateManageResultLauncherInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateManageResultLauncher, input).catch(err => {
			if (err === undefined) return;
			throw err;
		});
		if (!data) return false;
		if (input.delete) {
			// Remove the deleted item from the list
			this.set(this.data?.filter(line => line.id !== input.id));
			data.mutateManageResultLauncher.datasets.forEach(ds => {
				this.app.entities.datasets.updateResultStatus(ds.id, ds.status.results);
				this.app.entities.datasets.updateDefinitiveResultStatus(ds.id, ds.hasDefinitiveResults);
			});
			return true;
		}
		if (input.deleteAll) {
			// Remove all items except the definitive one
			this.set(this.data?.filter(line => line.id === data.mutateManageResultLauncher.resultLauncher.id));
		}
		this.update(data.mutateManageResultLauncher.resultLauncher);
		data.mutateManageResultLauncher.datasets.forEach(dataset => {
			this.app.entities.datasets.updateResultStatus(dataset.id, dataset.status.results);
			this.app.entities.datasets.updateDefinitiveResultStatus(dataset.id, data.mutateManageResultLauncher.resultLauncher.definitive);
			this.app.entities.datasets.updateLockStatus(dataset.id, dataset.collectOpen, dataset.validationOpen);
		});
		return true;
	}

	async mutateArchiveResults(input: IMutateArchiveResultsInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateArchiveResults, input);
		if (!data.mutateArchiveResults.ok) {
			return false;
		}
		this.updateArchivedStatus(input.rlId, true);
		return true;
	}

}
