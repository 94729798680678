import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseDocumentation } from '../../domain/data/ResponseDocumentation';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import {
	IQueryDocumentationFileInput,
	IQueryDocumentationFileOutput
} from '../../domain/interfaces/IQueryDocumentationFile';

export class ApolloClientQueryDocumentations extends ApolloClientProvider<ResponseDocumentation> {}
export class ApolloClientQueryDocumentationFile extends ApolloClientProvider<IQueryDocumentationFileOutput, IQueryDocumentationFileInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryDocumentationFileInput): Promise<IQueryDocumentationFileOutput | null | undefined> {
		return super.exec({ token, lang, adapter }, data);
	}
}