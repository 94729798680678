import { Entity } from '../core/Entity';
import { Documentation } from '../data/entries/Documentation';
import {
	ApolloClientQueryDocumentationFile,
	ApolloClientQueryDocumentations
} from '../../infrastructure/ApolloClass/DocumentationClass';
import { QUERY_DOCUMENTATIONS } from '../../infrastructure/ApolloClient/requests/QUERY_DOCUMENTATIONS';
import { QUERY_DOCUMENTATION_FILE } from '../../infrastructure/ApolloClient/requests/QUERY_DOCUMENTATION_FILE';

export class DocumentationEntity extends Entity {
	data: Documentation[] | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.queryDocumentationFile ??= this.app.installer(ApolloClientQueryDocumentationFile, QUERY_DOCUMENTATION_FILE);
		this.app.adapter.queryDocumentations ??= this.app.installer(ApolloClientQueryDocumentations, QUERY_DOCUMENTATIONS);
		this.app.adapter.storeDocumentation?.({ loading: false, data: this, error: null });
	}

	set(obj: Documentation[] | undefined) {
		this.data = obj;
		this.change();
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDocumentation?.({ loading: false, data: this, error: null });
	}

	update(): void {
		return;
	}

	get(): Documentation[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDocumentations();
		}
		return this.data;
	}

	getById(id: string): Documentation | undefined {
		return this.data?.find((doc) => doc.id === id);
	}

	private _storeError(err: Error) {
		this.app.adapter.storeDocumentation?.({
			loading: false,
			error: err,
			data: this,
		});
	}

	async queryDocumentations() {
		if (this._isQuerying) return;
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryDocumentations).catch((err) => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (data) {
			this.set(data.documentations);
		}
	}

	async queryDocumentationFile(id: string) {
		return this.callApi(this.app.adapter.queryDocumentationFile, { id });
	}
}