import { gql } from '@apollo/client';

export const QUERY_DOCUMENTATIONS = gql`
  query GetAllDocumentation {
    documentations {
      id
      title
      thumbnail
      description
      createdAt
      docType
      url
      file
      fileInfo
    }
  }
`;