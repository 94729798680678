import { gql } from '@apollo/client';

export const QUERY_DATASETS = gql`
    query QueryDatasets(
    	$datasetsIds: [String!]
        $studyId: String
    ) {
    	datasets (
    		datasetsIds: $datasetsIds
            studyId: $studyId
    	) {
            id,
            name,
            nameEn,
            nameFr,
            desc,
            archived,
            studyId,
            status {
                maturity,
                inventory,
                validated,
                results,
				impactsComplete
            },
            metadata {
                prioEquipRate,
                equipmentRate,
                prioSettingRate,
                settingRate,
                maturityRate
            },
            permissions {
                collectOpen,
                validationOpen,
                validate,
                results
            },
            selectedIndicators,
            validationSampleId,
            hasDefinitiveResults
            hasSettings
            labels
        }
    }
`;
