import { gql } from '@apollo/client';

export const QUERY_MY_STUDIES = gql`
    query QueryMyStudies {
        myStudies {
            companyFk {
                id
                name
                emailDomains
            }
            year
            name
            nameEn
            nameFr
            id
            archived
            datasetsId
            checklist
            labels
            functionalUnit {
                id
                name
                private
                checklist
                defaultLcaTemplate
            }
            consultants {
                id
                username
                firstName
                lastName
                email
                ssoEmail
                phoneNumber
                groups {
                    id
                    name
                }
            }
        }
    }
`;
