import { gql } from '@apollo/client';

export const MUTATE_COPY_DATASET = gql`
    mutation CopyDataset(
        $studyId: String!,
        $datasetId: String!,
        $empty: Boolean,
        $description: String!,
        $maturity: Boolean!,
        $validation: Boolean,
        $labels: [String!],
        $name: String,
        $nameEn: String,
        $nameFr: String,
    ) {
        mutateCopyDataset(
            studyId: $studyId,
            datasetId: $datasetId,
            empty: $empty,
            desc: $description,
            maturity: $maturity,
            validation: $validation,
            labels: $labels,
            name: $name,
            nameEn: $nameEn,
            nameFr: $nameFr,
        ) {
            studyId,
            newDataset {
                id,
                studyId,
                name,
                desc,
                archived,
                status {
                    maturity,
                    inventory,
                    validated,
                    results
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validate,
                    results,
                    validationOpen
                }
                hasDefinitiveResults
                hasSettings
                labels
            }
        }
    }
`;
