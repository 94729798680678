import React from 'react';
import { translate } from '../infrastructure/translations/translate';

export const Alert = ({
	variant,
	children,
	style
}: { variant: string, style?: React.CSSProperties } & React.PropsWithChildren): JSX.Element => {
	switch (variant) {
		case 'danger':
			return <div style={style} className={'alert alert_danger'}>{children}</div>;
		case 'success':
			return <div style={style} className={'alert alert_success'}>{children}</div>;
		case 'warning':
			return <div style={style} className={'alert alert_warning'}>{children}</div>;
		case 'info':
			return <div style={style} className={'alert alert_info'}>{children}</div>;
		case 'normal':
			return <div style={style} className={'alert alert_normal'}>{children}</div>;
		default:
			return <div style={style} className={'alert'}>{children}</div>;
	}
};
export const AlertError = (props: {  
	status?: string | React.ReactNode, 
	onClick?: ((error: string) => void) | ((error: React.ReactNode) => void)
}): JSX.Element => {
	return (
		<div className={'alert alert_danger'}>
			{props.onClick && <button className={'alert_close_button'} onClick={() => props.onClick ? props.onClick('') : () => void 0}>x</button>}
			{props.status}
		</div>
	);
};
export const AlertSuccess = ({ message }: { message?: string }): JSX.Element => {
	return (
		<div className={'alert alert_success'}>
			{translate(message ? message : 'alerts.valueSaved')}
		</div>
	);
};
