import React, { useEffect, useId } from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { GroupedBarChart, GroupedBarChartData } from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { translate } from '../../../../infrastructure/translations/translate';
import { DashboardComparisonMaturity } from '../../../../domain/interfaces/DashboardComparisonData';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../CreateDashboardComparisonBenchmarkModal';
import { createDashboardComparisonObjectiveModal } from '../CreateDashboardComparisonObjectiveModal';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { dashboardCompareToReference } from '../DashboardComparison';
import { DashboardPinButton } from '../../DashboardPin';
import { useModal } from '../../../../viewComponents/modal/useModal';
import { dashboardLabelName } from '../../DashboardLabelName';
import { TooltipItem } from 'chart.js/auto';
import {
	DashboardComparisonPluginGraphBarRefDiff,
	DashboardEvolutionPluginGraphBarRefDiff,
	DashboardPluginGraphBarLegend
} from '../../DashboardPluginGraphBarLegend';
import { DashboardSelectReference } from '../../DashboardSelectReference';
import { getColors } from '../../../../config';
import { DashboardMaturityDomainGraphData } from '../../DashboardMaturityDomainGraphData';

export const DashboardMaturityDomainComparisonGraph = ({ showTable, data, displayLegend }: { showTable: boolean, data: DashboardMaturityDomainGraphData, displayLegend?: boolean }) => {
	const { objective, graph: graphData, datasetRef } = data;
	const id = useId();
	const options: ChartProps<'bar'>['options'] = {
		layout: {
			padding: 10
		},
		maintainAspectRatio: false,
		aspectRatio: 3,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					title: (items: TooltipItem<'bar'>[]) => {
						return items[0].label.split(',').join(' ');
					}
				}
			}
		},
		scales: {
			y: {
				max: 5
			},
			x: {
				grid: {
					display: false
				},
				ticks: {
					maxRotation: 30,
					minRotation: 15,
				}
			}
		},
	};

	// Get the max number of colors needed by any element of graphData
	const colors = getColors(Math.max(...graphData.map(d => d?.values.length ?? 0)));
	return (<div className="dashboard_graph">
		{!showTable && <>
			<div className={'dashboard_chart_container'}>
				<GroupedBarChart
					data={graphData}
					datasetRef={datasetRef}
					options={options}
					thin={20}
					percentage={.8}
					categoryPercentage={.3}
					borderRadius={3}
					labelLength={30}
					colors={colors}
					plugins={[DashboardComparisonPluginGraphBarRefDiff(true), DashboardPluginGraphBarLegend(id), DashboardEvolutionPluginGraphBarRefDiff(true)]}
				/>
			</div>
			{displayLegend && <div id={id} className="dashboard_graph_legend" />}
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					<th className={'align-right'}>{translate('value')}</th>
					{objective && <th className={'align-right'}>{translate('dashboard.modal.objective')} - {objective.year}</th>}
				</tr>
			</thead>
			<tbody>
				{graphData.filter((e) => e !== null).map((e, i) => (
					<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
						<td>{e?.label}</td>
						<td className={'value'}>
							<span className={'number value'}>
								{e?.values[0].value ? resultValue(e?.values[0].value) : '-'}
								{datasetRef && datasetRef !== e?.label && <span className="number ref">
									{dashboardCompareToReference(graphData, datasetRef, e?.values[0].value ?? -1)}
								</span>}
							</span>
						</td>
						{objective && e?.values[1] && <td className={'value'}>
							{resultValue(e?.values[1].value)}
						</td>}
					</tr>
				))}
			</tbody>
		</table>}
	</div>);
};

type DashboardMaturityDomainComparisonProps = {
	data: DashboardComparisonMaturity;
	globalBenchmark: DashboardComparisonBenchmark | undefined;
}
const DashboardMaturityDomainComparison = ({ data, globalBenchmark }: DashboardMaturityDomainComparisonProps): JSX.Element => {
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();
	const [objective, setObjective] = React.useState<{ percent: number, year: number } | undefined>();
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [openObjectiveModal, setOpenObjectiveModal] = React.useState<boolean>(false);
	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);
	const [datasetRef, setDatasetRef] = React.useState<string | null>(null);

	useModal(openObjectiveModal, createDashboardComparisonObjectiveModal(
		openObjectiveModal,
		setOpenObjectiveModal,
		setObjective
	));
	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	useEffect(() => {
		if (globalBenchmark && !benchmark) setBenchmark(globalBenchmark);
	}, [globalBenchmark]);

	const defineObjective = () => {
		if (objective) {
			setObjective(undefined);
		} else {
			setOpenObjectiveModal(true);
		}
	};

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	const parseDataset = (dataset: DashboardComparisonMaturity['datasets'][number], domain: string) => {
		const value = dataset.maturity.reduce((acc, maturity) => {
			if (domain !== 'average' && maturity.domain !== domain || maturity.grade == null) return acc;
			return acc + maturity.grade;
		}, 0) / Math.max(dataset.maturity.filter(maturity => domain === 'average' || maturity.domain === domain).length, 1);
		return {
			label: dashboardLabelName({ id: dataset.id, data, defaultName: dataset.name }),
			values: [{
				value,
				scope: translate('dashboard.maturity.grade') as string,
				color: '#086598'
			}],
		};
	};

	let graphData: (GroupedBarChartData | null)[] = data.datasets.map(d => parseDataset(d, selectDomain));
	if (objective && Number(objective.percent)) graphData.forEach(e => {
		if (e) e.values.push({
			value: Math.min(5, e.values[0].value + (e.values[0].value * (100 - objective.percent) / 100)),
			scope: `${translate('dashboard.objective')} ${objective.year}`,
			color: '#46825a'
		});
	});

	if (benchmark) {
		const b = benchmark.maturity.datasets.map(d => parseDataset(d, selectDomain));
		graphData.push({
			label: benchmark.sampleName,
			values: [{
				scope: b[0].values[0].scope,
				value: (b.reduce((acc, e) => {
					return acc + e.values[0].value;
				}, 0) / b.length),
				color: '#1a1a1a'
			}]
		});
	}

	const concatBenchmarkDatasets = () => {
		if (!benchmark) return {
			id: '',
			name: '',
			study: '',
			year: '',
			company: '',
			maturity: []
		};
		const maturities = benchmark.maturity.datasets.reduce<(DashboardComparisonMaturity['datasets'][number]['maturity'][number] & {num: number})[]>((acc, d) => {
			if (!acc.length) return d.maturity.map((m) => ({ ...m, num: 1 }));
			d.maturity.forEach((m) => {
				const accMat = acc.find(a => a.id === m.id);
				if (!accMat) {
					acc.push({ ...m, num: 1 });
					return;
				}
				accMat.num++;
				if (m.grade == null) return;
				accMat.grade += m.grade;
			});
			return acc;
		}, []);
		const maturity = maturities.map(m => ({
			...m,
			grade: m.grade / m.num
		}));
		return {
			id: benchmark.sampleName,
			name: benchmark.sampleName,
			study: '',
			year: '',
			company: '',
			maturity
		};
	};

	if (selectDomain === 'all') {
		const datasetColors = getColors(data.datasets.length);
		graphData = data.domains.map((domain) => {
			const datasetsList = benchmark ? [...data.datasets, concatBenchmarkDatasets()] : data.datasets;
			const parsedDatasets = datasetsList.map(d => parseDataset(d, domain));
			const values = parsedDatasets.map(d => d.values[0].value);
			return {
				label: domain,
				values: parsedDatasets.map((d, i) => ({
					value: values[i],
					scope: d.label,
					color: datasetColors[i]
				})),
			};
		});
	}

	if (graphData.length < 4) {
		graphData.unshift(null);
		graphData.push(null);
	}
	const saveData = {
		domain: selectDomain,
		objective,
		graph: graphData,
		datasetRef
	};
	return <div className={'dashboard_card dashboard_card_color_blue'}>
		<h3>{translate('dashboard.title.maturityDomainsComparison')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{data.domains.map((domain) => (
					<option key={domain} value={domain}>{domain}</option>
				))}
				<option value={'average'}>{translate('dashboard.select.allDomainsAverage')}</option>
			</select>
			<DashboardSelectReference data={data} reference={datasetRef} setReference={setDatasetRef} />
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced" />
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				{selectDomain != 'all' && <button type="button" className="button_blank dashboard_action" onClick={defineObjective}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-bullseye-pointer"/>
					</div>
					<div className="dashboard_text">
						{objective && translate('dashboard.action.objective.remove')}
						{!objective && translate('dashboard.action.objective.add')}
					</div>
				</button>}
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardMaturityDomainComparison'} />
			</div>
			<DashboardMaturityDomainComparisonGraph showTable={showTable} data={saveData} displayLegend={selectDomain === 'all'}/>
		</div>
	</div>;
};

export default DashboardMaturityDomainComparison;