import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import './ResultsView.scss';
import { changeDocumentTitle } from '../../utils/changeDocumentTitle';
import { Result } from '../../domain/data/entries/Result';
import { translate } from '../../infrastructure/translations/translate';
import { FootprintPerLCSGraph, ResultFootprintPerLCStep } from '../../viewComponents/results/ResultFootprintPerLCStep';
import { ResultFootprintDetailed } from '../../viewComponents/results/ResultFootprintDetailed';
import { ResultFootprintPerFunctionalUnit } from '../../viewComponents/results/ResultFootprintPerFunctionalUnit';
import { FootprintPerBlockGraph, ResultFootprintPerBlock } from '../../viewComponents/results/ResultFootprintPerBlock';
import { MaturityGraph, ResultMaturity } from '../../viewComponents/results/ResultMaturity';
import {
	MaturityComparisonByDomainGraph,
	ResultMaturityComparisonByDomain
} from '../../viewComponents/results/ResultMaturityComparisonByDomain';
import {
	NormalizedFootprintPerBlockGraph,
	ResultNormalizedFootprintPerBlock
} from '../../viewComponents/results/ResultNormalizedFootprintPerBlock';
import {
	NormalizedFootprintPerIndicators,
	ResultNormalizedFootprintPerIndicator
} from '../../viewComponents/results/ResultNormalizedFootprintPerIndicator';
import { ResultNormalizedFootprintDetailed } from '../../viewComponents/results/ResultNormalizedFootprintDetailed';
import {
	NormalizedFootprintPerLcsGraph,
	ResultNormalizedFootprintPerLCStep
} from '../../viewComponents/results/ResultNormalizedFootprintPerLCStep';
import { ResultEnvironmentalBudget } from '../../viewComponents/results/ResultEnvironmentalBudget';
import {
	ElectricityConsumptionGraph,
	ResultElectricityConsumption
} from '../../viewComponents/results/ResultElectricityConsumption';
import Spinner from '../../viewComponents/utils/Spinner/Spinner';
import { ResultFluxSummary } from '../../viewComponents/results/ResultFluxSummary';
import { ResultQualityBasedResult } from '../../viewComponents/results/ResultQualityBasedResult';
import {
	QualityScoreGraph,
	ResultQualityScoreBasedResult
} from '../../viewComponents/results/ResultQualityScoreBasedResult';
import { ResultMaturityScore } from '../../viewComponents/results/ResultMaturityScore';
import { ResultImpactScore } from '../../viewComponents/results/ResultImpactScore';
import {
	PlanetaryBoundariesGraph,
	ResultPlanetaryBoundaries
} from '../../viewComponents/results/ResultPlanetaryBoundaries';
import { ErrorBoundary } from 'react-error-boundary';
import { InfoSamplesInfo } from '../../viewComponents/results/utils/infoSamplesInfo';
import { Tip } from '../../viewComponents/tip/Tip/Tip';
import { Navigate, useNavigate } from 'react-router-dom';
import { ResultsModal } from '../../viewComponents/modal/ResultsModal/ResultsModal';
import ErrorImage from '../../viewComponents/error/ErrorImage/ErrorImage';
import { IIndicator } from '../../domain/interfaces/IIndicator';
import { IResultType } from '../../domain/interfaces/IResultType';
import ReactMarkdown from 'react-markdown';
import { useTooltip } from '../../viewComponents/tip/useTooltip';
import { getFiltersByDomain } from '../../utils/getFilterByDomain';
import { ISampleListItem } from '../../domain/interfaces/ISample';
import { ResultFootprintEquivalence } from '../../viewComponents/results/ResultFootprintEquivalence';
import { DashboardPinButton } from '../DashboardView/DashboardPin';
import { useResultLaunchers } from '../../viewComponents/hooks/useResultLaunchers';
import { useResults } from '../../viewComponents/hooks/useResults';
import { useIndicators } from '../../viewComponents/hooks/useIndicators';
import { useSamples } from '../../viewComponents/hooks/useSamples';
import { useModal } from '../../viewComponents/modal/useModal';
import { useUser } from '../../viewComponents/hooks/useUser';
import { useStudies } from '../../viewComponents/hooks/useStudies';
import { useSampleResultComparison } from '../../viewComponents/modal/ResultComparisonModal/ResultComparisonModal';
import { closeGlobalModal } from '../../viewComponents/modal/GlobalModal';
import ResultHeader from './ResultHeader';
import { IResultLauncher } from '../../domain/data/entries/IResultLauncher';
import { Alert } from '../../viewComponents/Alerts';
import { ResultQualityOverview, ResultQualityOverviewGraph } from '../../viewComponents/results/ResultQualityOverview';
import { BgesGraph, ResultBges } from '../../viewComponents/results/ResultBges';
import {
	useEquipmentsVersionsModal
} from '../../viewComponents/modal/EquipmentTypesVersionsModal/EquipmentsVersionsModal';
import {
	useLcaParametersVersionsModal
} from '../../viewComponents/modal/LcaParametersVersions/LcaParametersVersionsModal';
import { ResultInventoryOverview } from '../../viewComponents/results/ResultInventoryOverview';
import { useDatasets } from '../../viewComponents/hooks/useDatasets';
import { ResultImpacts } from '../../viewComponents/results/ResultImpacts';
import { ResultSkeleton } from './ResultSkeleton';
import { ResultCarousel, ResultCarouselGlobalNormalized } from './ResultCarousel';


export const ResultComparisonImpossible = (): JSX.Element => {
	return <div className={'result_comparison_impossible'}>
		<h2>{translate('results.comparison_impossible')}</h2>
	</div>;
};

export const ResultContainer = ({ children, head, buttons, id, isGraphDisplayed, groupBy }:
	{
		children: React.ReactNode,
		head?: React.ReactNode,
		buttons?: React.ReactNode,
		id?: string,
		isGraphDisplayed?: boolean,
		groupBy?: boolean
	}) => {
	return (
		<div className={`result_block ${groupBy ? 'group_by' : ''}`} key={`${id ? id : ''}`}>
			{buttons && buttons}
			<div className={'result_card'}>
				{head && <div className={'result_card_head'}>{head}</div>}
				<div className={`result_card_content ${isGraphDisplayed ? '' : 'table'} ${groupBy ? 'groupBy' : ''}`}>
					{children}
				</div>
			</div>
		</div>
	);
};

/**
 * Return an empty Dom React Element
 * @constructor
 * @return JSX.Element
 *
 * @author
 */
const NoResult = (): JSX.Element => {
	return (<></>);
};

/**
 * Return an empty Dom React Element
 * @constructor
 * @return JSX.Element
 *
 * @author
 */
const SampleMandatoryResult = (): JSX.Element => {
	return (<div className={'results_choose_sample'}>
		<h5>{translate('results.chooseASample')}</h5>
		<p>{translate('results.chooseASampleDesc')}</p>
	</div>);
};

/**
 * Return if a Sample is not undefined
 * with typescript
 * @param sample
 * @return boolean
 */
function isSampleNotUndefined(sample: { id: string, name: string } | undefined): sample is { id: string, name: string } {
	return sample !== undefined && sample !== null;
}

/**
 * Return folder information
 * @param {Result[]} arrayBlock
 * @constructor
 * @return JSX.Element
 *
 * @author
 */
const InfoSampleBlock = ({ arrayBlock }: { arrayBlock: Result[] }): JSX.Element => {
	if (arrayBlock?.length) {
		const samples = arrayBlock
			.map(b => b.sample)
			.filter(isSampleNotUndefined)
			.filter((sample, index, self) =>
				index === self.findIndex(s => s.id === sample.id)
			);

		if (samples.length > 0) {
			return <InfoSamplesInfo info={samples}/>;
		}
		return <></>;
	}
	return <></>;
};

/**
 * Return information about the launcher
 * @param {IResultLauncher} launcher
 * @return JSX.Element
 *
 * @Author Maximilien Valenzano
 */
const InfoLauncherBlock = ({ launcher }: { launcher: IResultLauncher }): JSX.Element => {
	if (launcher) {
		const moreThanOneDataset = launcher.computedDatasets && launcher.computedDatasets.length > 1 || false;
		const datasets = launcher.computedDatasets?.map(d => d.name) || [];
		if (!moreThanOneDataset) return <></>;
		return (
			<Tip type={'normal'} border={'lightgray'} style={{ margin: '10px 0px' }}>
				<p>{translate('results.collectionDatasets')} : {datasets.join(', ')}</p>
			</Tip>
		);
	}
	return <></>;
};


export const getResultComponent = (block: Result, element: string, indicators: IIndicator[]) => {
	const type = block.type;
	const data = JSON.parse(element == 'relative' && block.relativeData ? block.relativeData : block.data);
	let result = null;
	let dynamicGraph = null;
	let indicatorsNeeded = false;
	const isDataRelative = element == 'relative';
	switch (type) {
		case 'FootprintPerBlock':
			result = <ResultFootprintPerBlock data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <FootprintPerBlockGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'FootprintPerLCStep':
			result = <ResultFootprintPerLCStep data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <FootprintPerLCSGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'FootprintPerFunctionalUnit':
			result = <ResultFootprintPerFunctionalUnit data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'FootprintDetailed':
			result = <ResultFootprintDetailed data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'FootprintEquivalence':
			result = <ResultFootprintEquivalence data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'Maturity':
			result = <ResultMaturity data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <MaturityGraph data={data}/>;
			break;
		case 'MaturityComparisonByDomain':
			result = <ResultMaturityComparisonByDomain data={data}/>;
			dynamicGraph = <MaturityComparisonByDomainGraph data={data}/>;
			break;
		case 'NormalizedFootprintPerBlock':
			result = <ResultNormalizedFootprintPerBlock data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <NormalizedFootprintPerBlockGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'NormalizedFootprintPerIndicator':
			result = <ResultNormalizedFootprintPerIndicator data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <NormalizedFootprintPerIndicators data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'NormalizedFootprintDetailed':
			result = <ResultNormalizedFootprintDetailed data={data}/>;
			indicatorsNeeded = true;
			break;		
		case 'NormalizedFootprintPerLCStep':
			result = <ResultNormalizedFootprintPerLCStep data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <NormalizedFootprintPerLcsGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'EnvironmentalBudget':
			result = <ResultEnvironmentalBudget data={data}/>;
			break;
		case 'ElectricityConsumption':
			result = <ResultElectricityConsumption data={data}/>;
			dynamicGraph = <ElectricityConsumptionGraph data={data}/>;
			break;
		case 'FluxSummary':
			result = <ResultFluxSummary data={data}/>;
			break;
		case 'LowestQualityData':
			result = <ResultQualityBasedResult data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'QualityScore':
			result = <ResultQualityScoreBasedResult data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <QualityScoreGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'QualityOverview':
			result = <ResultQualityOverview data={data}/>;
			dynamicGraph = <ResultQualityOverviewGraph data={data}/>;
			break;
		case 'MaturityScore':
			result = <ResultMaturityScore data={data}/>;
			break;
		case 'ImpactScore':
			result = <ResultImpactScore data={data}/>;
			break;
		case 'PlanetaryBoundaries':
			result = <ResultPlanetaryBoundaries data={data} isRelative={isDataRelative}/>;
			dynamicGraph = <PlanetaryBoundariesGraph data={data}/>;
			indicatorsNeeded = true;
			break;
		case 'BGES':
			result = <ResultBges data={data}/>;
			dynamicGraph = <BgesGraph data={data}/>;
			break;
		case 'InventoryOverview':
			result = <ResultInventoryOverview data={data}/>;
			break;
		case 'Impacts':
			result = <ResultImpacts data={data}/>;
			break;
		default:
			break;
	}
	if (indicatorsNeeded && indicators.length <= 0) return (<div className={'results_choose_sample'}>
		<h5>{translate('results.noIndicator')}</h5>
		<p>{translate('results.noIndicatorSelected')}</p>
	</div>);
	if (element == 'graph') return dynamicGraph;
	return result;
};

export const ResultHasGraphs = [
	'FootprintPerBlock',
	'FootprintPerLCStep',
	'Maturity',
	'MaturityComparisonByDomain',
	'NormalizedFootprintPerBlock',
	'NormalizedFootprintPerIndicator',
	'NormalizedFootprintPerLCStep',
	'ElectricityConsumption',
	'Objectives',
	'QualityScore',
	'QualityOverview',
	'PlanetaryBoundaries',
	'BGES'
];
/**
 * Return the folder and the information displayed in it
 * @param block Result
 * @param resultType IResultType | undefined
 * @param sampleList ISampleListItem[]
 * @return JSX.Element
 *
 * @author
 */
const ResultBlock = ({ block, resultType, sampleList }: {
	block: Result,
	resultType: IResultType | undefined
	sampleList: ISampleListItem[]
}): JSX.Element => {
	const { entity: userEntity, data: logged } = useUser();
	const { entity } = useResults();
	const { selectedIndicators: indicators } = useIndicators();

	const canPinACVResult = logged?.permissions.includes('login.can_pin_acv_result_to_dashboard');
	block.hasGraph = ResultHasGraphs.includes(block.type);
	const [currentElement, setContentElement] = useState<string>(block.hasGraph ? 'graph' : 'children');
	const [openComparison, setOpenComparison] = useState<boolean>(false);
	const [queryResultsCompared, setQueryResultsCompared] = useState<boolean>(false);
	const [toolTipText, setToolTipText] = useState<string | null>(null);
	
	const resultTypeTitle = resultType?.shortName == block.type ? null : resultType?.shortName;
	const title = resultTypeTitle || translate(`results.title.${block.type}`) as string;
	const data = JSON.parse(currentElement == 'relative' && block.relativeData ? block.relativeData : block.data);
	
	const comparisonImpossible = block.sample != null && !block.data.includes('mean');
	const compared = block.sample?.id !== undefined;
	useTooltip(toolTipText !== null, toolTipText);
	
	const handleChangeComparison = (resultsIds: string[], sampleId?: string) => {
		setQueryResultsCompared(true);
		entity?.mutateResultsComparison({ resultsIds, sampleId }).then(() => {
			setQueryResultsCompared(false);
			setOpenComparison(false);
			closeGlobalModal();
		});
	};

	useModal(openComparison, useSampleResultComparison(
		openComparison,
		[block.id],
		setOpenComparison,
		sampleList, 
		handleChangeComparison,
		queryResultsCompared,
		setToolTipText,
		block.sample?.id
	));

	const buttons =
		<div className={'svg_switch_button'}>
			{block.hasGraph &&
				<button onClick={() => setContentElement('graph')}>
					<i className={`fa-solid fa-chart-column ${currentElement === 'graph' ? 'current_icon' : ''}`}/>
				</button>}
			<button onClick={() => setContentElement('children')}>
				<i className={`fa-solid fa-table ${currentElement === 'children' ? 'current_icon' : ''}`}/>
			</button>
			{block.relativeData && <button onClick={() => setContentElement('relative')}>
				<i className={`fa-solid fa-percent ${currentElement === 'relative' ? 'current_icon' : ''}`}/>
			</button>}
			<button onClick={() => setContentElement('methodology')}>
				<i className={`fa-regular fa-circle-question ${currentElement === 'methodology' ? 'current_icon' : ''}`} />
			</button>
			{block.relatedToSample != 2 && <button onClick={() => setOpenComparison(true)}>
				<i className="fa-solid fa-scale-balanced" color={compared ? 'black' : 'grey'}/>
			</button>}
			{canPinACVResult && <DashboardPinButton data={block} type={'ACVResult'} text={false} prefill={title} />}
		</div>;

	let head = <h5>{title}</h5>;
	if (compared) {
		const sample = sampleList.find((sample) => sample.id === block.sample?.id);
		if (sample) {
			head = <div className='result_compared_head'>
				<h5>{title}</h5>
				{comparisonImpossible ?
					<Alert variant='danger'>
						{translate('results.warning', { compare: () => sample.name })}
					</Alert> :
					<p className={'result_compared_title'}>
						{translate('result.comparedTo', { compare: () => sample.name })}
					</p>
				}
			</div>;
		}
	}
	const methodology = resultType?.methodology ?? '';
	if (currentElement === 'methodology') {
		if (methodology === '') { // no methodology error reported
			userEntity?.mutateReportError(new Error(
				`TypeError\n\nResultType (name): ${title} . \n\n Empty methodology for result type ${title}.`
			), { componentStack: 'views/ResultsView/ResultViews.ts ResultTitleBlock' }, `Empty methodology for result type ${title}, please fill it out.`);
		}
		return <ResultContainer
			head={head}
			buttons={buttons}
			id={`result ${block.id}`}
			isGraphDisplayed={false}
		>
			<ReactMarkdown className='result_methodology'>{methodology}</ReactMarkdown>;
		</ResultContainer>;
	}
	if (block.relatedToSample == 3 && !block.sample) {
		return <>
			<ResultContainer
				head={head}
				buttons={buttons}
				id={`result ${block.id}`}
				isGraphDisplayed={currentElement === 'graph'}
			>
				<SampleMandatoryResult/>;
			</ResultContainer>
		</>;
	}
	if (data) {
		return (<ErrorBoundary FallbackComponent={NoResult}>
			<ResultContainer
				head={head}
				buttons={buttons}
				id={`result ${block.id}`}
				isGraphDisplayed={currentElement === 'graph'}
				groupBy={block.type === 'Impacts'}
			>
				{getResultComponent(block, currentElement, indicators)}
			</ResultContainer>
		</ErrorBoundary>
		);
	}
	return (
		<ResultContainer head={<h5>{block.type}</h5>} id={`error ${block.id ? block.id : 'block'}`}>
			<p>{translate('somethingWentWrong')}</p>
		</ResultContainer>
	);
};

/**
 * This component fetch the ResultLauncher associated to datasetsIds and sampleID, before sharing it with the
 * ResultPanel component.
 * This is a wrapper component.
 *
 * @param {string[]} datasetsIds: Datasets whoe results should be shown
 * @param {string | undefined} sampleId: Sample associated to Results
 * @param {boolean | undefined} archived: If true, hide the button to archive the results or to export them or to set them private or public
 * @constructor
 * @return JSX.Element
 *
 * @author Amael
 */
const ResultsView = ({
	datasetsIds,
	sampleId,
	archived
}: {
	datasetsIds: string[],
	sampleId?: string,
	archived?: boolean
}): JSX.Element => {
	// Manage permissions
	const { data: logged } = useUser();
	const { loading: loadingResults, error: resError } = useResults();
	const { studies } = useStudies();
	const studyId = useSelector((state: State) => state.studyID);
	const study = studies?.find(s => s.id === studyId);
	const { datasets } = useDatasets(datasetsIds);
	const { samples: sampleList } = useSamples(study ? [study.functionalUnit.name] : undefined);
	const { entity: rlEntity, loading: loadingLaunchers, error: rlError, resultLaunchers: data } = useResultLaunchers(datasetsIds, sampleId);

	if (!logged?.permissions.includes('results.view_result')) return <Navigate to={'/forbidden'}/>;

	const resultRef = useRef<HTMLDivElement>(null);

	const [openModalResults, setOpenModalResults] = useState(false);
	const [queryError, setQueryError] = useState(false);
	const [noCollectionFound, setNoCollectionFound] = useState(false);
	const [activeLauncher, setActiveLauncher] = React.useState<string | null>(data.find(rl => rl.definitive)?.id ?? null);

	// Fetch the ResultLaunchers for given datasets and/or sample, except if datasetsIds didn't changed
	// Set queryError to true if an error occurred to display a message
	useEffect(() => {
		// Only query if datasetsIds changed and are not empty
		if (!datasetsIds || !datasetsIds.length) return;
		try {
			rlEntity?.queryComputingHistory(datasetsIds, sampleId).then((history) => {
				if (!history || !history.length) setNoCollectionFound(true);
			});
			setActiveLauncher(null);
		} catch (e) {
			setQueryError(true);
		}
	}, [JSON.stringify(datasetsIds)]);

	// Catch and filter errors
	if (resError || rlError || !data) return (<></>);

	// Reverse the list to have the last result first
	const resultsLaunchers = [...data].reverse();
	const definitiveResultExist = resultsLaunchers.some(launcher => launcher.definitive);
	const launcher: IResultLauncher = (activeLauncher
		? resultsLaunchers.find(rl => rl.id === activeLauncher)
		: definitiveResultExist
			? resultsLaunchers.find(rl => rl.definitive)
			: resultsLaunchers[0]) ?? resultsLaunchers[0];

	// For clarity
	const ready: boolean = resultsLaunchers.length > 0;
	const handleLauncherChange = (value: string): void => {
		setActiveLauncher(value);
	};

	const resultLoaded = ready && !queryError && launcher;

	if (queryError) return <ErrorImage errorText={translate('results.errorQuery') as string} img={'bug_fixing_man'} modal={false}/>;
	if (noCollectionFound) {
		const datasetNames = datasets.filter(d => datasetsIds.includes(d.id)).map(d => d.name).join(', ');
		return (<h2>{translate('results.noCollectionFound', {
			perimeters: () => <>{datasetNames}</>,
			break: () => <br/>
		})}</h2>);
	}
	if (loadingLaunchers || loadingResults || !resultLoaded) return (<div className={'results_grid'}>
		{[...Array(4)].map((_, index) => (
			<ResultSkeleton key={index} type={index % 2 ? 'table' : 'chart'} />
		))}
	</div>);

	return (
		<section ref={resultRef} key={'results'}>
			{/*{TODO: When updating modals to new system, put the modal login in ResultHeader because components here will disappear}*/}
			{openModalResults && launcher &&
				<ResultsModal onClose={() => setOpenModalResults(false)} rlId={launcher.id}/>}

			{queryError || !ready &&
				<div className="result_head_bar">
					<h1 style={{ marginRight: 'auto' }}>{translate('results.result') as string}</h1>
				</div>
			}

			{resultLoaded && <>
				<ResultHeader
					setLauncher={setActiveLauncher}
					launcher={launcher}
					datasetsIds={datasetsIds}
					handleLauncherChange={handleLauncherChange}
					loadingResults={loadingResults}
					setOpenModalResults={setOpenModalResults}
					resultRef={resultRef}
					setQueryError={setQueryError}
					studyArchived={archived}
					resultSampleId={sampleId}
				/>
				<Tip type={'info'}>
					{translate('results.info', {
						perimeter: () => {
							const scope = datasetsIds.length === 1
								? datasets.find(d => d.id === datasetsIds[0])?.name || ''
								: translate('datasetSelector.all') as string;
							return <>{scope}</>;
						}
					})}
				</Tip>
				<ResultsPanel
					launcher={launcher}
					archivedResults={launcher.archived}
					sampleList={sampleList ?? []}
					sampleId={sampleId}
					key={launcher.id}
				/>
			</>}
		</section>
	);
};


const resultGroupsNames = ['lcaresults', 'normalizedresults', 'data', 'fluxes', 'maturity'];
const resultGroupsRefs = resultGroupsNames.reduce((acc, name) => {
	acc[name] = React.createRef<HTMLDivElement>();
	return acc;
}, {} as Record<string, React.RefObject<HTMLDivElement>>);


/**
 * This panel shows a list of Results, associated to a ResultLauncher passed in props.
 *
 * @param {string} resultlauncher_id: ResultLauncher associated to Results
 * @param {boolean} archivedResults: If true, the Results are archived, show tips
 * @param {boolean} canGroupBy: If true, the user can group results by equipment
 * @param {ISample[]} sampleList: List of samples
 * @param {function} setLoadingResults: Function to set the loading state of the results
 * @constructor
 * @return JSX.Element
 *
 * @author Maximilien
 */
const ResultsPanel = ({
	launcher,
	archivedResults,
	sampleList,
	sampleId,
}: {
	launcher: IResultLauncher,
	archivedResults?: boolean,
	sampleList: ISampleListItem[],
	sampleId?: string
}): JSX.Element => {
	const { loading, error, entity, results } = useResults(launcher.id);

	const datasetId = useSelector((state: State) => state.datasetID);
	const navigate = useNavigate();

	const [resultTypes, setResultTypes] = useState<IResultType[]>([]);

	const carouselResultTypes = ['TotalFootprint', 'FootprintPerFunctionalUnit'];
	const launcherHaveSnapshot = !!(launcher.inventoryExtraSnapshot && launcher.inventoryExtraSnapshot.length > 0);

	const [openLcaParametersVersions, setOpenLcaParametersVersions] = useState<boolean>(false);
	const [openEquipmentVersion, setOpenEquipmentVersion] = useState<boolean>(false);

	useModal(openLcaParametersVersions, useLcaParametersVersionsModal(openLcaParametersVersions, setOpenLcaParametersVersions, launcher.id));
	useModal(openEquipmentVersion, useEquipmentsVersionsModal(openEquipmentVersion, setOpenEquipmentVersion, null, launcher.id));

	// Get the result types to get the methodology
	useEffect(() => {
		const id = !datasetId || datasetId === 'all' ? undefined : datasetId;
		entity?.queryResultTypes(id).then(setResultTypes);
	}, []);

	// bind the handleScroll event listener on the core_block
	useEffect(() => {
		const handleScroll = () => {
			for (const resultGroupsName of resultGroupsNames) {
				const categoryRef = resultGroupsRefs[resultGroupsName];
				const rect = categoryRef.current?.getBoundingClientRect();

				if (rect && rect.top <= window.innerHeight && rect.top >= 0) {
					navigate(`#${resultGroupsName}`);
					break;
				}
			}
		};

		const scrollElement = document.getElementById('core_block');

		if (scrollElement) {
			scrollElement.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);


	changeDocumentTitle(translate('results.results') as string);

	if (loading) return (<Spinner />);
	if (error || !entity) return (<></>);

	const carouselResults = results.filter((result) => carouselResultTypes.includes(result.type));
	const globalNormalizedResults = results.find((result) => result.type === 'GlobalNormalizedResult');

	// Get the filters for a given domain and sort the results
	const getFilteredAndSortedResults = (domain: string, allResults: Result[]) : Result[] =>  {
		const filters = getFiltersByDomain(domain);
		return allResults
			.filter(result => filters.includes(result.type))
			.sort((a, b) => filters.indexOf(a.type) - filters.indexOf(b.type));
	};

	const separatedResults = [
		{
			name: 'lcaresults',
			carousel:
				carouselResults.length > 0 &&
				<ResultCarousel
					results={carouselResults}
					resultTypes={resultTypes}
					canChangeResult={carouselResults.length > 1}
					sampleList={sampleList}
					index={'lcaresults'}
				/>,
			results: getFilteredAndSortedResults('lcaresults', results)
		},
		{
			name: 'normalizedresults',
			carousel: 
			globalNormalizedResults &&
				<ResultCarouselGlobalNormalized result={globalNormalizedResults} sampleList={sampleList}/>,
			results: getFilteredAndSortedResults('normalizedresults', results)
		},
		{
			name: 'corporateReporting',
			carousel: null,
			results: getFilteredAndSortedResults('corporateReporting', results)
		},
		{
			name: 'data',
			carousel: null,
			results: getFilteredAndSortedResults('data', results)
		},
		{
			name: 'flux',
			carousel: null,
			results: getFilteredAndSortedResults('flux', results)
		},
		{
			name: 'maturity',
			carousel: null,
			results: getFilteredAndSortedResults('maturity', results)
		},
	];
	return (
		<div>
			{archivedResults &&
				<Tip type={'danger'} border={'red'} icon={'archive'}>
					{translate('results.archivedResults')}
				</Tip>}
			<InfoSampleBlock arrayBlock={results}/>
			{sampleId == undefined && <InfoLauncherBlock launcher={launcher}/>}
			{separatedResults.map((separatedResult, index) => {
				return (separatedResult.results.length > 0 &&
					<span key={`${separatedResult.name}_${index}`}>
						<div className={'result_panel_head'}>
							<div className={'result_panel_title'} id={separatedResult.name} ref={resultGroupsRefs[separatedResult.name]}>
								<hr/>
								<h1>{translate(`result.${separatedResult.name}`)}</h1>
								<hr/>
							</div>
							<h3>{translate('results.computeInfo')}</h3>
						</div>

						{separatedResult.name === 'normalizedresults' && <div className={'result_panel_info'}>
							<Tip>
								{translate('results.normalizedResults.info')}
							</Tip>
						</div>}

						{separatedResult.carousel && separatedResult.carousel}

						{<div className={'results_grid'} key={`results_grid_${index}`}>
							{separatedResult.results
								.filter((result) => !carouselResultTypes.includes(result.type) && globalNormalizedResults?.type !== result.type)
								.map((result) =>
									<ResultBlock
										key={result.id}
										block={result}
										sampleList={sampleList}
										resultType={resultTypes.find(e => (e.name == result.type))}
									/>)}
						</div>}
					</span>
				);}
			)}
			<div className="result_panel_footer">
				<button
					className={'equipment_version'}
					onClick={() => setOpenEquipmentVersion(true)}>
					{translate('results.equipmentVersions')}
				</button>
				{launcherHaveSnapshot && <button
					className={'equipment_version'}
					onClick={() => setOpenLcaParametersVersions(true)}>
					{translate('results.lcaParametersVersion')}
				</button>}
			</div>
		</div>
	);
};
export default ResultsView;
