import { Language } from '../translate';

export const en: Language = {
	'noMobile.title': 'No mobile',
	'yes': 'Yes',
	'yes!': 'Yes!',
	'no': 'No',
	'close': 'Close',
	'cancel': 'Cancel',
	'validate': 'Validate',
	'ignore': 'Ignore',
	'delete': 'Delete',
	'deleteAccount': 'Delete account',
	'download': 'Download',
	'downloadAll': 'Download all',
	'toDownload': 'to download',
	'downloadBy': 'Download by',
	'downloadByType': 'Download by type of',
	'choose.by': 'Choose by',
	'choose.byTypes': 'Choose by types of',
	'error.noData': 'No data',
	'priority': 'Priority',
	'save': 'Save',
	'none': 'None',
	'dismiss': 'Dismiss',
	'back': 'Back',
	'next': 'Next',
	'previous': 'Previous',
	'or': 'Or',
	'select': 'Select',
	'create': 'Create',
	'edit': 'Edit',
	'search': 'Search',
	'all': 'All',
	'all.study': 'All of study',
	'selectAll': 'Select all',
	'add': 'Add',
	'see.more': 'See more',
	'see.less': 'See less',
	'name': 'Name',
	'description': 'Description',
	'cgu': 'Terms and conditions',
	'cguNotAccepted': 'No user of your organization has accepted the terms and conditions agreement yet. An accredited user must accept the terms and conditions agreement to be able to access the application.',
	'archived': 'Archived',
	'...': '...',
	'clear': 'Clear',
	'reload.page': 'Reload page',
	'send': 'Send',
	'required': 'required',
	'study': 'Study',
	'studies': 'Studies',
	'dataset': 'Perimeter',
	'company': 'Organization',
	'other': 'Other',
	'value': 'Value',
	'unit': 'Unit',
	'selectABlock': 'Select a domain',
	'filter': 'Filter',
	'maybeLater': 'Maybe later',
	'whatsNew': 'What\'s new ?',
	'share': 'Share',
	'to.be.done.abrev': 'Obj',
	'accept': 'Accept',
	'refuse': 'Refuse',
	'continue': 'Continue',
	'skip': 'Skip',
	'average': 'Average',
	'comingSoon': 'Coming soon',
	'perUser': 'per user',
	'database': 'Database',
	'help': 'Help',
	'total': 'Total',
	'lcaParameters': 'LCA Parameters',
	'domain': 'Domain',
	'energyMix': 'Energy mix',
	'showMoreDetails': 'Show more details',
	'noBlockSelected': 'No domain selected',
	'DEPRECATED': 'DEPRECATED',
	'goTo': 'Go to',
	'comment': 'Comment',
	'confirm': 'Confirm',
	'displayLegend': 'Display legend',
	'hideLegend': 'Hide legend',
	'addTranslation': 'Add translation',
	'addTranslation.info': 'By adding a translation, you can define a name for your &obj() in both French and English. Otherwise, the name entered will be the same regardless of the language selected',
	'you': 'You',
	'seeImages': 'See images',
	'publishedOn': 'Published on &date()',


	'input.number.placeholder': 'Enter a number',

	'geography.AFR': 'Africa',
	'geography.ASC': 'Asian Continent',
	'geography.AUS': 'Australia',
	'geography.AUT': 'Austria',
	'geography.BA': 'Bosnia and Herzegovina',
	'geography.BEL': 'Belgium',
	'geography.BG': 'Bulgaria',
	'geography.BRA': 'Brazil',
	'geography.CAN': 'Canada',
	'geography.CA_QC': 'Canada - Quebec',
	'geography.CHE': 'Switzerland',
	'geography.CHL': 'Chile',
	'geography.CHN': 'China',
	'geography.CO': 'Colombia',
	'geography.CZ': 'Czechia',
	'geography.DEU': 'Germany',
	'geography.DNK': 'Denmark',
	'geography.DOM': 'Dominican Republic',
	'geography.EE': 'Estonia',
	'geography.ESP': 'Spain',
	'geography.EUC': 'European Continent',
	'geography.FIN': 'Finland',
	'geography.FRA': 'France',
	'geography.GLO': 'Global',
	'geography.GR': 'Greece',
	'geography.HKG': 'Hong Kong',
	'geography.HR': 'Croatia',
	'geography.HU': 'Hungary',
	'geography.ID': 'Indonesia',
	'geography.IL': 'Israel',
	'geography.IN': 'India',
	'geography.IR': 'Ireland',
	'geography.ITA': 'Italy',
	'geography.JPN': 'Japan',
	'geography.KE': 'Kenya',
	'geography.KHM': 'Cambodia',
	'geography.KOR': 'South Korea',
	'geography.KZ': 'Kazakhstan',
	'geography.LB': 'Lebanon',
	'geography.LT': 'Lithuania',
	'geography.LUX': 'Luxembourg',
	'geography.LV': 'Latvia',
	'geography.MAR': 'Morocco',
	'geography.MEX': 'Mexico',
	'geography.MUS': 'Mauritius',
	'geography.MY': 'Malaysia',
	'geography.NAM': 'North America',
	'geography.NLD': 'Netherlands',
	'geography.NOR': 'Norway',
	'geography.NZL': 'New Zealand',
	'geography.PA': 'Panama',
	'geography.PE': 'Peru',
	'geography.PH': 'Philippines',
	'geography.PL': 'Poland',
	'geography.PRT': 'Portugal',
	'geography.RO': 'Romania',
	'geography.RS': 'Serbia',
	'geography.RUS': 'Russia',
	'geography.SGP': 'Singapore',
	'geography.SK': 'Slovakia',
	'geography.SWE': 'Sweden',
	'geography.TH': 'Thailand',
	'geography.TW': 'Taiwan',
	'geography.TR': 'Turkey',
	'geography.UA': 'Ukraine',
	'geography.UK': 'United Kingdom',
	'geography.USA': 'United States',
	'geography.UY': 'Uruguay',
	'geography.VE': 'Venezuela',
	'geography.VN': 'Vietnam',
	'geography.ZAF': 'South Africa',
	'geography.AR': 'Argentina',
	'geography.AE': 'United Arab Emirates',
	'geography.PK': 'Pakistan',
	'geography.EG': 'Egypt',

	'deleteOperation.confirmTitle': 'Confirm deletion',

	'documentation.title': 'Documentation',
	'documentation.link': 'Link to the report',
	'documentation.download': 'Download (&fileInfo())',

	'login.login': 'Login',
	'login.email': 'Email',
	'login.password': 'Password',
	'login.connect': 'Connection',
	'login.required': '(required)',
	'login.mailPlaceHolder': 'mymail@myorg.com',
	'login.passwordPlaceHolder': 'my password',
	'login.forgotPassword': '&link(Forgot password ?)',
	'login.noAccount': 'No account ? &linked(Contact us !)',
	'login.noSSOAccount': 'No SSO ? &linked(Contact us) to add your SSO !',
	'login.authFail': 'Authentication failed. Please try again.',
	'login.networkInterrupted': 'Network connection interrupted. Try to reconnect.',
	'login.errorOccurs': 'An error occurred on server-side. Please try again. If the problem persists, contact the admin.',
	'login.with': 'Login with',
	'login.switch': 'SSO',

	'cguForm.fullCgu': 'Click here to read the complete Terms and conditions',

	'login.ssoMail': 'SSO email',
	'login.ssoMail.placeholder': 'mymail@sso_domain.com',
	'login.sso.redirect': 'You will be redirected to our application. This window will close automatically.',
	'login.sso.errorDomain': 'This email domain is not allowed to use SSO.',
	'login.sso.errorState': 'The state provided by the SSO is not valid.',
	'login.sso.errorCode': 'Sorry, SSO connexion failed. Please try again.',
	'login.sso.switch': 'Switch to login',

	'login.maintenance': 'Maintenance',
	'login.maintenance.description': 'The application is currently under maintenance. Please try again later.',
	'login.maintenance.backTimeEstimate': 'We expect to be back online in 30 minutes at most.',
	'login.maintenance.contactUs': 'If you want us to contact you when the application is back online, please fill in the form below.',
	'login.maintenance.success': 'Thank you for your interest. We will contact you as soon as the application is back online.',
	'login.maintenance.error': 'An error occurred. Please try again.',

	'passwordReset.resetPassword': 'Reset password',
	'passwordReset.newPassword': 'New password',
	'passwordReset.confirmPassword': 'Confirm password',
	'passwordReset.save': 'Save',
	'passwordReset.request.success': 'If your email is registered, you will receive a link to reset your password.',

	'passwordSendReset.resetPassword': 'Reset password',
	'passwordSendReset.description': 'Enter your e-mail to receive the link for changing password.',
	'passwordSendReset.email': 'Email',
	'passwordSendReset.send': 'Send',
	'password': 'Password',

	'register.register': 'Register',
	'register.accusedReceive': 'Example text for information back',
	'register.email': 'Email',
	'register.username': 'Username',
	'register.password': 'Password',
	'register.confirmPassword': 'Confirm password',
	'register.already': 'Already have an account ? &login(Login) !',

	'ariaNavigation.menu': 'Menu',
	'ariaNavigation.mainContent': 'Main content',
	'ariaNavigation.accountCapsule': 'Account capsule',


	'home.status.resultReady': 'Results ready',
	'home.status.resultProgress': 'Results in progress',
	'home.status.validationProgress': 'Validation in progress',
	'home.status.start': 'Start',
	'home.status.collecting': 'Collecting',
	'home.noStudyYet': 'No study yet...',
	'home.studies': 'Studies',
	'home.studiesShown': 'studies shown',
	'home.filterStudyYearLabelCompany': 'Filter study, year, labels or company',
	'home.yourStudies': 'Your studies',
	'home.noStudies': 'No studies available yet... Please contact your consultant. The Resilio team as been automatically notified.',
	'home.error.accessUnauthorized': 'Sorry, it look like you are not authorized to access any study.',
	'home.study': 'Study',
	'home.year': 'Year',
	'home.dataset': 'Perimeter',
	'home.noPerimeter': 'No perimeter added yet',
	'home.perimeters': 'Perimeters',
	'home.perimeter': 'Perimeter',
	'home.company': 'Organization',
	'home.showMoreStudies': 'Show &return() more studies',
	'home.open': 'Open',
	'home.archived': 'Archived',
	'home.manage': 'Manage',
	'home.inventory': 'Inventory',
	'home.maturity': 'Maturity',
	'home.result': 'Result',
	'home.study.showDatasets': 'Show perimeters',
	'home.study.checklistModal.title': 'Study checklist',
	'home.study.checklistModal.helper': `
This text area is used to write down different tasks to be done in the study. For example :
- [ ] Data collection
- [ ] Validation of the data
- [ ] Computation of the results

---
The text is written using the Markdown language. Here are some examples of frequently used syntaxes (switch to edit mode to see this example) :
# Title
## Subtitle
### Subsubtitle
*italic text*  
**bold text**  
[link](https://www.resilio-solutions.com)  
- list
	- [ ] checkbox
	- [x] checked checkbox

If you need more information about the Markdown syntax, you can visit the [Markdown guide](https://www.markdownguide.org/).
	`,
	'home.notification.newUpdate.title': '🎉🎉🎉 &return() New features available ! &return() You can click Yes to know more.',
	'home.notification.newUpdate.message': 'You can access anytime this update on the new functionalities by clicking on the button "What\'s New" at the top right of the home page.',

	'accountHeader.info': 'Info',
	'accountHeader.manage': 'Manage',
	'accountHeader.logout': 'Logout',
	'accountHeader.settings': 'Settings',
	'accountHeader.accountBadge': 'Access to your settings',

	'staticPage.unknown': 'Unknown',

	'userSettings': 'User settings',
	'userSettings.password': 'Password',
	'userSettings.save': 'Save',
	'userSettings.actualPassword': 'Actual password',
	'userSettings.newPassword': 'New password',
	'userSettings.confirmPassword': 'Confirm password',

	'userManage.manage': 'Manage',
	'userManage.users': 'Users',
	'userManage.users.selectGroup': 'Select a group',
	'userManage.companies': 'Organization',
	'userManage.companies.monthLicenseFilter': 'Filter the companies by license end date',
	'userManage.companies.monthLicense': 'Companies having less than x months until their license expires',
	'userManage.companies.monthLicense.moreThan6Month': 'Companies that have a lisence ending in more than 6 months',
	'userManage.users.selectCompany': 'Select a company',
	'userManage.permissions': 'Access',
	'userManage.permissions.companyToManageAssign': 'Assign organization to manage',
	'userManage.result_lists': 'Result lists',
	'userManage.no_result_lists': 'No results list yet ...',
	'userManage.user.create': 'Create new user',
	'userManage.user.remove': 'Remove account ?',
	'userManage.all': 'All',
	'userManage.create': 'Create',
	'userManage.sendMail': 'Send mail',
	'userManage.userCreate.error': 'An error occurred while trying to create this user, please make sure to fill the informations correctly',
	'userManage.userDelete.error': 'An error occurred while trying to delete this user, try to refresh the page or contact your consultant if the problem persists',
	'userManage.matchDomainError': 'Email is not consistent with registered organization domain (&domain(domain))',
	'userManage.remove': 'Remove',
	'userManage.deactivateAll': 'Deactivate all users',
	'userManage.save': 'Save',
	'userManage.login': 'Login',
	'userManage.user.name': 'Login',
	'userManage.email': 'Email',
	'userManage.user.firstName': 'First name (optional)',
	'userManage.user.lastName': 'Last name (optional)',
	'userManage.user.phoneNumber': 'Phone number (optional)',
	'userManage.user.email': 'Email',
	'userManage.user.ssoEmail': 'SSO Email (optional)',
	'userManage.user.company': 'Organization',
	'userManage.user.selectACompany': 'Select an organization',
	'userManage.users.groups': 'Groups',
	'userManage.user.active': 'Active',
	'userManage.user.status.active': 'Active',
	'userManage.user.status.inactive': 'Inactive',
	'userManage.user.status.pending': 'This user doesn\'t have access to any studies, please go the page permission to assign studies to this user',
	'userManage.user.created': 'Congratulations, the user &email() has been created successfully ! You can now give him access to a study by going to the &page(Access) page.',
	'userManage.user.createdActive': 'An automatic email has been sent to the user describing how to access Resilio Tech.',
	'userManage.user.cguLabel': 'Must accept terms and conditions',
	'userManage.user.setMustAcceptCGU': 'Give the right to accept terms and conditions to this user. At least one user per organization must accept terms and conditions. As long as no user has accepted terms and conditions, no user from this company will be able to access the application.',
	'userManage.user.groupByAccess': 'Does this user have access to the detailed impact per equipment category?',
	'userManage.user.warnDelete': 'Warning, the deletion of your account is definitive. All the data associated will also be deleted.',
	'userManage.user.delete.success': 'Your account has been deleted successfully. You will be redirected to the login page.',
	'userManage.user.canReadAndWrite': 'Can read and write in studies',
	'userManage.user.canReadAndWriteTooltip': 'Disable this option to set this user as read only. If the user has the status can read only, s.he won\'t be able to create or modify inventories, results or dashboards',
	'userManage.user.deactivateAll.success': 'All user accounts have been deactivated',
	'userManage.user.deactivateAll.warning': 'Are you sure you want to deactivate all users linked to this company?',
	'userManage.canManage': 'Can manage users ?',
	'userManage.deactivationInfo': 'To deactivate an account, contact support@resilio-solutions.com',
	'userManage.datasetAccess': 'Perimeter access',
	'userManage.company.name': 'Organization name',
	'userManage.company.domain': 'Organization domain',
	'userManage.company.start': 'Licence beginning',
	'userManage.company.end': 'Licence end',
	'userManage.company.rdbSecretToken': 'Resilio Database Secret Token',
	'userManage.company.create': 'Create new organization',
	'userManage.company.createError': 'An error occurred while trying to create this organization',
	'userManage.company.domainError': 'This email domain is already used by another organization',
	'userManage.company.nameError': 'This name is already used by another organization',
	'userManage.company.placeholder.name': 'Organization name',
	'userManage.company.placeholder.domain': 'ex: org.com',
	'userManage.company.remove': 'Remove organization ?',
	'userManage.company.filter': 'Filter organization',
	'userManage.company.cguNotAccepted': 'No user of this company has accepted the terms and conditions agreement yet',
	'userManage.studies': 'Studies',
	'userManage.studies.create': 'Create new study',
	'userManage.studies.studyArchived': 'Archived studies',
	'userManage.studies.edit': 'Edit study',
	'userManage.studies.archive': 'Archive study',
	'userManage.studies.unarchive': 'Unarchive study',
	'userManage.studies.delete': 'Delete study',
	'userManage.studies.labelFilter': 'Filter study by labels',
	'userManage.studies.colorSettings': 'Color Settings',
	'userManage.studies.colorSettings.title': 'Color settings',
	'userManage.studies.colorSettings.lcsMode': 'Life Cycle Steps',
	'userManage.studies.colorSettings.blockMode': 'Inventory Domains',
	'userManage.studies.colorSettings.indicatorMode': 'Environmental Indicators',
	'userManage.studies.colorSettings.error': 'An error occurred while trying to save your color settings',
	'userManage.studies.colorSettings.query.error': 'An error occurred while trying to retrieve your color settings',
	'userManage.studies.colorSettings.chooseColor': 'Choose an other color',
	'userManage.studies.colorSettings.warningDefaultColor': 'This feature allow you to personalize the color of your results and to unify the colors of domains, environmental indicators and life cycle steps across all perimeters of one study. By saving this proposition of colors, they will be applied to the entire study.',
	'userManage.studies.colorSettings.warningSavedColor': 'By saving this sets of colors, they will be applied to the entire study',
	'userManage.studies.delete.title': 'Delete study ?',
	'userManage.studies.delete.name': 'Delete study &study() ?',
	'userManage.studies.edit.title': 'Edit study',
	'userManage.studies.archive.title': 'Archive',
	'userManage.studies.unarchive.title': 'Unarchive',
	'userManage.studies.dataset.archive.title': 'Archive perimeter',
	'userManage.studies.dataset.unarchive.title': 'Unarchive perimeter',
	'userManage.studies.dataset.archive': 'Archive perimeter',
	'userManage.studies.dataset.unarchive': 'Unarchive perimeter',
	'userManage.studies.dataset.edit': 'Edit perimeter',
	'userManage.studies.dataset.delete': 'Delete perimeter',
	'userManage.studies.dataset.addGp': 'Add maturity good practices',
	'userManage.studies.add.dataset': 'Add perimeter',
	'userManage.studies.dataset.add.title': 'Add perimeter',
	'userManage.studies.create.name': 'Study name',
	'userManage.studies.create.nameEn': 'Study name (English)',
	'userManage.studies.create.nameFr': 'Study name (French)',
	'userManage.studies.create.consultants': 'Consultants',
	'userManage.studies.create.functionalUnit': 'Functional unit',
	'userManage.studies.create.year': 'Study year',
	'userManage.studies.create.company': 'Study organization',
	'userManage.studies.create.error': 'An error occurred while trying to create this study',
	'userManage.studies.delete.error': 'An error occurred while trying to delete this study',
	'userManage.studies.create.duplicateError': 'Datas are already used by another study in this organization',
	'userManage.studies.create.selectFunctionalUnit': 'Select a functional unit',
	'userManage.studies.create.addLabels': 'Add labels',
	'userManage.studies.dataset.create.name': 'Perimeter name',
	'userManage.studies.dataset.create.nameEn': 'Perimeter name (English)',
	'userManage.studies.dataset.create.nameFr': 'Perimeter name (French)',
	'userManage.studies.dataset.study': 'Study perimeter',
	'userManage.studies.edit.error': 'An error occurred while trying to edit this study',
	'userManage.studies.edit.duplicateError': 'Datas are already used by another perimeter in this study',
	'userManage.studies.dataset.delete.title': 'Delete perimeter ?',
	'userManage.studies.dataset.delete.warning': 'Warning, the &red(deletion of this perimeter is definitive) &dataset(). The results, the inventory and all the data associated with this inventory will also be deleted.',
	'userManage.studies.dataset.delete.delayWarning': 'Warning, the deletion of this perimeter can take a few minutes. Please wait and do not leave the page.',
	'userManage.studies.dataset.description': 'Describe your scope',
	'userManage.studies.dataset.addGp.success': 'Good practices have been added to your perimeter',
	'userManage.studies.deleteDataset.error': 'This perimeter cannot be deleted because it contains results',
	'userManage.studies.deleteStudy.error': 'This study cannot be deleted because it contains perimeters',
	'userManage.studies.filter.consultants': 'Filter consultants',
	'userManage.permissions.selectUser': 'Select a user',
	'userManage.permissions.noStudies': 'No studies yet...',
	'userManage.permissions.save': 'Save',
	'userManage.permissions.list.error': 'No user list found, it seems that there is no users in your organization. Please contact your consultant if you need further information.',
	'userManage.permissions.permissions.filter': 'Filter organization',
	'userManage.resultList.name': 'Name of the result list',
	'userManage.resultList.placeholder.name': 'Name',
	'userManage.resultList.description': 'Description of the result list',
	'userManage.resultList.placeholder.description': 'Description',
	'userManage.resultList.types': 'Types of results',
	'userManage.resultList.create': 'Create new result list',
	'userManage.resultList.create.error': 'An error occurred while trying to create this result list',
	'userManage.resultList.remove': 'Remove result list ?',
	'userManage.resultList.remove.error': 'An error occurred while trying to remove this result list',
	'userManage.resultList.save.error': 'An error occurred while trying to save this result list',
	'userManage.resultList.usedInComparison': 'This result will be compared to a benchmark if one has been selected',
	'userManage.resultList.needSample': 'This result will be need a benchmark to be calculated',
	'userManage.dataBase.newImpactFactors': 'Create new impact factors',
	'userManage.dataBase.importFile': 'Import a file',
	'userManage.dataBase.equipmentName': 'Equipment name',
	'userManage.dataBase.equipmentName.desc': 'Main equipment name, displayed in bold in the inventory (ex : Laptop, dell latitude)',
	'userManage.dataBase.equipmentSpecification': 'Equipment specification',
	'userManage.dataBase.equipmentSpecification.desc': 'Differentiating attribute of the equipment, displayed below the equipment name in the inventory (ex : high end, 2CPU - 13 inch screen…).',
	'userManage.dataBase.equipmentDescription': 'Equipment description',
	'userManage.dataBase.equipmentDescription.desc': 'Details on the equipment : it can be a detailed description, an indication on where to find this type of data… It is displayed in the last column of the inventory when hovering the tooltip ’?’',
	'userManage.dataBase.equipmentCategory': 'Equipment category',
	'userManage.dataBase.equipmentCategory.desc': 'Category of the device (ex: Laptops, Purchase of IT services…). All equipment within a category should have the same unit.',
	'userManage.dataBase.equipmentIsAService': 'Is it a service?',
	'userManage.dataBase.equipmentIsAService.desc': 'The added line is a service if the item is not an IT equipment (ex : distance travelled per IT staff, purchase of IT services). No lifetime is associated to this item.',
	'userManage.dataBase.equipmentDefaultLifeTime': 'Default lifetime',
	'userManage.dataBase.equipmentDefaultLifeTime.desc': 'For services, the default lifetime corresponds to the duration of usage considered in the study. In most cases, we consider 1 year of usage (with the exception of data related to buildings).',
	'userManage.dataBase.equipmentCountryOfUse': 'Country of use',
	'userManage.dataBase.equipmentCountryOfUse.desc': 'Enter the country of use, if you used an electric mix to compute the emission factors in the use phase.',
	'userManage.dataBase.equipmentAdditionalInfo': 'Additional informations',
	'userManage.dataBase.equipmentAdditionalInfo.desc': 'Add any additional information wanted',
	'userManage.dataBase.equipmentMass': 'Mass (kg)',
	'userManage.dataBase.equipmentMass.desc': 'Enter the mass of the device.',
	'userManage.dataBase.electricityConsumption': 'Electricity consumption (KWh)',
	'userManage.dataBase.electricityConsumption.desc': 'Yearly electric consumption if the devices (in kWh).',
	'userManage.dataBase.companiesManaged': 'Companies you managed',
	'userManage.dataBase.companiesManaged.desc': 'This equipment is private and will only be used by the company you have selected',
	'userManage.dataBase.impactFactorsFormat': 'Impact factors format',
	'userManage.dataBase.impactFactorsFormat.desc': 'Select a data format for the impact factors you want to create (coming soon)',
	'userManage.dataBase.equipmentImpactFactors': 'Impact factors',
	'userManage.dataBase.createEquipmentType': 'Create an equipment type',
	'userManage.dataBase.error.removeEquipmentType': 'An error occurred while trying to remove this equipment type',
	'userManage.dataBase.error.updateEquipmentType': 'An error occurred while trying to update this equipment type',
	'userManage.dataBase.error.createEquipmentType': 'An error occurred while trying to create this equipment type',
	'userManage.dataBase.error.mandatoryNotFilled': 'Please, fill all the mandatory filled',
	'userManage.dataBase.error.noDefaultLifetime': 'An Equipment as a service need a default lifetime',
	'userManage.dataBase.error.nonNumericalValue': 'Please, only enter numerical values for this filled',

	'userManage.blocks': 'Inventory Domains',
	'userManage.samples': 'Benchmarks',
	'userManage.maturityDomains': 'Maturity Domains',
	'userManage.database': 'Database',
	'userManage.equipmentCategoriesOrder': 'Equipment Categories Order',
	'userManage.equipmentTypeOrder': 'Equipment Type Order',
	'userManage.indicatorOrder': 'Indicator Order',
	'userManage.ErrorTestView': 'Error Testing View',
	'userManage.sample.create': 'Create new benchmark',
	'userManage.sample.create.error': 'An error occurred while trying to create this benchmark',
	'userManage.sample.update.error': 'An error occurred while trying to update this benchmark',
	'userManage.sample.functionalUnitError': 'Perimeters must be from the same functional unit',
	'userManage.sample.remove': 'Remove benchmark ?',
	'userManage.sample.datasets': 'Perimeters: (&count(count) selected for this benchmark)',
	'userManage.sample.desc': 'Description',
	'userManage.sample.placeholder.desc': 'Description',
	'userManage.sample.placeholder.name': 'Name',
	'userManage.sample.name': 'Name of the benchmark',
	'userManage.sample.useForValidation': 'Use for validation',
	'userManage.sample.resultsView': 'Results view',
	'userManage.sample': 'Benchmark',
	'userManage.privateSample': 'Private Benchmarks',
	'userManage.publicSample': 'Public Benchmarks',
	'userManage.sample.datasets.unification': 'Perimeters unification',
	'userManage.sample.datasets.unification.desc': 'LCA parameters of the selected perimeters will be unified for this benchmark.',
	'userManage.sample.datasets.unification.check': 'Check the perimeters',
	'userManage.sample.datasets.unification.checked': 'Differences found that unification can resolve',
	'userManage.sample.datasets.unification.force': 'Force unification by default',
	'userManage.sample.datasets.unification.proceed': 'Proceed to unification',
	'userManage.sample.datasets.unification.cancel': 'Cancel',
	'userManage.sample.datasets.unification.complete': 'Unification complete',
	'userManage.sample.datasets.unification.good': 'The selected perimeters are identical',
	'userManage.sample.datasets.unification.noChanges': 'This following perimeters have some differences but unification can\'t resolve them. Please check the differences and try to unify them manually.',
	'userManage.sample.datasets.unification.close': 'Close',
	'userManageUsersView.userPrivilegesConfirmationModal.title': 'Confirmation',
	'userManageUsersView.userPrivilegesConfirmationModal.description': 'You are about to give privileges to the user &email(name). Confirmation from a administrator will be needed to validate this privileges. Are you sure you want to continue and send the request ?',
	'userManageUsersView.userPrivilegesConfirmationModal.confirm': 'Confirm request of privileges',
	'userManage.sample.xCountHiddenDataset': 'This benchmark contains &count(x) perimeters you don\'t have rights to access.',
	'userManage.sample.private': 'Make the benchmark private (hidden for users external to Resilio).',
	'userManage.sample.privateDesc': 'If this benchmark is made public, external consultant (not part of Resilio) will be able to view the name of the benchmark and see the aggregated results (there won\'t see however the name of the perimeters within the benchmark if they don\'t have access to it).',
	'userManage.functional_unit': 'Functional Unit',
	'userManage.lca_inventory': 'Life Cycle Inventory',
	'userManage.lca_maturity_model': 'Maturity Model',
	'userManage.lca_template': 'LCA Template',
	'userManage.functionalUnit.create': 'Create new functional unit',
	'userManage.functionalUnit.name': 'Name of the functional unit',
	'userManage.functionalUnit.private': 'Private',
	'userManage.functionalUnit.placeholder.name_fr': 'Name in French',
	'userManage.functionalUnit.placeholder.name_en': 'Name in English',
	'userManage.functionalUnit.delete': 'Remove functional unit ?',
	'userManage.functionalUnit.defaultLcaTemplate': 'Default LCA template',
	'userManage.functionalUnit.defaultLcaTemplate.none': 'No template by default',
	'userManage.lcaInventory.createLCAInventory': 'Create new LCI',
	'userManage.lcaInventory.name': 'Name of the LCI',
	'userManage.lcaInventory.description': 'Description of the LCI',
	'userManage.lcaInventory.error.noName': 'Please enter a name for the LCI',
	'userManage.lcaInventory.error.noDescription': 'Please enter a description for the LCI',
	'userManage.lcaInventory.error.noEquipments': 'Please add at least one equipment to the LCI',
	'userManage.lcaInventory.error.noSettings': 'Please add at least one setting to the LCI',
	'userManage.lcaInventory.error.blockNotSet': 'Please select a block for all equipments of the LCI',
	'userManage.lcaInventory.error.network': 'An error occurs while trying to save the LCI',
	'userManage.lcaInventory.equipments': '&count(Count) Equipment(s)',
	'userManage.lcaInventory.settings': '&count(Count) Setting(s)',
	'userManage.lcaInventory.categorySelection': 'Select a category',
	'userManage.lcaInventory.selectADomain': 'Select a domain',
	'userManage.lcaInventory.quitNotSaved': 'You have unsaved changes',
	'userManage.lcaInventory.quitWithoutSaving': 'Quit without saving',
	'userManage.lcaInventory.saveAndQuit': 'Save and quit',
	'userManage.lcaInventory.noMoreDomain': 'You already added all existing domains',
	'userManage.lcaInventory.errorDependency': 'You can\'t remove this LCI. It may be used by a LCA Template',
	'userManage.lcaInventory.editEquipments': 'Edit equipments',
	'userManage.lcaInventory.editSettings': 'Edit settings',
	'userManage.lcaMaturity.createLCAMaturity': 'Create new Maturity Model',
	'userManage.lcaMaturity.name': 'Name of the Maturity Model',
	'userManage.lcaMaturity.description': 'Description of the Maturity Model',
	'userManage.lcaMaturity.error.noName': 'Please enter a name for the Maturity Model',
	'userManage.lcaMaturity.error.noDescription': 'Please enter a description for the Maturity Model',
	'userManage.lcaMaturity.error.noEquipments': 'Please add at least one Good Practice to the Maturity Model',
	'userManage.lcaMaturity.error.network': 'An error occurs while trying to save the Maturity Model',
	'userManage.lcaMaturity.goodPractices': '&count(Count) Good Practice(s)',
	'userManage.lcaMaturity.error.noGoodPractice': 'Please add at least one Good Practice to the Maturity Model',

	'userManage.lcaTemplate.createLcaTemplate': 'Create new LCA Template',
	'userManage.lcaTemplate.nameEn': 'Name of the LCA Template (EN)',
	'userManage.lcaTemplate.nameFr': 'Name of the LCA Template (FR)',
	'userManage.lcaTemplate.descriptionEn': 'Description of the LCA Template (EN)',
	'userManage.lcaTemplate.descriptionFr': 'Description of the LCA Template (FR)',
	'userManage.lcaTemplate.inventory': 'Inventory',
	'userManage.lcaTemplate.selectInventory': 'No inventory',
	'userManage.lcaTemplate.maturity': 'Maturity',
	'userManage.lcaTemplate.selectMaturity': 'No maturity',
	'userManage.lcaTemplate.functionalUnit': 'Functional Unit',
	'userManage.lcaTemplate.selectFunctionalUnit': 'Select a functional unit',
	'userManage.lcaTemplate.resultList': 'Result List',
	'userManage.lcaTemplate.selectResultList': 'No result list',
	'userManage.lcaTemplate.indicators': 'Indicators',
	'userManage.lcaTemplate.lifeCycleSteps': 'Life Cycle Steps',
	'userManage.lcaTemplate.deleteLcaTemplate': 'Remove LCA Template ?',
	'userManage.lcaTemplate.deleteLcaTemplateConfirm': 'Are you sure you want to remove this LCA Template ?',
	'userManage.lcaTemplate.private': 'Make this LCA template private',
	'userManage.lcaTemplate.associatedCompany': 'Associated organization',

	'userManageSettings.sso': 'SSO',
	'userManageSettings.sso.title': 'Enter your SSO credentials',
	'userManageSettings.sso.clientId': 'Client ID',
	'userManageSettings.sso.clientSecret': 'Client Secret',
	'userManageSettings.sso.authorityUrl': 'Authorization URL',
	'userManageSettings.sso.provider': 'Provider',
	'userManageSettings.sso.scope': 'Scope',
	'userManageSettings.error.noCompany': 'There is no organization associated to your account. Please contact your administrator.',
	'userManageSetting.account': 'Account',
	'userManageSettings.infos.redirectUriMessage': 'Redirect URI must be set in your SSO provider.',
	'userManageSettings.infos.redirectUri': 'Please, provide this URI : &bold(https://app.resilio.tech/sso/redirect)',

	'choiceSelector.searchInList': 'Search in list',
	'choiceSelector.option': 'Option',
	'choiceSelector.selector': 'Selector',
	'choiceSelector.disabled': 'Disabled',

	'multiChoiceSelector.placeholder': 'Select one or multiple options',

	'datasetSelector.company': 'Organization',
	'datasetSelector.study': 'Study',
	'datasetSelector.perimeter': 'Perimeter',
	'datasetSelector.all': 'Aggregate',

	'downSidebar.method': 'Method',
	'downSidebar.glossary': 'Glossary',
	'downSidebar.help': 'Help',
	'downSidebar.accessibility': 'Accessibility',
	'downSidebar.ecoDesign': 'Eco-design',

	'sidebar.kanbanTable': 'Tasks overview',
	'sidebar.home': 'Home',
	'sidebar.yourStudies': 'Your Studies',
	'sidebar.studyManagement': 'Management',
	'sidebar.inventory': 'Inventory',
	'sidebar.overview': 'Overview',
	'sidebar.maturity': 'Maturity',
	'sidebar.validation': 'Validation',
	'sidebar.results': 'Results',
	'sidebar.groupby': 'Impacts Group by Equipement',
	'sidebar.settings': 'Settings',
	'sidebar.manage': 'Manage',
	'sidebar.dashboards': 'Dashboards',
	'sidebar.dashboard.evolution': 'Evolution',
	'sidebar.dashboard.comparison': 'Comparison',
	'sidebar.dashboard.simulation': 'Simulation',
	'sidebar.contactConsultants' : 'Contact your consultants',
	'sidebar.explore': 'Explore',
	'sidebar.helpSection.lastUpdate': 'Last update &time(time) days ago',

	'contact.title': 'Contact your consultants',
	'contact.consultantName': 'Consultant name',
	'contact.email': 'Consultant email',
	'contact.phoneNo': 'Consultant phone number',

	'alerts.valueSaved': 'Values saved.',

	'error.manage.notAllowed': 'You are not allowed to perform this action.',
	'error.manage.cantDeleteDatasetWithDefinitive': 'You cannot delete a perimeter with definitive version of result.',

	'errorBoundary.errorOccurs': 'An error occurred.',
	'errorBoundary.reloadPage': 'Please reload the page.',

	'errorModal.title': 'Error',
	'errorModal.refresh': 'Please refresh and try again.',
	'errorModal.contact': 'If the problem persists, please contact an administrator.',
	'errorModal.logout': 'Logout',
	'errorModal.backHome': 'Back home',

	'errorRenderer.auth': 'Authentication Failure',
	'errorRenderer.fetch': 'Failed to fetch',
	'errorRenderer.process': 'The main process failed due to an error. Please contact your administrator.',
	'errorRenderer.accessDenied': 'Access denied',
	'errorRenderer.accessReconnect': 'You cannot access this data. Try to reconnect.',
	'errorRenderer.networkIssue': 'Network issue',
	'errorRenderer.networkReconnect': 'Network connection interrupted. Try to reconnect.',
	'errorRenderer.userReconnect': 'User disconnected. Try to reconnect.',

	'noMobile.warning': 'Warning',
	'noMobile.support': 'Mobile format support',
	'noMobile.description': 'Mobile support or lower window size is not adequate for data entry currently. However, we are studying the possibilities of making the application accessible by mobile in the future.',

	'loading': 'Loading',
	'somethingWentWrong': 'Something went wrong',

	'table.lineRequired': 'Line required',
	'table.comments': 'Comments',
	'table.commentsValidation': 'Consultant comment',
	'table.electricityConsumption': 'Enter the annual electricity consumption of this item in kWh here (if you don\'t, we\'ll use a default value).',
	'table.electricityConsumption.unit': 'kWh/year/item',
	'table.placeholder.comment': 'Comment',
	'table.placeholder.commentValidation': 'Consultant comment',
	'table.metadata': 'Metadata',
	'table.noMetatada': 'This equipment type does not contain any metadata',
	'table.quality.low': 'Low',
	'table.quality.medium': 'Medium',
	'table.quality.high': 'High',
	'table.quality.low.tooltip': 'Low data quality',
	'table.quality.medium.tooltip': 'Medium data quality',
	'table.quality.high.tooltip': 'High data quality',
	'table.input.input': 'input',
	'table.input.priority': 'Priority field',
	'table.input.editMode': 'Edit mode',
	'table.input.switchToEditMode': 'Switch to edit mode',

	'tableAssign.button': 'Assign line to user',
	'tableAssign.none': 'None',
	'tableAssign.modalTitle': 'Assign line to users',
	'tableAssign.modalButton': 'Assign',
	'tableAssign.modalButtonClose': 'Close',
	'tableAssign.modalLabel': 'Users list',
	'tableAssign.modal.userFilter.placeholder': 'Filter or create user',
	'tableAssign.modal.userFilter.label': 'Filter or create user',
	'tableAssign.modal.createLambda.button': 'Add a guest user',
	'tableAssign.modal.userList.user': 'User',
	'tableAssign.modal.userList.notification': 'Notification',
	'tableAssign.modal.noUserList': 'No user found',
	'tableAssign.modal.userFilter.lambda.emailNotCorrespondToCompany': 'Email is not consistent with registered organization domains.',
	'tableAssign.modal.noUserAvailable': 'No assignable collaborators found',
	'tableAssign.modal.failToCreate': 'An error occurred while creating the user.',
	'tableAssign.modal.failToSendNotification': 'An error occurred while sending notifications.',

	'tableAssign.notification.title': 'You have invited a guest user',
	'tableAssign.notification.emailSent': 'An email has been sent to the user',

	'domains': 'Domains',
	'settings': 'Settings',
	'equipments': 'Equipments',
	'equipment': 'Equipment',

	'equipment.deprecated.tooltip': 'Our R&D team identified a data inconsistency affecting the equipment\'s environmental footprint calculation. Efforts are underway to promptly update the impact factor.',

	'datasetOverview': 'Detailed view',
	'datasetOverview.title': 'Detailed view',
	'datasetOverview.temporaryAssigned': 'Sent the notification mail to users ?',
	'datasetOverview.table.title.percent': 'Percent',
	'datasetOverview.table.title.name': 'Name',
	'datasetOverview.table.title.quantity': 'Quantity',
	'datasetOverview.table.title.lifetime': 'Lifetime',
	'datasetOverview.table.title.quality': 'Quality',
	'datasetOverview.table.title.comment': 'Comment',
	'datasetOverview.table.title.help': 'Help',
	'datasetOverview.table.title.assign': 'Assign',
	'datasetOverview.table.noData': 'No equipment found for this perimeter',
	'datasetOverview.notification': 'Notification',
	'datasetOverview.notificationText': 'Send the notification to users assigned on this perimeter ?',
	'datasetOverview.notificationPrevent': 'Do you really want to leave the page without sending the notification email(s)?',
	'datasetOverview.notify': 'Notify',
	'datasetOverview.notify.leaveMessage': 'You have unnoticed users assigned on this perimeter. Do you want to leave this page ?',
	'datasetOverview.defaultLifetime': 'Default lifetime',

	'studyWorkflow.studiesWorkflow': 'Studies workflow',
	'studyWorkflow.studies': 'Studies',
	'studyWorkflow.noStudy': 'No study yet...',

	'studyKanban.title': 'Study &studyName()',
	'studyKanban.priority': 'Priority',
	'studyKanban.global': 'Global',
	'studyKanban.settings': 'Settings',
	'studyKanban.maturity': 'Maturity',
	'studyKanban.control': 'control',
	'studyKanban.copy': 'Copy perimeter',
	'studyKanban.newDatasetName': 'Name of the new perimeter',
	'studyKanban.newDatasetNameEn': 'Name (English)',
	'studyKanban.newDatasetNameF': 'Name (French)',
	'studyKanban.newDatasetDescription': 'Description of the new perimeter',
	'studyKanban.datasetName': 'Perimeter name',
	'studyKanban.newDatasetError': 'An error occurred while creating the new perimeter',
	'studyKanban.selectDatasetToCopy': 'Select perimeter to copy',
	'studyKanban.copy.empty': 'Emptying',
	'studyKanban.copy.maturity': 'Copy Maturity data',
	'studyKanban.copy.validation': 'Copy Validation data',
	'studyKanban.noStudy': 'No study yet...',
	'studyKanban.noDataset': 'No perimeter yet...',
	'studyKanban.studies': 'Studies',
	'studyKanban.toStart': 'To Start',
	'studyKanban.start': 'Start',
	'studyKanban.startTask': 'Start task',
	'studyKanban.collect': 'Collect in progress',
	'studyKanban.validationOngoing': 'Validation in progress',
	'studyKanban.validationComplete': 'Validation completed',
	'studyKanban.continue': 'Continue',
	'studyKanban.results': 'Results',
	'studyKanban.resultsAvailable': 'Results available',
	'studyKanban.quickWins': 'Quick wins',
	'studyKanban.others': 'Others',
	'studyKanban.copyDataset': 'Copy perimeter',
	'studyKanban.datasetEmpty': 'Empty perimeter',
	'studyKanban.errorNameDataset': 'Perimeter name invalid',
	'studyKanban.description': 'Describe your scope',
	'studyKanban.datasetCreation.title': 'Add A Perimeter',
	'studyKanban.datasetCreation.description': 'Choose the way to create your perimeter',
	'studyKanban.datasetCreation.template': 'From a default perimeter',
	'studyKanban.datasetCreation.create': 'Create from scratch',
	'studyKanban.createFromScratch.title': 'Create a perimeter from scratch',
	'studyKanban.datasetCreation.copy': 'Duplicate a perimeter',
	'studyKanban.requestCalculation': 'Request a new calculation',
	'studyKanban.addDatasetFirst': 'Please add a perimeter before adding a CMDB',
	'studyKanban.selectTemplate': 'Select a template',
	'studyKanban.dataset.create.name': 'Perimeter name',
	'studyKanban.dataset.create.nameEn': 'Perimeter name (English)',
	'studyKanban.dataset.create.nameFr': 'Perimeter name (French)',

	'studyWorkflow.title': 'Workflow',

	'settings.title': 'Organizational data',
	'settings.field.parameter': 'Parameter',
	'settings.field.unit': 'Unit',
	'settings.field.value': 'Value',
	'settings.field.quality': 'Quality',
	'settings.field.comment': 'Comment',
	'settings.info.parameter': 'Parameter',
	'settings.info.unit': 'Unit',
	'settings.info.value': 'Value',
	'settings.info.quality': 'Quality',
	'settings.info.comment': 'Comment',
	'settings.tip': 'The settings are used to validate your data and to provide LCA parameters needed for the calculation of the footprint.',

	'inventory': 'inventory',
	'inventory.title': 'Inventory',
	'inventory.showMore': 'Show more equipments',
	'inventory.subtitle.defaultLifetime': 'for &count(count) year&plural(plural)',
	'inventory.subtitle.averageLifetime': 'with an average lifespan of &count(count) year&plural(plural)',
	'inventory.tooltip.noRefFlux': 'Indicate a quantity and the reference flux in LCA settings to have the ratio of number of equipment per user',
	'inventory.field.sortable': 'Sort',
	'inventory.field.type': 'Type',
	'inventory.field.unit': 'Unit',
	'inventory.field.quantity': 'Quantity',
	'inventory.field.internalLifetime': 'Internal lifetime (years)',
	'inventory.field.reuseFraction': 'Reuse (%)',
	'inventory.field.reuseLifetime': 'Reuse (years)',
	'inventory.field.lifetime': 'Total lifetime (years)',
	'inventory.field.dataQuality': 'Data quality',
	'inventory.field.comment': 'Comments',
	'inventory.field.electricityConsumption': 'Electricity consumption (kWh)',
	'inventory.field.assigned': 'Assigned',
	'inventory.field.unassigned': 'Others',
	'inventory.info.comment': 'Comments',
	'inventory.info.matchingScore': 'You have requested the equipment &requestedName(), the closest reference we\'ve found in our database is &foundName(). The matching score is &matchingScore(/5).',
	'inventory.info.matchingScore.requestNewReference': '&break() If you think the reference we found is not correct, please contact your consultant to request a new reference.',
	'inventory.info.help': 'Help',
	'inventory.info.sortable': 'Sort',
	'inventory.info.type': 'Type',
	'inventory.info.unit': 'Unit',
	'inventory.info.quantity': 'Quantity of IT equipment (Installed equipment + stock not used)',
	'inventory.info.internalLifetime': 'Lifetime of the equipment within the organization (average)',
	'inventory.info.reuseFraction': 'Pourcentage of reuse of an equipment type (for example in the case this equipment is rented or refurbished\n' +
		'after the end of warranty). Indicate 0 if the equipment is not reused.',
	'inventory.info.reuseLifetime': 'Lifetime of the equipment during its reuse period (external to the organization). Indicate 0 if the equipment\n' +
		'is not reused.',
	'inventory.info.lifetime': 'Internal lifetime + Reuse fraction*Reuse Lifetime. It is calculated automatically if the Internal Lifetime, %\n' +
		'of Reuse and reuse lifetime are given.',
	'inventory.info.dataQuality': 'Data quality',
	'inventory.info.dataQuality.high':'High quality: reliable data (e.g. extracted from a recent and updated inventory, an invoice...)',
	'inventory.info.dataQuality.medium':'Medium quality: data determined using realistic hypotheses (e.g.: it is estimated that 1/3 of the employees have a dual screen)',
	'inventory.info.dataQuality.low':'Low quality: data determined using questionable hypotheses, or using an average value from a benchmark or another perimeter',
	'inventory.info.dataQuality.highShort':'High quality',
	'inventory.info.dataQuality.mediumShort':'Medium quality',
	'inventory.info.dataQuality.lowShort': 'Low quality',
	'inventory.editEquipment.delete.warning': 'If there are some results associated with this equipment, they will be deleted as well. &red(Are your sure you want to delete this equipment and associated results?)',
	'inventory.editEquipment.delete.warningNoResults': 'Are you sure you want to delete this equipment?',
	'inventory.addEquipment.buttonLabel': 'Add equipment',
	'inventory.addEquipment.cmdb.buttonLabel': 'Import CMDB',
	'inventory.addEquipment.modal.title': 'Add equipment',
	'inventory.addEquipment.modal.header': 'Add equipment by',
	'inventory.addEquipment.modal.error.addEquipments': 'An error occurred while trying to add this equipments',
	'inventory.addEquipment.modal.explore': 'Explore',
	'inventory.addEquipment.modal.reference': 'Reference',
	'inventory.addEquipment.modal.configuration': 'Configuration',
	'inventory.addEquipment.modal.tooltipCategory': 'At the moment, product references are available for: &break() Laptops, servers, switchs, storages, computer monitors, televisions, desktops, virtual machines. &break() &break() In case of a missing reference, you can create a configuration of those devices in the Configuration tab, or contact our support',
	'inventory.addEquipment.modal.import': 'Import',
	'inventory.addEquipment.modal.title.block': 'Add equipment to this domain',
	'inventory.addEquipment.modal.search': 'Search for a product reference or an equipment type',
	'inventory.addEquipment.modal.quantity': 'Quantity',
	'inventory.addEquipment.modal.recap': 'Equipment recap.',
	'inventory.addEquipment.modal.hddSizeDefault': 'Default HDD disk size are 1To',
	'inventory.addEquipment.modal.explore.loadMore': 'Load more equipments...',
	'inventory.searchEquipment': 'Search for an equipment type',
	'inventory.lockedDescription': 'Your data is being validated, or is being used in the results associated with this scope. The collection of your inventory is thus blocked. You can unlock the data collection from the &link(validation page).',
	'inventory.archivedDescription': 'This scope is archived. You can no longer modify it. If you want an overview of the data entered, please go to the &link(detailed view)',
	'inventory.filter.tooltip': 'Filter equipments',
	'inventory.filter.label': 'Filter',

	'cmdb.file.limitExceeded': 'File size limit of 25MB exceeded. Please, split your file to import it.',
	'inventory.addEquipment.modal.cmdbAssistant.header': 'CMDB Assistant',
	'inventory.addEquipment.modal.cmdbAssistant.columnTitle': 'Help us match the different fields of your file with our model',
	'inventory.addEquipment.modal.cmdbAssistant.columnMandatory': 'These categories are mandatory to import your CMDB, please select the corresponding fields of your file to continue',
	'inventory.addEquipment.modal.cmdbAssistant.columnOptional': 'Select the corresponding fields of your file to help us precise the calculation of the footprint. These categories are optional, you can chose to skip this step, and default value will be considered for each field ignored. ',
	'inventory.addEquipment.modal.cmdbAssistant.unusedColumn': 'According to your selection, those columns don’t match any of our CMDB categories and will not be considered in the footprint calculation (not mandatory)',
	'inventory.addEquipment.modal.cmdbAssistant.column.manufacturer': 'Manufacturer',
	'inventory.addEquipment.modal.cmdbAssistant.column.model': 'Model Name',
	'inventory.addEquipment.modal.cmdbAssistant.column.type': 'Type',
	'inventory.addEquipment.modal.cmdbAssistant.column.perimeter': 'Perimeter',
	'inventory.addEquipment.modal.cmdbAssistant.column.block': 'Block',
	'inventory.addEquipment.modal.cmdbAssistant.column.ramCount': 'RAM Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.ramSize': 'RAM total size (GB)',
	'inventory.addEquipment.modal.cmdbAssistant.column.cpuCount': 'CPU Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.cpuName': 'CPU Name',
	'inventory.addEquipment.modal.cmdbAssistant.column.gpuCount': 'GPU Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.gpuName': 'GPU Name',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdCount': 'SSD Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdName': 'SSD Name',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdSize': 'SSD total size (GB)',
	'inventory.addEquipment.modal.cmdbAssistant.column.ssdTechno': 'SSD Technology',
	'inventory.addEquipment.modal.cmdbAssistant.column.hddCount': 'HDD Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.screenSize': 'Screen Size',
	'inventory.addEquipment.modal.cmdbAssistant.column.screenTechno': 'Screen Technology',
	'inventory.addEquipment.modal.cmdbAssistant.column.portCount': 'Port Count',
	'inventory.addEquipment.modal.cmdbAssistant.column.portCountPerModule': 'Port Count per Module',
	'inventory.addEquipment.modal.cmdbAssistant.column.rackUnit': 'Rack Unit',
	'inventory.addEquipment.modal.cmdbAssistant.column.usage': 'Usage',
	'inventory.addEquipment.modal.cmdbAssistant.column.country': 'Country (VM usage)',
	'inventory.addEquipment.modal.cmdbAssistant.column.modemType': 'Modem Type',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.manufacturer': 'The manufacturer of the equipment',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.model': 'The model name of the equipment',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.type': 'The type of the equipment',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.perimeter': 'The perimeter where the equipment is used',
	'inventory.addEquipment.modal.cmdbAssistant.tooltip.block': 'The domain where the equipment is used',
	'inventory.addEquipment.modal.cmdbAssistant.validateAll': 'Validate all',
	'inventory.addEquipment.modal.cmdbAssistant.noMatch': 'No match found',
	'inventory.addEquipment.modal.cmdbAssistant.matchBlockAndCategory': 'You must select in which domain each equipment category will be inserted',
	'inventory.addEquipment.modal.cmdbAssistant.info.noBlock': 'Domain selection will be done later in assistant',

	'inventory.addEquipment.modal.cmdbAssistant.value.onlyNumerical': 'The following columns must contain numerical values, please replace the non-numerical values',
	'inventory.addEquipment.modal.cmdbAssistant.value.ramSizeMaxWarning': 'For RAM, the maximum size allowed is 100000 GB (768 GB for virtual machines)',

	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.type': 'We have found items in your CMDB that does not match with our categories configuration.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.type': 'Do those items match with the category:',
	
	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.perimeter': 'We have found scopes in your CMDB that do not match with your existing perimeters. You can either create new perimeters from those scopes or map them to existing perimeters.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.perimeter': 'Do those items match with your perimeters:',
	
	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.country': 'We have found items in your CMDB that don’t match with our available countries of use.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.country': 'Do those items match with the country:',
	
	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.ssdTechno': 'We have found items in your CMDB that don’t match with our ssd technologies configuration.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.ssdTechno': 'Do those items match with the ssd technology:',
	
	'inventory.addEquipment.modal.cmdbAssistant.value.unMatch.screenTechno': 'We have found items in your CMDB that don’t match with screen technologies configuration.',
	'inventory.addEquipment.modal.cmdbAssistant.value.isItemMatching.screenTechno': 'Do those items match with the screen technology:',
	
	'inventory.addEquipment.modal.cmdbAssistant.value.thanksForMatch': 'Thank you for having cleaned and matched the values of your CMDB',
	'inventory.addEquipment.modal.cmdbAssistant.value.thanksAlreadyMatch': 'Your CMDB was already clean, thank you!',
	'inventory.addEquipment.modal.cmdbAssistant.value.nowAdd': 'You can now add it to your inventory.',
	'inventory.addEquipment.modal.cmdbAssistant.value.thanksForColumn': 'Thank you for helping us match your CMDB fields with our model !',
	'inventory.addEquipment.modal.cmdbAssistant.value.nowClean': 'We will now proceed on cleaning and matching the values of your CMDB.',
	'inventory.addEquipment.modal.cmdbAssistant.value.warningPreviousStep': 'Be careful, if you go back, you\'ll return to the initial column state of your import.',
	'inventory.addEquipment.modal.cmdbAssistant.value.deleteItems': 'All lines containing the value &item(item) will be deleted',
	'inventory.addEquipment.modal.cmdbAssistant.value.replaceItems': 'All the values: &item(item) will be replaced by: &newValue(replacement)',
	'inventory.addEquipment.modal.cmdbAssistant.value.unmatchInfo': 'If you need to take those items into account but any of our guess match, please contact a consultant.',
	'inventory.addEquipment.modal.cmdbAssistant.value.undo': 'Undo change',
	'inventory.addEquipment.modal.cmdbAssistant.value.selector.placeholder': 'Select a value to match',
	'inventory.addEquipment.modal.cmdbAssistant.value.createPerimeter': 'Create a new perimeter',
	'inventory.addEquipment.modal.cmdbAssistant.value.mapToPerimeter': 'Map to &perimeter(perimeter)',
	'inventory.addEquipment.modal.cmdbAssistant.block.selector.placeholder': 'Select a domain',
	'inventory.addEquipment.modal.cmdbAssistant.initialValueTruncated': 'The name is too long, it will be trimmed to 30 characters',

	'inventory.specification': 'Choose a specification in the list below',
	'inventory.addEquipment.modal.selectDatabase': 'Select one or more databases',
	'inventory.addEquipment.modal.selectedDatabase': 'Selected database(s)',
	'inventory.addEquipment.modal.changeDatabase': 'Change database',
	'inventory.addEquipment.modal.closeButton': 'Close',
	'inventory.addEquipment.modal.addButton': 'Add',
	'inventory.addEquipment.modal.name': 'Equipment name',
	'inventory.addEquipment.modal.categories': 'Equipment categories',
	'inventory.addEquipment.modal.equipments': 'Equipments',
	'inventory.addEquipment.modal.selectAType': 'Or select a category of equipment',
	'inventory.addEquipment.modal.selectAEquipment': 'Select an equipment',
	'inventory.addEquipment.modal.searchEquipment': 'Search an equipment',
	'inventory.addEquipment.modal.searchEquipmentTitle': 'Search an equipment',
	'inventory.addEquipment.modal.selectADomain': 'Select a domain',
	'inventory.addEquipment.modal.youAreInADomain': 'You are in the domain &domain(domainName)',
	'inventory.addEquipment.modal.selectASpec': 'Select a specificity',
	'inventory.addEquipment.modal.applyToEntireStudy': 'Apply to entire study',
	'inventory.addEquipment.modal.selectCMDB': 'Select or drag and drop your CMDB file',
	'inventory.addEquipment.modal.recapOfSelectedEquipment': 'Recap of selected equipments',
	'inventory.addEquipment.modal.backToSelection': 'Back to selection',
	'inventory.addEquipment.modal.selectedCategory': 'Selected category',
	'inventory.addEquipment.modal.addNewConf': 'Add a new configuration:',
	'inventory.addEquipment.modal.unknown': 'Unknown',
	'inventory.addEquipment.modal.serverType': 'Server type',
	'inventory.addEquipment.modal.cpu': 'CPU',
	'inventory.addEquipment.modal.ram': 'RAM',
	'inventory.addEquipment.modal.port': 'Port',
	'inventory.addEquipment.modal.portCount': 'Port number',
	'inventory.addEquipment.modal.rackUnit': 'Rack unit',
	'inventory.addEquipment.modal.modemType': 'Modem type',
	'inventory.addEquipment.modal.storage_system': 'Storage',
	'inventory.addEquipment.modal.type': 'Type',
	'inventory.addEquipment.modal.technology': 'Technology',
	'inventory.addEquipment.modal.casing': 'Casing',
	'inventory.addEquipment.modal.gpu': 'GPU',
	'inventory.addEquipment.modal.integrateGpu': 'Integrate GPU',
	'inventory.addEquipment.modal.screen': 'Screen',
	'inventory.addEquipment.modal.usage': 'Usage',
	'inventory.addEquipment.modal.geography': 'Geography',
	'inventory.addEquipment.modal.countryOfUse': 'Country of use',
	'inventory.addEquipment.modal.selectACountry': 'Select a country',
	'inventory.addEquipment.modal.selectAModemType': 'Select a modem type',
	'inventory.addEquipment.modal.usage.hour': 'We consider the duration of use of the VM over a year. By default, if it is turned on and used throughout the year, it is therefore to count: 365 days * 24 hours, which is 8760 hours',
	'inventory.addEquipment.modal.screenType': 'Screen Type',
	'inventory.addEquipment.modal.screenSize': 'Screen Size',
	'inventory.addEquipment.modal.deltaTHour': 'Usage Time',
	'inventory.addEquipment.modal.size': 'Size',
	'inventory.addEquipment.modal.addName': 'Name',
	'inventory.addEquipment.modal.product': 'Product',
	'inventory.addEquipment.modal.supplier': 'Supplier',
	'inventory.addEquipment.modal.model': 'Model',
	'inventory.addEquipment.modal.laptop': 'Laptop',
	'inventory.addEquipment.modal.television': 'Television',
	'inventory.addEquipment.modal.computer_monitor': 'Monitor',
	'inventory.addEquipment.modal.rack_switch-router': 'Rack Switch/Rack Router',
	'inventory.addEquipment.modal.modular_switch-router': 'Modular Switch Router',
	'inventory.addEquipment.modal.server': 'Server (rack, blade, tower)',
	'inventory.addEquipment.modal.rack_server': 'Rack Server',
	'inventory.addEquipment.modal.blade_server': 'Blade Server',
	'inventory.addEquipment.modal.tower_server': 'Tower Server',
	'inventory.addEquipment.modal.virtual_machine': 'Virtual Machine',
	'inventory.addEquipment.modal.desktop': 'Desktop',
	'inventory.addEquipment.modal.smartphone': 'Smartphone',
	'inventory.addEquipment.modal.tablet': 'Tablet',
	'inventory.addEquipment.modal.modem': 'Modem',
	'inventory.addEquipment.modal.mix': 'Mix',
	'inventory.addEquipment.modal.number': 'Number',
	'inventory.addEquipment.modal.cpuName': 'CPU Name',
	'inventory.addEquipment.modal.searchCpuName': 'Search for a CPU name ...',

	'inventory.addEquipment.modal.error.noCpu': 'Please add at least one CPU',
	'inventory.addEquipment.modal.error.noGpu': 'Please add at least one GPU',
	'inventory.addEquipment.modal.error.noStorage': 'Please add at least one storage',
	'inventory.addEquipment.modal.error.noStorageSize': 'Please specify the size in GB of all hard drives',
	'inventory.addEquipment.modal.error.noStorageType': 'Please specify the type of all hard drives',
	'inventory.addEquipment.modal.error.noStorageCasing': 'Please specify the casing size of all hard drives',
	'inventory.addEquipment.modal.error.noRams': 'Please add at least one RAM',
	'inventory.addEquipment.modal.error.noRamSize': 'Please specify the size in GB of all RAMs',
	'inventory.addEquipment.modal.error.noScreen': 'Please, add a screen',
	'inventory.addEquipment.modal.error.noScreenSize': 'Please specify the size of the screen',
	'inventory.addEquipment.modal.error.noScreenType': 'Please specify the type of the screen',
	'inventory.addEquipment.modal.error.noBlock': 'Please select a domain',
	'inventory.addEquipment.modal.error.noServerType': 'Please select a server type',
	'inventory.addEquipment.modal.error.noModemType': 'Please select a modem type',

	'inventory.addEquipment.modal.explore.noEquipmentFound': 'Equipment not found. &break() &link(Contact us) or use a generic or manual configuration for your equipment.',

	'inventory.addEquipment.modal.cmdb.error.onSheet': 'Workbook must have exactly one sheet',
	'inventory.addEquipment.modal.cmdb.error.unValidSheets': 'Some of your tabs cannot be imported: &sheets(sheets)',
	'inventory.addEquipment.modal.cmdb.error.notFound': 'Sheet not found',
	'inventory.addEquipment.modal.cmdb.error.empty': 'Sheet is empty or not readable',
	'inventory.addEquipment.modal.cmdb.error.noData': 'No data extracted, please check your file or the line provided.',
	'inventory.addEquipment.modal.cmdb.error.missing': 'Sheet must have at least followed columns filled: Manufacturer, Model and Type',
	'inventory.addEquipment.modal.cmdb.error.columns': 'Sheet must have at least 14 columns',
	'inventory.addEquipment.modal.cmdb.error.wrongColumns': 'The mandatory column &return() contains empty values, please correct your file.',
	'inventory.addEquipment.modal.cmdb.error.noScreenSize': 'The cmdb of type laptop must have a screen size column',
	'inventory.addEquipment.modal.cmdb.error.noScreenTechno': 'The cmdb of type laptop must have a screen technology column',
	'inventory.addEquipment.modal.cmdb.error.format': 'File must be in .csv or .xlsx format',
	'inventory.addEquipment.modal.cmdb.error.header': 'The sheet must contain a header containing the names of the columns',
	'inventory.addEquipment.modal.cmdb.error.block': 'Please select a domain',
	'inventory.addEquipment.modal.cmdb.error.noStudyIdFound': 'An error occurred while retrieving some data from your study; if this error persists, please contact: support@resilio-solutions.com.',
	'inventory.addEquipment.modal.cmdb.error.query': 'An error occurred while trying to load your file, please contact our support team.',
	'inventory.addEquipment.modal.cmdb.error.name': 'Please enter a name for the CMDB',
	'inventory.addEquipment.modal.cmdb.error.alreadyExists': 'This CMDB already exists',
	'inventory.addEquipment.modal.cmdb.error.noCmdbAdded': 'No CMDB added because no equipments have been found',
	'inventory.addEquipment.modal.cmdb.error.toStudyAndMultipleDataset': 'You have to choose between adding the CMDB to the study or to multiple perimeters',
	'inventory.addEquipment.modal.cmdb.error.sheetIndexOutOfRange': 'Given sheet index is out of range',
	'inventory.addEquipment.modal.cmdb.error.collectClosed': 'The inventory collect of the perimeter is closed',

	'inventory.error.cmdb.modal.delete': 'An error occurred while trying to delete this CMDB',
	'inventory.addEquipment.modal.cmdb.button': 'Load your file (<25MB)',
	'inventory.addEquipment.modal.cmdb.downloadTemplate': 'Download the template for your CMDB file',
	'inventory.addEquipment.modal.cmdb.cannotReplaceEquipments': 'You cannot replace equipments that are used for results',
	'inventory.addEquipment.modal.cmdb.replacement': 'Choose equipments to replace',
	'inventory.addEquipment.modal.cmdb.chooseType': 'Choose the type of CMDB you want to import',
	'inventory.addEquipment.modal.cmdb.chooseStart': 'Indicate the number of the header line of your CMDB',
	'inventory.addEquipment.modal.cmdb.multipleDataset.info': 'Your CMDB must have a column \'perimeter\' containing the name of the perimeter to which the equipment is assigned.',
	'inventory.addEquipment.modal.cmdb.importLoading': 'Importing your CMDB ... &break() This may take a few minutes.  &break()You can continue navigating on the app and will be notified when the import is finished.  &break()Please, do &bold(not close your browser).',
	'inventory.addEquipment.modal.cmdb.canUseApp': 'You can continue to use the application, you will be notified when the import is finished.',
	'inventory.addEquipment.modal.cmdb.importSuccess.title': 'Congratulations !&return() Your CMDB has been successfully imported !',
	'inventory.addEquipment.modal.cmdb.importSuccess.message': 'You can now visualize it in inventory.',
	'inventory.addEquipment.modal.cmdb.importError.title': 'An error occurred while importing your CMDB',
	'inventory.addEquipment.modal.cmdb.importError.message': 'Your data has been partially imported. Some rows in your data file failed to be imported. We are not able to fix this issue automatically. Consequently, Resilio support team has been notified, to understand the problem and find a solution soon. Your consultant or support team will get back to you within 48h. Would you need any immediate assistance, please reach out to &emergency()',

	'inventory.editEquipment.modal.electricityConsumption': 'Enter the electricity consumption here (a default value will be taken if empty).',
	'inventory.editEquipment.modal.input.electricityConsumption': 'Electricity consumption',
	'inventory.editEquipment.modal.allocationFactor': 'Enter an allocation factor assigned to the quantity. &break() Make sure your perimeters are consistent!',
	'inventory.editEquipment.modal.input.allocationFactor': 'Allocation (from 0 to 1)',
	'inventory.editEquipment.modal.quantity': 'Quantity',

	'inventory.cmdb.field.name': 'Name',
	'inventory.cmdb.field.ramCount': 'RAM Count',
	'inventory.cmdb.field.ramSize': 'RAM Size (GB)',
	'inventory.cmdb.field.cpuCount': 'CPU Count',
	'inventory.cmdb.field.cpuModel': 'CPU Model',
	'inventory.cmdb.field.gpuCount': 'GPU Count',
	'inventory.cmdb.field.gpuModel': 'GPU Model',
	'inventory.cmdb.field.ssdCount': 'SSD Count',
	'inventory.cmdb.field.ssdSize': 'SSD Size',
	'inventory.cmdb.field.hddCount': 'HDD Count',
	'inventory.cmdb.field.screenSize': 'Screen Size',
	'inventory.cmdb.field.screenType': 'Screen Type',
	'inventory.cmdb.details': 'details',
	'inventory.cmdb.viewDetails': 'View details of the CMDB',
	'inventory.cmdb.modal.delete': 'Delete this CMDB',
	'inventory.cmdb.modal.infoDelete': 'You can choose to delete all the equipment in the CMDB or only those associated with a category. Select the category or categories whose equipment will be deleted:',
	'inventory.cmdb.modal.warningDelete': 'Do you want to confirm the deletion of the equipment belonging to the selected categories? This action is irreversible.',
	'inventory.cmdb.modal.infoDeleteSingle': 'Do you want to confirm the deletion of the equipment belonging to this CMDB?',
	'inventory.cmdb.modal.lifeTime': 'Lifetime',
	'inventory.cmdb.modal.editLifetime': 'Edit cmdb lifetime',
	'inventory.cmdb.modal.allocationFactor': 'Allocation factor (between 0 and 1)',
	'inventory.cmdb.modal.allocationFactor.tooltip': 'The allocation factor is used to weight the impact of your equipment in the calculation. For example, if you have 10 servers in your data center and you estimate that 50% of these resources are used for your functional unit, you can enter an allocation factor of 0.5. This means that only half of the total impact of your servers will be allocated to your perimeter.',
	'inventory.cmdb.modal.settings.warning': 'Please note: when you click on submit, the changes will be applied to all the devices in this CMDB (the previous modification to a device will be deleted for the parameter). Would you like to continue?',
	'inventory.cmdb.modal.settings.defaultLifetimeWarning': 'These equipments contain a default lifetime. The changes of lifetime will not be applied on these equipments.',
	'inventory.cmdb.modal.settings.error': 'An error occurred while trying to save this modification to the CMDB',

	'inventory.cmdb.notification.partiallyImportedRows': 'The following rows could not be imported:',

	'inventory.cmdb.modal.label.sheetSelector': 'Select the sheet to import',

	'inventory.detailedInformations': 'Inventory detailed informations',
	'inventory.equipmentInfos.modal.title': 'Equipment Informations',

	'inventory.error.data': 'An error occurred with your inventory, try to reload the page or contact your consultant if the problem persists.',
	'inventory.error.resilioDbUnavailable': 'Resilio Database service is not available for the moment, only local database is available.',

	'data.table.equipmentEdit.propagate': 'Propagate to the study',
	'data.table.equipmentEdit.name': 'Name',
	'data.table.equipmentEdit.specification': 'Specification',
	'data.table.equipmentEdit.deleteError': 'It\'s not possible to delete this equipment because it is used in the results',

	'maturity': 'maturity',
	'maturity.title': 'Maturity',
	'maturity.head.name': 'Wording of the good practice',
	'maturity.head.grade': 'Maturity grade',
	'maturity.head.grade.tooltip': 'Self-assessment using the CMMI scale (rating from 1 to 5)',
	'maturity.head.kpi': 'Kpi',
	'maturity.head.kpi.tooltip': 'Key Performance Indicator',
	'maturity.head.priority': 'Priority',
	'maturity.head.priority.tooltip': 'Depends on the environmental impact of a good practice. The greater the environmental "benefit" of the good practice, the higher the priority',
	'maturity.head.difficulty': 'Difficulty',
	'maturity.head.difficulty.tooltip': 'Depends on how easy it is to implement a good practice. The easier the good practice is to implement, the lower the difficulty',
	'maturity.head.follow': 'Follow-up',
	'maturity.head.follow.tooltip': 'Allows you to flag good practices that are still to be completed or that you wish to re-evaluate',

	'maturity.tip.truncated': `&bold(Explanation of the CMMI scale)!

&bold(Initial): The starting point - The project is new or undocumented. It is led by an individual and its success depends on his or her own skills.
&bold(Reproducible): The good practice is beginning to be mastered and has been initiated on part of the perimeter. The methods put in place allow for a general deployment.`,

	'maturity.tip': `&bold(Explanation of the CMMI scale)!

&bold(Initial): The starting point - The project is new or undocumented. It is led by an individual and its success depends on his or her own skills.
&bold(Reproducible): The good practice is beginning to be mastered and has been initiated on part of the perimeter. The methods put in place allow for a general deployment.
&bold(Defined): The project is standardized. Procedures are written and available. All players have a clear understanding of it.
&bold(Mastered): An indicator, KPIs, measures performance from a quantitative and/or qualitative point of view. Variances are analyzed.
&bold(Optimized): The project is part of a continuous improvement process.
&bold(Not applicable): This field can be selected if this good practice doesn't apply to your organization (example : we don't have any data centers). If the best practice is not currently implemented in your organization but could be implemented in the future, please select the first maturity level "Initial".

&flagTip() Click on the flag to follow a good practice. Then, you can filter the good practices flagged by clicking on the "Follow-up" column header.`,

	'maturity.description': 'Description',
	'maturity.priority.priority': 'Priority',
	'maturity.priority.recommended': 'Recommended',
	'maturity.priority.notPriority': 'Not priority',
	'maturity.difficulty.easy': 'Easy',
	'maturity.difficulty.standard': 'Standard',
	'maturity.difficulty.hard': 'Hard',
	'maturity.comment': 'Comment',
	'maturity.kpi': 'KPI',
	'maturity.kpiValue': 'KPI value',
	'maturity.errorOccurs': 'An error occured, your data has not been saved.',
	'maturity.error.data': 'An error occurred with your maturity, try to reload the page or contact your consultant if the problem persists.',
	'maturity.notification.gradeMastered.title': '🎉🎉🎉&return() Congratulations !',
	'maturity.notification.gradeMastered.message': 'You entered a grade that is equal or above 4. You at least master this good practice! To validate the results, please enter a KPI.',

	'results.downloadExcel': 'Download results as an Excel File',
	'results.exportResults': 'Export',
	'results.proceedExport': 'Proceed to export',
	'results.downloadAllGraphs': 'Download all graphs',
	'result.ExportModalTitle': 'Chose what you want to download in Excel',
	'result.ExportModalChoice': 'Choose what you want to download (required)',
	'result.fr': 'French',
	'result.en': 'English',
	'results': 'results',
	'results.info': 'The results presented in this section represent the footprint and/or maturity of the scope &perimeter(), over one year. The results are obtained with a stock approach, and are mid-point / problem oriented LCA results.',
	'results.computeInfo': '(Impacts computed over 1 year)',
	'results.title': 'Results',
	'results.results': 'Results',
	'results.result': 'Result',
	'results.collectionDatasets': 'Perimeters in this collection',
	'results.launcherSelector': 'Collections',
	'results.nothingHere': 'Nothing here yet',
	'results.noBenchmark': 'No benchmark',
	'results.notReady': 'Results not ready. Please finish completing the inventory.',
	'results.archivedResults': 'Be careful: Those results are archived. They probably not correspond to your current inventory.',
	'results.error': 'Error',
	'results.indicator': 'Indicator',
	'results.year': 'Year',
	'results.step': 'Step',
	'results.index': 'Index',
	'results.value': 'Value',
	'results.valuePBCI': 'PBCI',
	'results.min': 'Min',
	'results.mean': 'Mean',
	'results.max': 'Max',
	'results.unit': 'Unit',
	'results.block': 'Domain',
	'results.domain': 'Domain',
	'results.scope': 'Scope',
	'results.grade': 'Grade',
	'results.category': 'Category',
	'results.lifetime': 'Lifetime',
	'results.averageLifetime': 'Average lifetime',
	'results.quantity': 'Quantity',
	'results.averageQuantity': 'Total quantity',
	'results.quantityByUsers': 'Quantity by users',
	'results.averageQuantityByUsers': 'Average quantity by users',
	'results.byUsers': 'by users',
	'results.unit.tons': 'tons',
	'results.carousel.displayPerUser': 'Display result per user',
	'results.carousel.displayTotalFootprint': 'Display result total footprint',
	'results.carousel.lessThanMin': 'This value is lower than the minimum value of the benchmark',
	'results.carousel.moreThanMax': 'This value is higher than the maximum value of the benchmark',
	'results.lifeCycleStep.EOL': 'End-of-life',
	'results.lifeCycleStep.BLD': 'Manufacturing',
	'results.lifeCycleStep.USE': 'Use',
	'results.lifeCycleStep.DIS': 'Distribution',
	'results.electricityConsumption.title': 'Electricity consumption (kWh)',
	'results.lifeCycleStep': 'Life cycle step',
	'results.TotalFootprint': 'Total footprint',
	'results.title.NormalizedFootprintPerLCStep': 'Contribution of life cycle stages to the footprint',
	'results.title.NormalizedFootprintPerIndicator': 'Contribution of indicators to the footprint',
	'results.title.NormalizedFootprintPerBlock': 'Normalized footprint per domains',
	'results.title.NormalizedFootprintDetailed': 'Normalized footprint detailed',
	'results.title.GlobalNormalizedResult': 'Global normalized result',
	'results.title.MaturityComparisonByDomain': 'Maturity comparison by domain',
	'results.title.Maturity': 'Maturity Grade per Domain',
	'results.title.InventoryOverview': 'Inventory overview',
	'results.maturity.score': 'Maturity score',
	'results.maturity.averageScore': 'Average score',
	'results.maturity.initial': 'Initial',
	'results.maturity.reproducible': 'Reproducible',
	'results.maturity.defined': 'Defined',
	'results.maturity.mastered': 'Mastered',
	'results.maturity.optimized': 'Optimized',
	'results.maturity.notApplicable': 'Not applicable',
	'results.title.FootprintPerLCStep': 'Impact per life cycle step',
	'results.title.FootprintPerFunctionalUnit': 'Footprint per user',
	'results.title.FootprintPerBlock': 'Impact per domains',
	'results.title.FootprintDetailed': 'Footprint detailed',
	'results.title.FootprintEquivalence': 'Footprint equivalence',
	'results.title.EnvironmentalBudget': 'Environmental budget',
	'results.title.ElectricityConsumption': 'Electricity consumption',
	'results.normalizedResults.info': 'Normalized Results do not take into account the environmental indicator filter that you could have applied. Normalized Results always correspond to the global calculation weighting all indicators.',
	'results.equipmentVersions': 'Equipment versions',
	'results.lcaParametersVersion': 'LCA parameter versions',
	'results.domain.graphs': 'Graphs',
	'results.domain.tables': 'Datas',
	'results.title.FluxSummary': 'Flux summary',
	'results.fluxSummary.build_impact_raw_materials': 'Build impact raw materials',
	'results.fluxSummary.weee': 'WEE',
	'results.fluxSummary.paper_waste': 'Paper waste',
	'results.fluxSummary.electricity': 'Electricity',
	'results.fluxSummary.number_of_users': 'Number of users',
	'results.fluxSummary.unit.build_impact_raw_materials': 'MIPS',
	'results.fluxSummary.unit.weee': 'tons',
	'results.fluxSummary.unit.paper_waste': 'tons',
	'results.fluxSummary.unit.electricity': 'kWh',
	'results.fluxSummary.unit.number_of_users': ' ',
	'results.title.Objectives': 'Objectives',
	'results.objectives.BusinessAsUsual': 'Business as usual',
	'results.objectives.Ideal': 'Ideal',
	'results.title.LowestQualityData': 'Data lines to improve',
	'results.title.QualityScore': 'Quality score',
	'results.title.QualityOverview': 'Quality overview',
	'results.perFunctionalUnit': 'Per functional unit',
	'results.total': 'Total',
	'results.qualityWeight': 'Quality weight',
	'results.percentage': 'Percentage',
	'results.equipment': 'Equipment',
	'results.equipmentShortNameAndSpec': 'Equipment name & specification',
	'results.version': 'Version',
	'results.dataReference': 'Data reference',
	'results.tooltip.dataReference': 'This ID will allow you to retrace the parameters used to compute the impact of this equipment. You can consult those parameters by clicking on the given link.',
	'results.redirect.id': 'Visualize my equipment on ResilioDb',
	'results.dateVersion': 'Last update',
	'results.dbSource': 'Database',
	'results.qualityScore': 'Quality score',
	'results.qualityScore.toImprove': 'To improve',
	'results.qualityScore.evaluated': 'Number of evaluated lines',
	'results.quality.none': 'Not specified',
	'results.quality.high': 'High',
	'results.quality.medium': 'Medium',
	'results.quality.low': 'Low',
	'results.quality': 'Quality',
	'results.title.MaturityScore': 'Maturity score',
	'results.resultValue': 'Result value',
	'results.title.Impacts': 'Impact grouped by equipment category',
	'results.title.ImpactScore': 'Impact score',
	'results.title.PlanetaryBoundaries': 'Planetary boundaries',
	'results.title.BGES': 'GHG Protocol - Scopes',
	'results.eqEarth': 'eq. Earth',
	'results.setToPrivate': 'Set to private',
	'results.setToPublic': 'Set to public',
	'results.setToDefinitive': 'Set to definitive',
	'results.setToNotDefinitive': 'Set to not definitive',
	'results.deleteAll': 'Delete all',
	'results.setColors': 'Set colors',
	'results.errorNotReady': 'Sorry, it look like your results are not ready for now. Please be patient or contact your consultant if you believe they should.',
	'results.errorHappens': 'Sorry, an error occurred while creating the graph. Please try reloading the page or contact your consultant if the error persists.',
	'results.errorQuery': 'Sorry, something went wrong with these results... Please refresh the page, and contact Resilio Team if the problem persists.',
	'results.nextCarouselPicture': 'Next picture',
	'results.previousCarouselPicture': 'Previous picture',
	'results.switchToGraphOrTable': 'Switch to graph or table',
	'results.compare': 'Compare',
	'results.noComparison': 'No comparison',
	'results.compareWithSample': 'Compare with a benchmark',
	'results.compareWithCustom': 'Compare with a set',
	'results.selectSample': 'Select a benchmark',
	'results.compareResults': 'Compare results',
	'results.removeComparison': 'Remove comparison',
	'results.search': 'Search',
	'results.chooseASample': 'Choose a benchmark',
	'results.chooseASampleDesc': 'To get comparison results, you need to choose a benchmark by clicking on a balance icon on top of the page for all results or on each result card.',
	'results.comparison_impossible': 'Comparison impossible',
	'result.comparedTo': 'Compared to benchmark: &compare(nom)',
	'results.warning': 'We couldn\'t compare this result with the benchmark &compare(name)',
	'results.equivalence.indicator.title': 'Equivalence &indicator()',
	'results.noIndicator': 'No indicator',
	'results.noIndicatorSelected': 'No indicator selected, to obtain this result, please select an indicator in the filter.',
	'results.allDomains': 'All domains',
	'results.noCollectionFound': 'There is no collection of results for this set of perimeter(s): &perimeters(). &break() Please, check that you have calculated the results for this set of perimeters.',

	'results.alert.equivalence': 'equivalence',
	'results.alert.quality': 'quality',
	'results.alert.maturity': 'maturity',

	'results.setDefinitive': 'Definitve results',
	'results.notification.setDefinitive': '⚠️ These results will become definitive and you will no longer be able to change them. This will also apply to normalized and maturity results. &return() &return() &red(You can also delete all results collections previously computed.) By confirming you would only have access this definitive result. &return() You can also delete certain results by navigating though your results collections and deleting them individually. This would avoid storing unused data!',
	'results.notification.setToNotDefinitive': 'Are you sure you no longer wish to define this set of results as definitive?',
	'results.notification.deleteResult': 'You will delete this version from the results. This will apply to standardized and maturity results. &return() Would you like to continue?',
	'results.notification.loadDeleteResults': 'This may take a few minutes. Please, do not close this page.',

	'results.error.needComparison': 'This result needs a comparison to be displayed.',
	'results.error.cantDeleteDefinitive': 'You can\'t delete a definitive result. Please set it to not definitive first.',
	'error.manage.cantDeleteDatasetWithSample': 'You can\'t delete a perimeter that is used as a benchmark.',
	'error.manage.studyNotFound': 'Study does not exist',
	'error.manage.datasetAlreadyExist': 'This perimeter name already exists in the study',
	'error.manage.cannotChangeCompany': 'You can\'t change the company of a study',

	'results.error.exportFailed': 'An error occurred while exporting the results.',

	'result.all': 'All Results',
	'result.lcaresults': 'LCA Results',
	'result.normalizedresults': 'Normalized Results',
	'result.corporateReporting': 'Corporate Reporting',
	'result.projections': 'Projections',
	'result.data': 'Data',
	'result.flux': 'Flux',
	'result.maturity': 'Maturity',
	'result.maturity.score': 'Score',

	'result.scopes': 'Scopes',
	'result.bgesUnitId': 'BEGES Unit',
	'result.ademeCarbonFootprint': 'BEGES Category',
	'result.ghgProtocolCategory': 'GHG Protocol Category',
	'result.ghg': 'GWP (kg eq. CO2) - per category',
	'result.bges.total': 'GWP (kg CO2 eq.) - per scope',

	'filterIndicatorModal.title': 'Filter indicators',
	'filterIndicatorModal.filter': 'Select the indicators you want to display',

	'groupby.title': 'Impacts Group by Equipement',
	'groupby.error': 'An error occurred with your group by, try to reload the page or contact your consultant if the problem persists.',
	'groupby.normalized': 'Normalized',
	'groupby.equipement.name': 'Equipment name',
	'groupby.equipement.unit': 'Unit',
	'groupby.equipement.category': 'Equipment category',
	'groupby.equipement.block': 'Equipment domain',
	'groupby.select.companies': 'Select organizations :',
	'groupby.select.studies': 'Select studies :',
	'groupby.select.datasets': 'Select perimeters :',
	'groupby.request.log': 'Get the log',
	'groupby.grouped.category': 'Grouped by category',
	'groupby.grouped.equipment': 'Grouped by equipment',
	'groupby.grouped.allDomain': 'All domains',
	'groupby.tooltip.lifeCycleStep': 'Relative result regarding the life cycle steps',
	'groupby.tooltip.equipment': 'Relative result regarding the type or category of equipment',
	'groupby.tipSelectedDatasets': 'Selected perimeters',
	'groupby.equipment.name': 'Name & Specification',
	'groupby.equipment.category': 'Category',
	'groupby.equipment.block': 'Domain',
	'groupby.equipment.unit': 'Unit',
	'groupby.equipment.total': 'Total',
	'groupby.allDomains': 'All domains',

	'info.block.preSample': 'Those results are compared to benchmark(s) : ',
	'info.block.validationPreSample': 'Those values are compared to the selected benchmark : ',
	'info.block.validationSampleDown': 'if average - 2 * σ ≤ your value < benchmark average ⇨ ',
	'info.block.validationSampleUp': 'if benchmark average ≤ your value < average + 2 * σ ⇨ ',
	'info.block.validationSampleDoubleDown': 'if your value < benchmark average - 2 * σ ⇨ ',
	'info.block.validationSampleDoubleUp': 'if benchmark average + 2 * σ ≤ your value ⇨ ',
	'info.block.noSample': 'Those results are not compared to any benchmark',
	'info.block.indicatorMax': 'Result ≥ maximum value of the benchmark',
	'info.block.indicatorUp': 'Result > average value of the benchmark',
	'info.block.indicatorDown': 'Result < average value of the benchmark',
	'info.block.indicatorMin': 'Result ≤ minimum value of the benchmark',

	'validation.sigma.black': 'Value amongst the 0,2% most extremes values of the sample',
	'validation.sigma.green': 'Value amongst the 68,2% values of the benchmark',
	'validation.sigma.orange': 'Value amongst the 27,2% most extremes values of the benchmark',
	'validation.sigma.red': 'Value amongst the 4,4% most extremes values of the benchmark',
	'validation.title': 'Validation',
	'validation.nothingHere': 'Nothing here yet',
	'validation.element': 'Element',
	'validation.unit': 'Unit',
	'validation.valid': 'Valid',
	'validation.quantity': 'Quantity',
	'validation.stayOnValidation': 'Stay in validation',
	'validation.goToResults': 'Go to results',
	'validation.validationQuantity': 'Validation - Quantity',
	'validation.validationLifetime': 'Validation - Lifetime (years)',
	'validation.quality': 'Quality',
	'validation.qualityTooltip': 'Quality',
	'validation.sampleQuantity': 'Benchmark - Quantity',
	'validation.sampleLifetime': 'Benchmark - Lifetime (years)',
	'validation.lifetimeTotal': ' Total Lifetime (years)',
	'validation.internalLifetime': 'Internal lifetime (years)',
	'validation.reuseFraction': 'Reuse (%)',
	'validation.reuseLifetime': 'Reuse (years)',
	'validation.noQuantity': 'No quantity',
	'validation.noLifetime': 'No lifetime',
	'validation.noQuantityAndLifetime': 'No quantity and no lifetime',
	'validation.info.element': 'Element',
	'validation.info.unit': 'Unit',
	'validation.info.valid': 'Valid',
	'validation.info.quantity': 'Quantity',
	'validation.info.validationQuantity': 'Validation - Quantity',
	'validation.info.validationLifetime': 'Validation - Lifetime (years)',
	'validation.info.sample': 'Benchmark',
	'validation.info.lifetimeTotal': 'Total Lifetime (years)',
	'validation.info.internalLifetime': 'Internal lifetime (years)',
	'validation.info.reuseFraction': 'Reuse (%)',
	'validation.info.reuseLifetime': 'Reuse (years)',
	'validation.info.sampleQuantity': 'Benchmark - Quantity - Sigma - (# perimeters included) - Quality score',
	'validation.info.sampleLifetime': 'Benchmark - Lifetime (years)',
	'validation.unlockCollect': 'Unlock collect',
	'validation.lockCollect': 'Lock collect',
	'validation.calculate': 'Calculate',
	'validation.calculationRequest': 'Calculation request',
	'validation.calculationRequest.text': 'By clicking on "Validate", our consultants will be notified and will launch the calculation as soon as possible. You will be able to see the results in the "Results" page.',
	'validation.calculationRequest.message': 'You can add a message to your calculation request.',
	'validation.calculationRequest.success': 'Calculation request sent',
	'validation.calculationRequest.error': 'An error occurred while sending the calculation request, please try again later.',
	'validation.calculateAndReset': 'Archive results and calculate',
	'validation.calculationImpactsInconsistent': 'Calculation impacts inconsistent',
	'validation.calculationResetWarning': 'This will archive the current results and impacts and calculate the new ones. Are you sure you want to continue?',
	'validation.archive': 'Archive',
	'validation.archiveSuccess': 'Benchmark archived',
	'validation.filterUnvalidated': 'Hide validated equipments',
	'validation.unFilterUnvalidated': 'Show all equipments',
	'validation.calculationNotPossible': 'Calculation not possible',
	'validation.calculationExplanation': ' Block the collect and complete the validation to enable the calculation.',
	'validation.errorOccurs': 'An error occurred',
	'validation.calculationCheck': 'Some safety checks before lauching computation',
	'validation.calculationLaunched': 'Calculation launched',
	'validation.calculationSuccess': 'Calculation successfully finished',
	'validation.calculationErrorOnSample': 'Calculation error on benchmark. Please contact your consultant or your administrator.',
	'validation.calculationAlert': 'Your perimeter doesn\'t contain the information necessary to compute your &styledText(results for) &resultTypes(resultTypes). Related results have therefore been &styledText(unselected) automatically.',
	
	'validation.ok': 'Ok',
	'validation.selectResultList': 'Select result list',
	'validation.selectResultTypes': 'Select result types',
	'validation.selectStudy': 'Compute on entire study',
	'validation.selectStudyDatasets': 'For study, compute all perimeters selected',
	'validation.selectSample': 'Select benchmark',
	'validation.removeSample': 'Remove benchmark', 
	'validation.missingTypes': 'Missing results for benchmark',
	'validation.missingType.explanation': 'The benchmark is missing some results. Results will be computed without being compared to the benchmark.',
	'validation.missingType.explanationContact': 'To obtain the results with benchmark comparison, please contact your Consultant.',
	'validation.result.mandatory': 'Result that need a comparison and won\'t be computed',
	'validation.resultType.used': 'Result used in comparisons',
	'validation.resultType.useless': 'Result that cannot be compared',
	'validation.missingTypes.info1': 'All results needing a comparison will be ignored.',
	'validation.missingTypes.info2': 'All results that use comparison will be computed without comparison.',
	'validation.missingTypes.process': 'Process anyway ?',
	'validation.defaultLifetime': 'Default lifetime',
	'validation.archiveResults': 'Archive results',
	'validation.inventoryExtraEdit': 'Edit LCA parameters',
	'validation.inventoryExtraView': 'View LCA parameters',
	'validation.inventoryExtraEditSuccess': 'LCA parameters edited',
	'validation.inventoryExtraEdit.error': 'An error occurred during the edition of your LCA parameters',
	'validation.inventoryExtraEdit.referenceFluxError': 'Your reference flux should be above 0',
	'validation.inventoryExtraEdit.referenceFlux': 'Dimensioning value',
	'validation.inventoryExtraEdit.referenceFlux.desc': 'The dimensioning value refers to the quantity of products analyzed and/or consumables used, required to fulfill the functional unit. For an LCA of information systems, the dimensioning value corresponds to the number of users of the IS (generally the number of employees + service providers). For a classic LCA of digital services, such as "Using a videoconferencing service for 1 hour", it is generally 1, since the functional unit is already standardized, in our example at 1 hour. However, for a UF of "using a videoconferencing service for one day", the dimensioning value could be "2h" if we consider using videoconferencing for 2 hours a day.',
	'validation.inventoryExtraEdit.energyMixes': 'Energy mixes',
	'validation.inventoryExtraEdit.indicatorsAndLCS': 'Indicators and LCS',
	'validation.inventoryExtraEdit.factorSet': 'Factor versions',
	'validation.inventoryExtraEdit.indicatorAllSelected': 'By default, all indicators and life cycle steps are selected and used for the calculation, you can click on the lock to make a new selection.',
	'validation.inventoryExtraEdit.indicatorSelectionCaution': 'Attention: If not all indicators and life cycle step are selected, the comparison of normalized results with benchmarks might not be possible.',
	'validation.indicators': 'Indicators',
	'validation.lifeCycleSteps': 'Life cycle steps',
	'validation.energyMix': 'Electricity mixes',
	'validation.energyMix.privateEnergyMix': 'The energy mixes: &energyMix(energyMix) has been entered. These energy mix are private to your company. Please contact our support team to modify it.',
	'validation.energyMix.specificVersionSelected': 'Be careful, at the moment specific versions of electricity mixes are selected manually. By activating this option, the last version of electricity mixes will automatically be used in your calculation',
	'validation.energyMix.lastVersion.alwaysSelected': 'For now, the last available versions of electricity mixes are used in your calculation. This option will be disactivated automatically if you later select a specific version for a domain.',
	'validation.energyMix.lastVersion.autoSelectLast': 'Automatically update electricity mixes to the latest available version',
	'validation.referenceFlux': 'Reference flux',
	'validation.factorSet': 'Factor versions',
	'validation.factorSet.equipmentType': 'Equipment type',
	'validation.factorSet.factorSet': 'Factor version',
	'validation.factorSet.lastVersion': 'Last version',
	'validation.factorSet.lastVersion.option': 'The last version of this equipment will be used for the calculation, currently the last version is version &version(version)',
	'validation.factorSet.specificVersionSelected': 'Be careful, at the moment specific versions of impact factors are selected manually. By activating this option, the last version of impact factors of each inventory item will automatically be used in your calculation.',
	'validation.factorSet.lastVersion.alwaysSelected': 'For now, the last available version of impact factors of each inventory item is used in your calculation. This option will be disactivated automatically if you later select a specific version for an inventory item.',
	'validation.factorSet.lastVersion.autoSelectLast': 'Automatically update impact factors to the latest available version',
	'validation.filterEnergyMix': 'Filter by mix',
	'validation.sniffingAuto': 'Auto-detect available indicators and life cycle steps',
	'validation.archiveResultsDescription': 'This will archive the results of the current perimeter. You will not be able to see them anymore. Do you want to continue ?',
	'validation.archiveResultsDone': 'Results archived',
	'validation.archiveResultsError': 'Error while archiving results',
	'validation.archiveResultsNoInfo': 'No additional information',
	'validation.unlockValidation': 'Unlock validation',
	'validation.lockValidation': 'Lock validation',
	'validation.cancelButtonModalValidation': 'Cancel',
	'validation.goToRefFlux': 'Modify dimensioning value',
	'validation.sample': 'Benchmark',
	'validation.compare': 'Compare',
	'validation.unlockButtonModalValidation': 'Unlock',
	'validation.warningUnlockValidation': 'Are you sure you want to unlock the validation of data?\nIf a modification is made on the validated quantity or total lifetime,\npreviously computed results will be automatically archived.',
	'validation.unlockTitle': 'Unlock validation',
	'validation.average.sigma': 'Deviation from the mean = ',
	'validation.noPermToLockValidation': 'You do not have the permission to lock/unlock validation',
	'validation.warning': 'Warning',
	'validation.warningNoReferenceFlux': 'Do you confirm that the dimensioning value associated to the perimeter is 1 ? &break() If not, you can modify this value in "Edit LCA Parameters" > "Dimensioning value"',
	'validation.energyMix.block': 'Domain',
	'validation.energyMix.energyMix': 'Energy mix',
	'validation.energyMix.select': 'Select an energy mix',
	'validation.energyMix.selectFactorSet': 'Select an energy mix factor version',
	'validation.energyMix.lastFactorSet': 'Last energy mix factor version',
	'validation.error.data': 'An error occurred with your data, try to reload the page or contact your consultant if the problem persists.',
	'validation.empty': 'There is nothing to show here!',
	'validation.addSampleToCompare': 'Add a benchmark to compare',
	'validation.usedInComparison': 'This result will be compared to a benchmark if one has been selected',
	'validation.exportInventory': 'Export inventory',
	'validation.notification.definitiveResult.collect': 'You can no longer collect your inventory because your perimeter contains a definitive version of the result. You can set this result as non-definitive from Result page. Would you like to be redirected on?',
	'validation.notification.definitiveResult.validation': 'You can no longer validate your inventory because your perimeter contains a definitive version of the result. You can set this result as non-definitive from Result page. Would you like to be redirected on?',
	'validation.notification.definitiveResult.compute': 'You can no longer carry out the calculation because your perimeter contains a definitive version of the result. You can set this result as non-definitive from Result page. Would you like to be redirected on?',

	'checkError.datasetNotReady': 'Compute impossible for &dataset(perimeter)',
	'checkError.dataset.noEquipments': '"&dataset(perimeter)" has no equipments',
	'checkError.dataset.equipment.quantity': 'Validated quantity is None for equipment &equipment(equipment)',
	'checkError.dataset.equipment.noValidatedLifetime': 'Validated lifetime is None or zero while quantity > 0 for equipment &equipment(equipmentName)',
	'checkError.dataset.equipment.validationLifetimeNotPositive': 'Validated lifetime is not strictly positive for equipment &equipment(equipmentName)',
	'checkError.dataset.noInventoryExtra': 'No inventory extra in the perimeter: &dataset(perimeter)',
	'checkError.dataset.noBlocks': 'No blocks in the perimeter: &dataset(perimeter)',
	'checkError.dataset.noEnergyMixes': 'No energy mixes in the perimeter: &dataset(perimeter)',
	'checkError.dataset.noReferenceFlux': 'No reference flux in the perimeter: &dataset(perimeter)',
	'checkError.dataset.referenceFluxNotPositive': 'Reference flux for &dataset(perimeter) is not positive',
	'checkError.dataset.noLifeCycleSteps': 'No life cycle steps in the perimeter: &dataset(perimeter)',
	'checkError.dataset.noIndicators': 'No indicators in the perimeter: &dataset(perimeter)',
	'checkError.dataset.noUseLifeCycleStep': 'No use life cycle step in the perimeter: &dataset(perimeter)',
	'checkError.impactFactor.null': 'Impact factor v&version(version) for &equipment(equipment) with &indicator(indicator) and &life_cycle_step(life_cycle_step) is null',
	'checkError.impactFactor.notFound': 'Impact factor v&version(version) for &equipment(equipment) with &indicator(indicator) and &life_cycle_step(life_cycle_step) not found',
	'checkError.impactFactor.noFactorSet': 'Equipment &equipment(equipment) has no factor set',
	'checkError.impactFactorElecUse.null': 'Impact factor v&version(version) for electricity use for &equipment(equipment) is null',
	'checkError.impactFactorElecUse.notExist': 'Impact factor v&version(version) for electricity use for &equipment(equipment) not found',
	'checkError.impactFactorEnergyMix.null': 'Impact factor for energy mix &energyMix(energyMix) with &indicator(indicator) is null',
	'checkError.impactFactorEnergyMix.notExist': 'Impact factor for energy mix &energyMix(energyMix) with &indicator(indicator) not found',
	'checkError.energyMix.noFactorSet': 'Energy mix &energyMix(energyMix) has no factor set',
	'checkError.sampleMissingResults': 'Some results from benchmark &sample(sampleName) are missing.',
	'checkError.sampleMissingResultsContactAdmin': 'Some Benchmark results are missing. Contact your consultant or administrator to fix this.',
	'checkError.block.noTranslation': 'A block has no translation, contact your administrator',
	'checkError.equipment.noTranslation': 'Equipment #&equipment(equipmentID) has no translation, contact your administrator',
	'checkError.dataset.noMaturity': 'Perimeter &dataset(perimeter) has no maturity',
	'checkError.dataset.noMaturityGrade': 'Maturity is not completely filled in perimeter &dataset(perimeter)',
	'checkError.indicatorAndLifeCycleStepNotConsistent': 'Your indicators or life cycle steps are not consistent between your perimeters',
	'checkError.factorSetNotConsistent': 'Your factor sets are not consistent between your perimeters',

	'codePage.card.assignedAccess': 'Assigned access',
	'codePage.card.button.sendMeCode': 'Send me a code',
	'codePage.card.codeInformation': 'Code is sent to your email. It\'s a one time use code and will expire after 15 minutes.',
	'codePage.card.code.label': 'Code',
	'codePage.card.code.input.placeholder': 'Type your code',
	'codePage.card.button.submit': 'Submit',
	'codePage.card.codeSent': 'Code sent',
	'codePage.card.codeNotSent': 'Code not sent, an error occurred, please try again later.',
	'codePage.card.error.noToken': 'No token provided, try to refresh the page',
	'codePage.card.codeVerified': 'Code verified',
	'codePage.card.codeNotVerified': 'Invalid code. Please try again. Note that attempt connection are limited.',

	'errorImage.header': 'Something went wrong',
	'lambdaAssignedPage.forbidden.title': 'Forbidden',
	'lambdaAssignedPage.forbidden.description': 'You are not allowed to access this page',

	'forbidden.title': 'Forbidden',
	'forbidden.description': 'You are not allowed to access this page',

	'export.errorNoButtonClicked': 'Please select a button before validate',
	'export.notReady': 'This feature is almost ready. Our developper team is baking it... Please be patient!',

	'export.results': 'Results',
	'export.dataCollected': 'Data Collected',

	'export.results.rawResults': 'Results (excel)',
	'export.results.graphicalResults': 'Graphical Results (png)',
	'export.results.executiveSummary': 'Executive Summary (pdf)',
	'export.results.certificate': 'Certificate',
	'export.results.exportImpacts': 'Impacts (excel)',
	'export.errorExport': 'An error occurred during the export. Try to reload the page or contact your consultant if the problem persists.',
	'export.rawResultsTooltip': 'Excel File with your result data',
	'export.graphicalResultsTooltip': 'Collection of Graphs',
	'export.executiveSummaryTooltip': 'LaTeX export',
	'export.certificateTooltip': '2 pages PDF certificate',
	'export.exportImpactsTooltip': 'Excel File with your impacts data',
	'export.results.exportEnvironmentalAssessment': 'Environmental Assessment (pdf)',
	'export.exportEnvironmentalAssessmentTooltip': 'PDF File with your Environmental Assessment data',

	'export.dataCollected.inventory': 'Inventory (excel)',
	'export.dataCollected.maturity': 'Maturity (excel)',
	'export.dataCollected.settings': 'Settings (excel)',
	'export.results.warnDefinitiveResult': 'Your are about to export your results. If this export is given to a client we recommend you to set this version of results as definitive. This state will prevent you from modifying the inventory, compute new results or delete those current results. You can always deactivate this state from the result page. Do you want to set this version of results as definitive?',

	'export.inventoryTooltip': 'Excel File with your Maturity data',
	'export.maturityTooltip': 'Excel File with your Inventory data',
	'export.settingsTooltip': 'Excel File with your Settings data',
	'export.dataCollected.warning': 'Warning: The export of the inventory, maturity or settings corresponds to the last version of the completed data.',

	'message.contactConsultant': 'We informed Resilio team. Feel free to contact your consultant.',

	'dashboard.title': 'Dashboard',
	'dashboard.notImplemented': 'This feature is coming soon !',
	'dashboard.overview.title': 'Overview',
	'dashboard.comparison.title': 'Comparison',
	'dashboard.simulation.title': 'Simulation',
	'dashboard.evolution.title': 'Evolution',
	'dashboard.create.table.button.title': 'Create a table',
	'dashboard.create.table.button.desc': 'Your personalized table to follow your results',
	'dashboard.acv.button.title': 'ACV',
	'dashboard.acv.button.desc': 'All your results from your impact study',
	'dashboard.evolution.button.title': 'Evolution',
	'dashboard.evolution.button.desc': 'Follow the evolution of your results',
	'dashboard.comparison.button.title': 'Comparison',
	'dashboard.comparison.button.desc': 'Compare your impact with other perimeters',
	'dashboard.simulation.button.title': 'Simulation',
	'dashboard.simulation.button.desc': 'Simulate impact gains from you actions plan',
	'dashboard.savedOn': 'Saved on &date(date)',
	'dashboard.maturity.notCorrectlyFilled': 'The maturity inventory is not complete. The maturity result will not be displayed.',

	'dashboard.results.title': 'Existing results',
	'dashboard.feature.to.be.implemented': 'This feature is not yet implemented !',

	'dashboard.modal.acv.choose.a.company': 'Choose a organization',
	'dashboard.modal.acv.choose.a.study': 'Choose a study',
	'dashboard.modal.acv.choose.a.dataset': 'Choose a Perimeter',

	'dashboard.modal.choose.a.study.and.a.dataset': 'Choose a study and a Perimeter',
	'dashboard.modal.searchbar.navigation.info': 'Search',
	'dashboard.modal.choose.functionalUnit': 'Select a functional unit',
	'dashboard.evolution.alert.benchmark.not.usable': 'This benchmark is not usable for this evolution dashboard',
	'dashboard.evolution.benchmark.not.usable': 'You have choosed a benchmark that is not usable for this evolution dashboard while years in the benchmark are not the same as the years of perimeters selected.',
	'dashboard.evolution.modal.dataset': 'Perimeter',
	'dashboard.evolution.modal.study': 'Study',
	'dashboard.evolution.modal.company': 'Organization',
	'dashboard.evolution.modal.years': 'Years',
	'dashboard.evolution.modal.use': 'We use the name of the studies and perimeters to compute the evolution of your perimeters and studies in time.',
	'dashboard.evolution.modal.datasets.multiple': 'Following your selection, the graph will display :',
	'dashboard.evolution.modal.datasets.unique': 'Following your selection we don\'t have detected at least one stream of data that can be compared in time. Please make sure that the name of the study and perimeters are the same for us to detect automatic evolution of your perimeter. Here are the perimeters selected :',
	'dashboard.evolution.modal.datasets.unique.support': 'If you don\'t know how to group your perimeters, contact your consultant or our support team at &support(support@resilio.tech).',
	'dashboard.evolution.modal.incorrect': 'If the grouping is incorrect, please verify that the names of the studies and perimeters match one other or contact our support team at &support(support@resilio-solution.com).',

	'dashboard.title.globalComparison': 'Global Comparison',
	'dashboard.title.globalSimulation': 'Global gain simulation',
	'dashboard.title.domainsComparison': 'Domains Comparison',
	'dashboard.title.domainsSimulation': 'Domains gain simulation',
	'dashboard.title.maturityDomainsComparison': 'Maturity Domains Comparison',
	'dashboard.title.maturityDomainsEvolution': 'Maturity Domains Evolution',
	'dashboard.title.indicatorsComparison': 'Indicators Comparison',
	'dashboard.title.indicatorsSimulation': 'Indicators Simulation',
	'dashboard.title.equipmentsComparison': 'Equipments Comparison',
	'dashboard.title.equipmentsEvolution': 'Equipments Evolution',
	'dashboard.title.equipmentsSimulation': 'Equipments Simulation',
	'dashboard.title.perimetersEvolution': 'Perimeters Evolution',
	'dashboard.title.domainsEvolution': 'Perimeter Domains Evolution',

	'dashboard.select.globalFootprint': 'Global Footprint',
	'dashboard.select.footprintPerUser': 'Footprint per user',
	'dashboard.select.indicator.normalized': 'Normalized',
	'dashboard.select.allIndicators': 'All indicators',
	'dashboard.select.allIndicatorsGrouped': 'All normalized indicators - PBCI',
	'dashboard.select.allDomains': 'All domains',
	'dashboard.select.allDomainsAverage': 'Average of all domains',
	'dashboard.select.allScopes': 'All scopes',
	'dashboard.select.allDatasets': 'All perimeters',
	'dashboard.select.allLifeCycleSteps': 'All life cycle steps',
	'dashboard.maturity.grade': 'Maturity grade',
	'dashboard.select.cumulativeNone': 'No cumulative',
	'dashboard.select.cumulativeToBefore': 'Cumulative to last scenarios',
	'dashboard.select.cumulativeToBefore.warning': 'Your simulations can be cumulated if there are no correlation between the different scenario, or in other terms if you did not modify the same equipment lines in the different scenarios. If this is the case, you can add up the gains or losses associated to your different scenarios, and thus deduce the total gains or losses after implementing all your simulations.',
	'dashboard.select.cumulativeToBefore.description': 'By activating the "cumulative simulation" option, your simulations will be added one to another. Please select this option only if there are no correlation between your different simulations.',
	'dashboard.select.cumulativeToBefore.table.description': 'The percentages displayed correspond to the cumulative gain or loss on each of the scenarios. The total impact is calculated with respect to the reference.',

	'dashboard.action.benchmark.add': 'Add a benchmark',
	'dashboard.action.benchmark.remove': 'Remove benchmark',
	'dashboard.action.objective.add': 'Add an objective',
	'dashboard.action.objective.remove': 'Remove objective',
	'dashboard.action.graph': 'Graph',
	'dashboard.action.table': 'Table',
	'dashboard.action.histogram.show': 'Show histogram',
	'dashboard.action.histogram.hide': 'Hide histogram',
	'dashboard.action.pin': 'Pin',

	'dashboard.objective': 'Objective',
	'dashboard.modal.objective': 'Objective',
	'dashboard.modal.objective.header': 'Add an objective',
	'dashboard.objective.percent': 'Percent',
	'dashboard.objective.year': 'Year',

	'dashboard.modal.benchmark.header': 'Add a benchmark',
	'dashboard.benchmark': 'Benchmark',
	'dashboard.benchmark.select': 'Select a benchmark',
	'dashboard.benchmark.calculate': 'Please wait while benchmark data are being computed',

	'dashboard.select.noDataset': 'No reference selected',
	'dashboard.select.for.ref': 'for ref.',
	'dashboard.select.category': 'Per Category of Device',
	'dashboard.select.equipmentType': 'Per Equipment',
	'dashboard.select.quantity': 'Quantity',
	'dashboard.select.lifetime': 'Lifetime',
	'dashboard.select.noRelative': 'Global',
	'dashboard.select.userRelative': 'Per user',
	'dashboard.select.custom.indicators': 'Select indicators',

	'dashboard.alert.usedIndicatorsAndLifeCycleSteps': 'Only environmental indicators and life cycle steps that are common to all the selected perimeters will be used for comparison calculations',
	'dashboard.alert.functionalUnit': 'Only studies with the same functional unit will be used for comparison calculations',
	'dashboard.comparison.saveButton': 'Save this view of results',
	'dashboard.comparison.deleteButton': 'Delete this view of results',
	'dashboard.comparison.deleteModal': 'Delete this view of results',
	'dashboard.comparison.benchmark': 'Compare to benchmark',
	'dashboard.comparison.new': 'New comparison',
	'dashboard.simulation.new': 'New simulation',
	'dashboard.evolution.new': 'New evolution',
	'dashboard.globalSimulation.objective': 'Objective',
	'dashboard.modal.dataset.tooltip.noAllImpacts': 'This perimeter does not contain all impacts for comparison',
	'dashboard.modal.dataset.tooltip.noResults': 'This perimeter does not contain any results for comparison',
	'dashboard.equipment.select.a.reference': 'Select a reference',
	'dashboard.noDataset': 'There are no perimeters selected.',
	'dashboard.indicators': 'Indicators',
	'dashboard.lifeCycleSteps': 'Life cycle steps',
	'dashboard.modal.benchmark.wait': 'Please wait while benchmark data are being computed.',
	'dashboard.equipments.alert': 'Data marked with \'NA\' didn\'t appear in the inventory of the perimeter. Data marked with a 0 indicate a quantity of 0 entered in the inventory',
	'dashboard.equipments.alert.category': 'The devices are displayed per category. The categories are the same than then the headers summarizing the quantity and lifetime of your devices in the inventory.',

	'dashboard.tableCreationModal.header': 'Create a table',

	'dashboard.myDashboardList.title': 'My dashboards',
	'dashboard.sharedDashboardList.title': 'Shared dashboard with me',
	'dashboard.overview.table.edit': 'Edit',
	'dashboard.overview.table.share': 'Share with a collaborator',
	'dashboard.overview.table.add': 'Add',
	'dashboard.overview.table.addCard.text': 'Add a text card',
	'dashboard.overview.table.addCard.text.desc': 'Add a text card to your dashboard',

	'dashboard.overview.table.editModal.header': 'Edit a table',
	'dashboard.overview.table.deleteModal.header': 'Delete a table',
	'dashboard.overview.table.card.deleteModal.header': 'Delete a card',
	'dashboard.overview.table.card.text.markdownExplanation': 'You can modify the text using markdown syntax. For more information, please refer to the markdown documentation.',

	'dashboardPinModal.header': 'Pin a table',
	'dashboardPinModal.name': 'Card name',
	'dashboardPinModal.success': 'Card pinned',
	'dashboardPinModal.success.deleted': 'Card unpinned',
	'dashboardPinModal.newTable': 'New table',

	'dashboard.card.move.up': 'Up',
	'dashboard.card.move.down': 'Down',

	'dashboard.card.modify': 'Modify a card',
	'dashboard.card.selected.dataset': 'Selected perimeter',
	'dashboard.card.selected.domain': 'Selected domain',
	'dashboard.card.selected.scope': 'Selected scope',
	'dashboard.card.selected.cumulative': 'Cumulative',
	'dashboard.card.selected.footprintType': 'Selected footprint type',
	'dashboard.card.selected.indicator': 'Selected indicator',
	'dashboard.card.selected.lifeCycleStep': 'Selected life cycle step',
	'dashboard.card.selected.equipment.valueType': 'Selected value type',
	'dashboard.card.selected.equipment.showAs': 'Show',
	'dashboard.card.selected.equipment.relativity': 'Relativity',

	'dashboard.name.of.view': 'Name of view',
	'dashboard.dashboard.saved.title': 'Dashboards saved',
	'dashboard.comparison': 'Comparison',
	'dashboard.evolution': 'Evolution',
	'dashboard.simulation': 'Simulation',

	'dashboard.modal.back': 'Retour',
	'dashboard.modal.choose.a.reference.dataset': 'Choose a reference perimeter',
	'dashboard.modal.reference.dataset': 'Reference perimeter',
	'dashboard.modal.simulation.add.a.perimeter': 'Add a or multiple perimeters as simulation',

	'dashboard.tooltip.saved': 'Those results correspond to your inventory at the following date. Any modifications done to the selected inventories since this date have done been taken into account. Please save a new dashboard if you want the updated results.',

	'modal.selector.defaultLabel': 'Select an option',

	'modal.suggestion.success': 'Thank you! Your request has been sent to our support teams. We will process it as soon as possible!',
	'modal.suggestion.error': 'An error occurred while sending your suggestion.',
	'modal.equipmentReferenceSuggestion.title': 'Request to add a product reference',
	'modal.equipmentReferenceSuggestion.selector.defaultLabel': 'Select an equipment category',
	'modal.equipmentReferenceSuggestion.input.label': 'Enter the product reference you wish to add',
	'modal.domainSuggestion.title': 'Suggest a new domain',
	'modal.domainSuggestion.content': 'Fill in the model you would like to add. Note that this model will be public and will therefore be visible to Resilio Tech users.',
	'modal.domainSuggestion.addDomain': 'Or suggest a new domain',

	/************************************************************************************************
	 *                                       API ERRORS KEYS                                        *
	 * ******************************************************************************************** */
	'api.error': 'Internal Server Error: &error()',
	'api.error.uncaught': 'Internal Server Error. Our team has been informed. If you need immediate assistance, contact &emergency()',
	'api.error.wrongFormat': 'Internal Server Error. Our team has been informed. Please try again later.',
	'api.error.authentication': 'You need to be logged to perform this action',
	'api.error.failedToFetch': 'Failed to fetch data',
	'api.error.permission.denied': 'You are not allowed to perform this action',
	'api.error.access.denied': 'You are not allowed to access this resource',
	'api.error.rateLimit': 'Too many requests. Please wait &timeToWait() seconds before trying again',
	'api.error.rdbSecretAccessTokenMissing': 'ResilioDB API is not callable for the moment. Our team has been informed. If you need immediate assistance, contact &emergency()',

	// Not Found
	'api.error.notFound': '&obj() not found',
	'api.error.company.notFound': 'Company not found',
	'api.error.user.notFound': 'User not found',
	'api.error.lambda.notFound': 'Lambda user not found',
	'api.error.study.notFound': 'Study not found',
	'api.error.study.name.notProvided': 'You must provide a name for the study',
	'api.error.dataset.notFound': 'Perimeter not found',
	'api.error.dataset.name.notProvided': 'You must provide a name for the perimeter',
	'api.error.resultLauncher.notFound': 'Result launcher not found',
	'api.error.resultList.notFound': 'Result list not found',
	'api.error.equipment.notFound': 'Equipment not found',
	'api.error.equipmentType.notFound': 'Equipment type not found',
	'api.error.equipmentCategory.notFound': 'Equipment category not found',
	'api.error.factorSet.notFound': 'Factor set not found',
	'api.error.block.notFound': 'Block not found',
	'api.error.email.notFound': 'Email not found',
	'api.error.provider.notFound': 'Provider not found',
	'api.error.dashboard.notFound': 'Dashboard not found',
	'api.error.userDashboard.notFound': 'User dashboard not found',
	'api.error.functionalUnit.notFound': 'Functional unit not found',
	'api.error.lcaTemplate.notFound': 'LCA Template not found',
	'api.error.lcaTemplate.privateButNoCompany': 'A private LCA template must be linked to a company',
	'api.error.lcaInventory.notFound': 'LCA Inventory not found',
	'api.error.lcaMaturity.notFound': 'LCA Maturity not found',
	'api.error.cmdb.notFound': 'Cmdb &name() not found',
	'api.error.gpInstance.notFound': 'Good practice instance not found',
	'api.error.sample.notFound': 'Sample not found',
	'api.error.setting.notFound': 'Setting not found',

	'api.error.user.notActive': 'User not active',

	// Login
	'api.error.login.wrongCredentials': 'Authentication failed: wrong email or password. Please try again.',
	'api.error.login.passwordsNotMatch': 'Passwords do not match',
	'api.error.login.wrongPassword': 'Wrong password',
	'api.error.login.passwordSameAsOld': 'New password is the same as the old one',
	'api.error.login.passwordInvalid': 'Error when validating password: &error()',
	'api.error.login.passwordResetTokenExpired': 'Password reset token expired',
	'api.error.tooManyAttempts.15min': 'Too many attempts. Please try again in 15 minutes.',
	'api.error.tooManyAttempts.1hour': 'Too many attempts. Please try again in 1 hour.',
	'api.error.lambdaUser.linkExpired': 'User not linked to an IP',

	// Token
	'api.error.token': 'No token',
	'api.error.token.expired': 'Token expired',
	'api.error.token.invalid': 'Invalid token',
	'api.error.token.notAccepted': 'Token not accepted',
	'api.error.email.invalid': 'Invalid email',

	// Lambda
	'api.error.link.expired': 'Link expired',
	'api.error.link.mismatch': 'Link IP mismatch',
	'api.error.lambda.tooManyAttempts': 'Too many attempts',
	'api.error.lambda.codeInvalid': 'Invalid code',
	'api.error.lambda.codeExpired': 'Code expired',

	// Manage
	'api.error.manage.user.email.exists': 'Email already exists',
	'api.error.manage.user.groups.empty': 'You must select at least one group',
	'api.error.lambdaUser.noDomain': 'No domain for your companies',
	'api.error.lambdaUser.invalidDomain': 'Invalid email domain',
	'api.error.resultList.types.notProvided': 'Result types must be provided on list creation or update',
	'api.error.resultList.exists': 'Result list already exists',
	'api.error.removeAccount.failed': 'Failed to remove account: &error()',
	'api.error.removeAccount.protected': 'Your account is protected because you have signed the CGU. &break() Please contact our team at &support().',

	// CMDB
	'api.error.cmdb.studyAndMultipleDatasets': 'You have to choose between adding the CMDB to the study or to multiple perimeters',
	'api.error.cmdb.datasetIdMissing': 'Perimeter ID is missing',
	'api.error.cmdb.createInstancesFailed': 'Failed to create CMDB instances: &error()',
	'api.error.cmdb.category.notFound': 'This category does not exist: &category()',
	'api.error.cmdb.model.notFound': 'Model not found for identifier: &identifier()',
	'api.error.cmdb.addEquipmentFailed': 'Failed to add equipment. We already informed Resilio support team. Your consultant and our support will come back to you within 48h. If you need immediate assistance, contact &emergency()',
	'api.error.cmdb.addEquipmentTypeFailed': 'Failed to add equipment type. We already informed Resilio support team. Your consultant and our support will come back to you within 48h. If you need immediate assistance, contact &emergency()',
	'api.error.cmdb.noCmdbAdded': 'No CMDB added. We already informed Resilio support team. Your consultant and our support will come back to you within 48h. If you need immediate assistance, contact &emergency()',
	'api.error.cmdb.emptyData': 'CMDB data is empty',
	'api.error.cmdb.invalidColumns': 'Invalid CMDB data. Wrong columns: &columns()',
	'api.error.cmdb.missingColumns': 'Invalid CMDB data. Missing columns: &columns()',
	'api.error.cmdb.invalidDataColumns': 'Invalid CMDB data. Wrong values in columns: &columns()',
	'api.error.cmdb.invalidDataBlock': 'Invalid CMDB data in block column. Wrong values: &values()',
	'api.error.cmdb.dataParsingFailed': 'Failed to parse your CMDB data: &error()',
	'api.error.cmdb.relatedToResults': 'You cannot delete this CMDB because it is related to a result',

	// Company
	'api.error.company.date.format': 'Invalid date format. It should be: yyyy-mm-dd',
	'api.error.company.email.empty': 'Email domains cannot be empty',

	// Compute
	'api.error.compute.noResultList': 'Result list is empty',
	'api.error.compute.noResultToCompute': 'No result to compute after checking',
	'api.error.compute.definitiveResults': 'The perimeter already has definitive results',
	'api.error.compute.inconsistentImpacts': 'Impacts are not consistents. Missing impacts for equipments: &break() &error()',
	'api.error.compute.launchFailed': 'Failed to launch computation',

	// Dataset
	'api.error.dataset.delete.sample': 'You can\'t delete a perimeter that is used as a benchmark',
	'api.error.dataset.delete.definitive': 'You can\'t delete a perimeter that contain definitive results',
	'api.error.dataset.template.studyFunctionalUnitMismatch': 'Study functional unit do not match the one in the template',
	'api.error.dataset.template.creationFailed': 'Failed to create perimeter from template: &error()',
	'api.error.dataset.hasDefinitiveResults': 'You can\'t edit a perimeter that contain definitive results',
	'api.error.dataset.archived': 'Perimeter is archived and cannot be edited',
	'api.error.dataset.nameExists': 'Perimeter name already exists',
	'api.error.dataset.companyMismatch': 'The company of the new study does not match the company of the perimeter',
	'api.error.dataset.collectClosed': 'You cannot edit this perimeter because the collect is closed. Open it / Ask your consultant to open it if you need to import data. If needed, contact &emergency()',
	'api.error.datasets.collectClosed': 'The data collection phase is closed for perimeters: &datasets(). Open it / Ask your consultant to open it if you need to import data. If needed, contact &emergency()',
	'api.error.dataset.validationClosed': 'You cannot edit this perimeter because the validation is closed',
	'api.error.dataset.id.required': 'Cannot resolve perimeters, no perimeter or study ID provided',
	'api.error.dataset.label.notFound': 'Label not found for perimeter',
	'api.error.dataset.copyError': 'Fail to copy perimeter: &error()',

	// Dashboard
	'api.error.dashboard.datasetIds': 'Error fetching perimeter IDs from dashboard: &error()',
	'api.error.dashboard.invalidUsers': 'One or more selected users do not have access to the perimeters associated with the dashboard.',
	'api.error.userDashboard.invalidJson': 'Invalid JSON data in user dashboard card',
	'api.error.invalidDataset': 'Wrong data format in dashboard',
	'api.error.dashboard.idNotFound': 'No ID found in the dashboard',

	// Equipment
	'api.error.equipment.datasetInBenchmark': 'You cannot delete this equipment because it is used in a benchmark',
	'api.error.equipment.delete.noUser': 'Failed to delete equipment: cannot verify user permission',
	'api.error.equipment.delete.failed': 'Failed to delete equipment',

	// Equipment Type
	'api.error.equipmentType.defaultLifetimeRequired': 'A default lifetime is required for service',
	'api.error.equipmentType.noImpactFactors': 'No impact factors received for this equipment',
	'api.error.equipmentType.deletion.failed': 'Failed to delete equipment type',
	'api.error.equipmentType.update.failed': 'Failed to update equipment type',

	// Export
	'api.error.export.latex.tooManyStudies': 'You cannot export as LaTex while combining more than 1 study.',
	'api.error.export.latex.noData': 'Error when collecting data for &result_type() in &lang(): &error()',
	'api.error.export.impacts.noInventoryExtraData': 'Error when extracting LCA parameters data',

	// LCA Template
	'api.error.lcaTemplate.functionalUnitMismatch': 'Functional unit mismatch with the template',
	'api.error.lcaTemplate.functionalUnitMissing': 'Functional unit missing in the template',

	// Maturity
	'api.error.maturity.noAnalysis': 'This perimeter do not have maturity analysis',
	'api.error.maturity.alreadyExists': 'This perimeter already have a maturity good practices',
	'api.error.maturity.noTemplate': 'This perimeter already have a maturity good practices',
	'api.error.maturity.templateNoMaturity': 'This related template does not contains any maturity good practices',

	// Resilio Database
	'api.error.rdb.callFailed': 'Call to ResilioDatabase failed: Status &status()',
	'api.error.rdb.category.notFound': 'Invalid category: &category()',
	'api.error.rdb.missingField': 'Invalid data, missing infos for field : &field()',
	'api.error.rdb.configuration.notFound': 'No configuration found for this equipment in response',

	// Result launcher
	'api.error.resultLauncher.delete.definitive': 'You cannot delete a perimeter with definitive version of result',
	'api.error.resultLauncher.edit.definitive': 'You cannot edit a perimeter with definitive version of result',
	'api.error.resultLauncher.invalidInventoryExtraSnapshot': 'Invalid JSON in inventory extra snapshot',

	// Result
	'api.error.queryResults.wrongParams': 'You must pass either rl_id or results_ids argument',

	// Study
	'api.error.study.archived': 'Study is archived and cannot be edited',
	'api.error.study.consultant.notAllowed': 'This user &email() cannot be added to the study',
	'api.error.study.label.notFound': 'Label not found for study',

	// Sample
	'api.error.sample.edit.alreadyUsed': 'You cannot modify a benchmark that is used in results',
	'api.error.sample.delete.alreadyUsed': 'You cannot delete this sample because it\'s used in a validation or a result',

	// Mutation Failed
	'api.error.socialAuth.failed': 'Social authentication failed: &error()',
	'api.error.passwordReset.failed': 'Failed to reset password: &error()',
	'api.error.passwordChange.failed': 'Failed to change password: &error()',
	'api.error.acceptCgu.failed': 'Failed to accept CGU: &error()',
	'api.error.sendNotification.failed': 'Failed to send notification: &error()',
	'api.error.companyToManage.failed': 'Failed to update company to manage: &error()',
	'api.error.email.failed': 'Failed to send email',
	'api.error.requestCalculation.failed': 'Failed to request calculation: &error()',
	'api.error.resultsComparison.failed': 'Failed to compare results: &error()',
	'api.error.export.impacts.failed': 'Failed to export impacts: &error()',
	'api.error.inventoryExtraEdit.failed': 'Failed to edit inventory extra: &error()',
	'api.error.colorManagement.failed': 'Failed to manage colors: &error()',
	'api.error.addEquipmentResilioDb.failed': 'Failed to add equipment from ResilioDatabase: &error()',
	'api.error.addEquipmentCmdbFailed.failed': 'Failed to add equipment from CMDB: &error()',
	'api.error.equipmentTypeManagement.failed': 'Failed to manage equipment type: &error()',
	'api.error.sample.unifyDatasetsLCAParams.failed': 'Failed to unify LCA parameters',
	'api.error.manage.user.removeAccount.failed': 'Failed to remove account',

	// Query Failed
	'api.error.lambdaVerifyLink.failed': 'Resolve lambda verify link failed: &error()',
	'api.error.lambdaVerifyCode.failed': 'Fail to verify lambda code: &error()',
	'api.error.queryUser.failed': 'Fail to resolve user: &error()',
	'api.error.queryMyCompany.failed': 'Fail to resolve company: &error()',

};
