import { AdapterContract } from '../domain/core/AdapterContract';
import {
	IDataBlocksDTO,
	IDataDatasetsDTO, IDataDocumentationDTO,
	IDataIndicatorsDTO,
	IDataInventoryDTO,
	IDataLambdaInventoryDTO,
	IDataLcaDTO,
	IDataLifeCycleStepsDTO,
	IDataManageEquipmentDTO,
	IDataMaturityDTO,
	IDataModalDTO,
	IDataResultLaunchersDTO,
	IDataResultsDTO,
	IDataSampleDTO,
	IDataSettingsDTO,
	IDataStaticPageDTO,
	IDataStudiesDTO,
	IDataValidationDTO
} from '../domain/interfaces/DTO/IDataDTO';
import { IUserDTO } from '../domain/interfaces/DTO/IUserDTO';
import {
	localStorageGetLang,
	localStorageGetToken,
	localStorageSetLang,
	localStorageSetToken
} from './LocalStorage/LocalStorageAdapter';
import {
	changeNotification,
	changeBlocksStatusData,
	changeCmdbDataStatusData,
	changeCompany,
	changeDashboardStatusData,
	changeDatasetsStatusData,
	changeError,
	changeIndicatorsStatusData,
	changeInventoryStatusData,
	changeLambdaInventoryStatusData,
	changeLcaStatusData,
	changeLifeCycleStepsStatusData,
	changeLogged,
	changeManageEquipmentStatusData,
	changeMaturityStatusData,
	changeModalStatusData,
	changeResultLaunchersStatusData,
	changeResultsStatusData,
	changeSampleListStatusData,
	changeSettingsStatusData,
	changeStaticPageStatusData,
	changeStudiesStatusData,
	changeUserAssignments,
	changeUserDashboardStatusData,
	changeUserNotification,
	changeValidationStatusData,
	setTemporaryAssignedUsersEmail, changeDocumentationDataStatusData,
} from '../store/dispatchers';
import { ICompanyDTO } from '../domain/interfaces/DTO/ICompanyDTO';
import { UserAssignmentsEntity } from '../domain/entities/UserAssignmentsEntity';
import { INotification } from '../domain/interfaces/INotification';
import { IUserNotification } from '../domain/interfaces/IUserNotification';
import { IUserDashboardDTO } from '../domain/interfaces/DTO/IUserDashboardDTO';
import { IDashboardDTO } from '../domain/interfaces/DTO/IDashboardDTO';
import EventEmitter from 'events';
import { IDataCmdbDTO } from '../domain/interfaces/DTO/IDataDTO';
import { ApiErrorJson } from '../domain/interfaces/ApiErrorJson';

export class Adapter implements AdapterContract {
	storeEvent: EventEmitter = new EventEmitter();

	getToken(): Promise<string> {
		return localStorageGetToken();
	}

	setToken(token: string): Promise<boolean> {
		return localStorageSetToken(token);
	}

	getLang(): string {
		return localStorageGetLang();
	}

	setLang(lang: string): Promise<boolean> {
		return localStorageSetLang(lang);
	}

	// View Storage
	async storeUserDTO(status: IUserDTO): Promise<void> {
		changeLogged(status);
	}

	async storeCompanyDTO(status: ICompanyDTO): Promise<void> {
		changeCompany(status);
	}

	async storeDataStudies(dto: IDataStudiesDTO): Promise<void> {
		changeStudiesStatusData(dto);
	}

	async storeDataDatasets(dto: IDataDatasetsDTO): Promise<void> {
		changeDatasetsStatusData(dto);
	}

	async storeDataSettings(dto: IDataSettingsDTO): Promise<void> {
		changeSettingsStatusData(dto);
	}

	async storeDataInventory(dto: IDataInventoryDTO): Promise<void> {
		changeInventoryStatusData(dto);
	}

	async storeDataManageEquipment(dto: IDataManageEquipmentDTO): Promise<void> {
		changeManageEquipmentStatusData(dto);
	}

	async storeDataMaturity(dto: IDataMaturityDTO): Promise<void> {
		changeMaturityStatusData(dto);
	}

	async storeDataResults(dto: IDataResultsDTO): Promise<void> {
		changeResultsStatusData(dto);
	}

	async storeDataResultLaunchers(dto: IDataResultLaunchersDTO): Promise<void> {
		changeResultLaunchersStatusData(dto);
	}

	async storeDataIndicators(dto: IDataIndicatorsDTO): Promise<void> {
		changeIndicatorsStatusData(dto);
	}

	async storeDataLifeCycleSteps(dto: IDataLifeCycleStepsDTO): Promise<void> {
		changeLifeCycleStepsStatusData(dto);
	}

	async storeDataLca(dto: IDataLcaDTO): Promise<void> {
		changeLcaStatusData(dto);
	}

	async storeDataSamples(dto: IDataSampleDTO): Promise<void> {
		changeSampleListStatusData(dto);
	}

	async storeDataModal(dto: IDataModalDTO): Promise<void> {
		changeModalStatusData(dto);
	}

	async storeDataBlocks(dto: IDataBlocksDTO): Promise<void> {
		changeBlocksStatusData(dto);
	}

	async storeDataValidation(dto: IDataValidationDTO): Promise<void> {
		changeValidationStatusData(dto);
	}

	async storeStaticPage(dto: IDataStaticPageDTO): Promise<void> {
		changeStaticPageStatusData(dto);
	}

	async storeUserAssignments(entity: UserAssignmentsEntity): Promise<void> {
		changeUserAssignments(entity);
	}

	async storeLambdaInventory(dto: IDataLambdaInventoryDTO): Promise<void> {
		changeLambdaInventoryStatusData(dto);
	}

	async storeError(error: Error | null): Promise<void> {
		changeError(error);
	}

	async storeUserNotification(notification: IUserNotification): Promise<void> {
		changeUserNotification(notification);
	}

	async storeNotification(notification: ApiErrorJson | INotification | undefined): Promise<void> {
		changeNotification(notification);
	}

	async storeUserDashboards(userDashboards: IUserDashboardDTO): Promise<void> {
		changeUserDashboardStatusData(userDashboards);
	}

	async storeDashboards(dto: IDashboardDTO): Promise<void> {
		changeDashboardStatusData(dto);
	}

	async storeCmdbData(dto: IDataCmdbDTO): Promise<void> {
		changeCmdbDataStatusData(dto);
	}

	async storeTemporaryAssignedUsersEmails(emails: string[]): Promise<void> {
		setTemporaryAssignedUsersEmail(emails);
	}

	async storeDocumentation(dto: IDataDocumentationDTO): Promise<void> {
		changeDocumentationDataStatusData(dto);
	}
}
