import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useResults } from '../../viewComponents/hooks/useResults';
import { useIndicators } from '../../viewComponents/hooks/useIndicators';
import { translate } from '../../infrastructure/translations/translate';
import { useTooltip } from '../../viewComponents/tip/useTooltip';
import { Result } from '../../domain/data/entries/Result';
import { IResultType } from '../../domain/interfaces/IResultType';
import { ISampleListItem } from '../../domain/interfaces/ISample';
import { getMedian } from '../../utils/getMedian';
import { resultValue } from '../../viewComponents/results/utils/resultValue';
import { ToolTipLight } from '../../viewComponents/tip/ToolTipLight/ToolTipLight';
import Spinner from '../../viewComponents/utils/Spinner/Spinner';
import { ResultComparisonImpossible } from './ResultsView';
import { CORP_COLORS } from '../../config';
import { useSampleResultComparison } from '../../viewComponents/modal/ResultComparisonModal/ResultComparisonModal';
import { useModal } from '../../viewComponents/modal/useModal';
import { closeGlobalModal } from '../../viewComponents/modal/GlobalModal';
import { IIndicator } from '../../domain/interfaces/IIndicator';

type IndicatorData = {
	indicator: string,
	unit: string,
	value: number,
	mean: number,
	min: number,
	max: number,
	order?: number,
}

/**
 * One item of the ResultCarousel
 *
 * @param indicatorData: IndicatorData
 * @param title: string
 * @param icon: IconProp
 *
 * @author Yacine Bentayeb && Tanguy Pauvret
 */
export const CarouselItem = ({ indicatorData, title, icon }: ({ indicatorData: IndicatorData, title: string, icon?: string })): JSX.Element => {
	const [tooltip, setTooltip] = React.useState<React.ReactElement | string>();
	useTooltip(tooltip !== undefined, tooltip ?? '');

	let meanPercent = 0;

	const medianResult = getMedian(
		[indicatorData.value],
		indicatorData.unit,
		indicatorData.mean ? [indicatorData.mean, indicatorData.min, indicatorData.max] : []
	);
	indicatorData.value = medianResult.values[0];
	indicatorData.unit = medianResult.units;

	const gradientStyle: { backgroundImage?: string } = {};
	let cursorPosition = 0;

	if (indicatorData.mean) {
		const valuesMeans = medianResult.valuesMeans?.map((value: number) => value ?? 0);
		indicatorData.mean = valuesMeans ? valuesMeans[0] : 0;
		indicatorData.min = valuesMeans ? valuesMeans[1] : 0;
		indicatorData.max = valuesMeans ? valuesMeans[2] : 0;
		cursorPosition = (indicatorData.value - indicatorData.min) / (indicatorData.max - indicatorData.min) * 100;

		const range = indicatorData.max - indicatorData.min;
		meanPercent = ((indicatorData.mean - indicatorData.min) / range) * 100;
		gradientStyle.backgroundImage = `linear-gradient(to right, var(--user-styling-color-darken-mary), yellow ${meanPercent}%, var(--user-styling-color-danger-mary))`;

	}

	const isCompared = indicatorData.mean && indicatorData.min && indicatorData.max ? true : false;
	const moreThanMax = indicatorData.value > indicatorData.max;
	const lessThanMin = indicatorData.value < indicatorData.min;
	const isOutOfRange = moreThanMax || lessThanMin;

	return (
		<div className={`carousel_item ${isCompared ? '' : 'not_compared'}`}>
			{icon && <div className={'carousel_item_icon'} dangerouslySetInnerHTML={{ __html: icon }}/>}
			<div className={'carousel_item_head'}>
				<p>{title}</p>
			</div>
			{!indicatorData.unit || !indicatorData.value
				? <Spinner/>
				: <div className={'carousel_item_body'}>
					<p className={'item_value'}>{resultValue(indicatorData.value)}</p>
					<p className={'item_unit'}>{indicatorData.unit}</p>
				</div>
			}

			{isCompared && <div className={'carousel_item_footer'}>

				<p className={`item_min ${lessThanMin ? 'less_than_min' : ''}`}>
					{lessThanMin && <i
						className="fa-regular fa-circle-info"
						onMouseEnter={() => setTooltip(translate('results.carousel.lessThanMin'))}
						onMouseLeave={() => setTooltip(undefined)}
					/>}
					{translate('results.min')} {resultValue(indicatorData.min)}
				</p>

				<div className={'carousel_item_gradient_bar_wrapper'}>
					<div className={'carousel_item_gradient_bar'} style={gradientStyle}>
						{!isOutOfRange && <>
							<div className={'carousel_item_cursor perimeter_cursor'} style={{ left: `calc(${cursorPosition}%)` }}/>
							<p className={'perimeter_text'} style={{ left: `calc(calc(${cursorPosition}%) - 10px)` }}>{translate('you')}</p>
						</>}
						<div className={'carousel_item_cursor mean_cursor'} style={{ left: `calc(${meanPercent}%)` }} />
						<p className={'mean_text'} style={{ left: `calc(calc(${meanPercent}%) - 12px)` }}>{translate('results.mean')} <br/> {resultValue(indicatorData.mean)}</p>
					</div>
				</div>

				<p className={`item_max ${moreThanMax ? 'more_than_max' : ''}`}>
					{translate('results.max')} {resultValue(indicatorData.max)}
					{moreThanMax && <i
						className="fa-regular fa-circle-info"
						onMouseEnter={() => setTooltip(translate('results.carousel.moreThanMax'))}
						onMouseLeave={() => setTooltip(undefined)}
					/>}
				</p>

			</div>}
		</div>
	);
};
/**
 * Component to display a carousel of results
 * @param result: Result (all results that can be displayed in carousel)
 * @param resultTypes: IResultType[] (list of all result types)
 * @param canChangeResult: boolean (if the user can change the result)
 * @param sampleList: ISampleListItem[] (list of all samples)
 * @param index: string (index of the result)
 *
 * @author Yacine Bentayeb && Tanguy Pauvret
 */
export const ResultCarousel = ({ results, resultTypes, canChangeResult, sampleList, index }: {
	results: Result[],
	resultTypes: IResultType[],
	canChangeResult: boolean,
	sampleList: ISampleListItem[],
	index: string,
}): JSX.Element => {
	const { entity: resultsEntity } = useResults();
	const { selectedIndicators } = useIndicators();

	const [startIndex, setStartIndex] = useState<number>(0);
	const [lastIndex, setLastIndex] = useState<number>(0);
	const [maxIndex, setMaxIndex] = useState<number>(selectedIndicators.length);
	const [selectedResultIndex, setSelectedResultIndex] = useState<number>(0);
	const [displayMethodology, setDisplayMethodology] = useState<boolean>(false);
	const [toolTipPos, setToolTipPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(false);
	const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState<boolean>(true);
	const [openComparison, setOpenComparison] = useState<boolean>(false);
	const [queryResultsCompared, setQueryResultsCompared] = useState(false);
	const [isBoxChecked, setIsBoxChecked] = useState<boolean>(false);
	const [toolTipText, setToolTipText] = useState<string | null>(null);

	useTooltip(toolTipText !== null, toolTipText);
	
	// change item to display depending on screen size
	const itemToDisplay = window.innerWidth > 1550 ? 4 : window.innerWidth > 1250 ? 3 : 2;
	const result = results[selectedResultIndex];
	const data = JSON.parse(result.data);
	const methodology = resultTypes.find((resultType) => resultType.name === result.type)?.methodology || '';

	const handleComparison = (resultsIds: string[], sampleId?: string) => {
		setQueryResultsCompared(true);
		resultsEntity?.mutateResultsComparison({ resultsIds, sampleId }).then(() => {
			setQueryResultsCompared(false);
			setOpenComparison(false);
			closeGlobalModal();
		});
	};
	
	useModal(openComparison, useSampleResultComparison(
		openComparison,
		[result.id],
		setOpenComparison,
		sampleList, 
		handleComparison,
		queryResultsCompared,
		setToolTipText
	));

	const handleResultChange = () => {
		setIsBoxChecked(!isBoxChecked);
		const index = results.findIndex((res: Result) => res.id === result.id);
		if (index !== -1) {
			setSelectedResultIndex((index + 1) % results.length);
		}
	};

	// get the first value of the last index
	useEffect(() => {
		if (startIndex + itemToDisplay <= maxIndex)
			setLastIndex(startIndex + itemToDisplay);
		else
			setLastIndex(maxIndex);

	}, [maxIndex]);

	useEffect(() => {
		setMaxIndex(selectedIndicators.length);
		if (selectedIndicators.length <= itemToDisplay) {
			setIsNextButtonDisabled(true);
			setIsPrevButtonDisabled(true);
		} else {
			setIsNextButtonDisabled(false);
		}
	}, [selectedIndicators.length]);

	// change all indicator in data.data to indicators related name
	data.data.map((item: IndicatorData) => {
		const indicator = selectedIndicators.find((indicator: IIndicator) => indicator.id === item.indicator);
		item.indicator = indicator?.shortName || item.indicator;
		item.unit = indicator?.unit || item.unit;
		item.order = indicator?.order;
		return item;
	});

	data.data = data.data.sort((a: IndicatorData, b: IndicatorData) => {
		// Check if 'order' is defined for both items (otherwise it could lead to unexpected ordering)
		if (typeof a.order === 'number' && typeof b.order === 'number') {
			return a.order - b.order;
		} else if (typeof a.order === 'number') {
			// Place items with defined 'order' before those without
			return -1;
		} else if (typeof b.order === 'number') {
			// Place items with defined 'order' before those without
			return 1;
		}
		return 0;
	});

	// update the indexes when clicking on the next button
	const handleNext = () => {
		setIsPrevButtonDisabled(false);
		if (lastIndex + itemToDisplay < maxIndex) {
			setStartIndex((prevIndex) => prevIndex + itemToDisplay);
			setLastIndex((prevIndex) => prevIndex + itemToDisplay);
		} else {
			setStartIndex(maxIndex - itemToDisplay);
			setLastIndex(maxIndex);
			setIsNextButtonDisabled(true);
		}
	};

	// update the indexes when clicking on the previous button
	const handlePrev = () => {
		setIsNextButtonDisabled(false);
		if (startIndex - itemToDisplay > 0) {
			setStartIndex((prevIndex) => prevIndex - itemToDisplay);
			setLastIndex((prevIndex) => prevIndex - itemToDisplay);
		} else {
			setStartIndex(0);
			setLastIndex(0 + itemToDisplay);
			setIsPrevButtonDisabled(true);
		}
	};

	// Keep only indicators selected
	// Get the list of items to display according to the index set
	let carouselItems = data.data
		.filter((item: IndicatorData) => selectedIndicators.find(i => i.shortName === item.indicator))
		.slice(startIndex, lastIndex)
		.map((indicatorData: IndicatorData, idx: number) => (
			<CarouselItem
				key={idx}
				indicatorData={indicatorData}
				title={selectedIndicators.find((indicator) => indicator.shortName === indicatorData.indicator)?.name || ''}
				icon={selectedIndicators.find((indicator) => indicator.shortName === indicatorData.indicator)?.icon || ''}
			/>)
		);

	const canBeCompared = result.type !== 'TotalFootprint';
	const comparisonImpossible = result.sample != null && !result.data.includes('mean');

	if (comparisonImpossible) {
		carouselItems = <ResultComparisonImpossible/>;
	}

	return (<>
		<div className={'result_carousel_container'} key={`carousel_${index}`}>
			<div className='result_carousel_header'>
				{canChangeResult
					? <div className={'result_carousel_title'}>
						<h5 className={`${isBoxChecked ? '' : 'current_title'}`}>{translate('results.TotalFootprint') as string}</h5>
						<input
							type={'checkbox'}
							title={translate(isBoxChecked ? 'results.carousel.displayPerUser' : 'results.carousel.displayTotalFootprint') as string}
							aria-label={translate(isBoxChecked ? 'results.carousel.displayPerUser' : 'results.carousel.displayTotalFootprint') as string}
							className={'button_none'}
							onClick={handleResultChange}
						/>
						<h5 className={`${isBoxChecked ? 'current_title' : ''}`}>{translate('results.title.FootprintPerFunctionalUnit') as string}</h5>
					</div>
					: <h5>{translate(`results.title.${result.type}`) as string}</h5>
				}

				{result.relatedToSample != 2 && canBeCompared && <button
					type={'button'}
					className={'button_none'}
					onClick={() => setOpenComparison(true)}
				>
					<i className="fa-solid fa-scale-balanced"
						color={result.sample != null ? 'black' : 'grey'}
						title={translate('results.compareResults') as string}
					/>
				</button>}

				{methodology !== '' && <>
					<i
						onMouseEnter={(e) => {
							setDisplayMethodology(true);
							setToolTipPos({ x: e.clientX, y: e.clientY });
						}}
						onMouseLeave={() => setDisplayMethodology(false)}
						className={'info_icon pointer-events-none fa-regular fa-circle-question'}
					/>

					{displayMethodology &&
							<ToolTipLight style={{ top: toolTipPos.y + 10, left: toolTipPos.x + 10 }}>
								<ReactMarkdown>{methodology}</ReactMarkdown>
							</ToolTipLight>
					}
				</>}

				{displayMethodology && methodology != '' &&
					<ToolTipLight style={{ top: toolTipPos.y + 10, left: toolTipPos.x + 10 }}>
						<ReactMarkdown>{methodology}</ReactMarkdown>
					</ToolTipLight>
				}

			</div>
			{result.sample && <p className={'result_compared_title mb'} style={{ marginBottom: '30px' }}>
				{translate('result.comparedTo', { compare: () => result.sample?.name ?? '' })}
			</p>}
			<div className={'result_carousel'}>
				<button disabled={isPrevButtonDisabled} onClick={handlePrev}
					style={{ visibility: isPrevButtonDisabled ? 'hidden' : 'visible' }}
					aria-label={translate('results.nextCarouselPicture') as string}>
					<i
						className="fa-solid fa-chevron-left"
						color={CORP_COLORS.gray}
					/>
				</button>
				{carouselItems}
				<button disabled={isNextButtonDisabled} onClick={handleNext}
					style={{ visibility: isNextButtonDisabled ? 'hidden' : 'visible' }}
					aria-label={translate('results.previousCarouselPicture') as string}>
					<i
						className="fa-solid fa-chevron-right"
						color={CORP_COLORS.gray}
					/>
				</button>
			</div>
		</div>
	</>);
};

export const ResultCarouselGlobalNormalized = ({ result, sampleList }: { result: Result, sampleList: ISampleListItem[] }): JSX.Element => {
	const [perUser, setPerUser] = useState<boolean>(false);
	const [openComparison, setOpenComparison] = useState<boolean>(false);
	const [queryResultsCompared, setQueryResultsCompared] = useState(false);
	const [toolTipText, setToolTipText] = useState<string | null>(null);
	const { entity: resultsEntity } = useResults();
	
	const handleComparison = (resultsIds: string[], sampleId?: string) => {
		setQueryResultsCompared(true);
		resultsEntity?.mutateResultsComparison({ resultsIds, sampleId }).then(() => {
			setOpenComparison(false);
			closeGlobalModal();
		}).finally(() => {
			setQueryResultsCompared(false);
		});
	};

	useModal(openComparison, useSampleResultComparison(
		openComparison,
		[result.id],
		setOpenComparison,
		sampleList, 
		handleComparison,
		queryResultsCompared,
		setToolTipText
	));

	useTooltip(toolTipText !== null, toolTipText);


	const data = JSON.parse(result.data);


	return (
		<div className={'result_carousel_container'}>
			<div className={'result_carousel_header'}>
				<div className={'result_carousel_title'}>
					<h5 className={`${perUser ? '' : 'current_title'}`}>{translate('results.TotalFootprint') as string}</h5>
					<input
						type={'checkbox'}
						title={translate(perUser ? 'results.carousel.displayPerUser' : 'results.carousel.displayTotalFootprint') as string}
						aria-label={translate(perUser ? 'results.carousel.displayPerUser' : 'results.carousel.displayTotalFootprint') as string}
						className={'button_none'}
						onClick={() => setPerUser(!perUser)}
					/>
					<h5 className={`${perUser ? 'current_title' : ''}`}>{translate('results.title.FootprintPerFunctionalUnit') as string}</h5>
				</div>
				{!perUser && <button
					type={'button'}
					className={'button_none'}
					onClick={() => setOpenComparison(true)}
				>
					<i className="fa-solid fa-scale-balanced"
						color={result.sample != null ? 'black' : 'grey'}
						title={translate('results.compareResults') as string}
					/>
				</button>}
			</div>
			<div className={'result_carousel'}>
				<CarouselItem
					indicatorData={{
						indicator: 'PBCI',
						unit: 'PBCI',
						value: perUser ? data.data[0].value / data.data[0].nb_users : data.data[0].value,
						mean: data.data[0].mean && !perUser ? data.data[0].mean : 0,
						min: data.data[0].min && !perUser ? data.data[0].min : 0,
						max: data.data[0].max && !perUser  ? data.data[0].max : 0,
					}}
					title={translate('results.title.GlobalNormalizedResult') as string}
				/>
			</div>
		</div>
	);
};