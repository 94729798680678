import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { translate } from '../../../infrastructure/translations/translate';
import { useStaticPages } from '../../hooks/useStaticPages';
import { Link } from 'react-router-dom';
import { linkGenerator, linkStaticPageById } from '../../../router/linkGenerator';
import { CguModal } from '../../../views/LoginView/CguPages/CguModal';
import { BASE_APP_URL } from '../../../config';

const ContactConsultants = ({ title, path }: { title: string, path: string}): JSX.Element => {
	return (
		<Link className='contact_consultant' to={path} aria-label='contactConsultants'>
			{title}
		</Link>
	);
};

const CGULink = ({ setOpenCguModal }: { setOpenCguModal: (value: boolean) => void }): JSX.Element => {
	return (
		<button onClick={() => setOpenCguModal(true)} className='cgu_link' aria-label='cgu'>
			{translate('cgu')}
		</button>
	);
};

const Documentationlink = () => {
	const text = translate('documentation') as string;
	return (
		<Link to='/documentation' className='documentation_link' aria-label='documentation'>
			{/*Capitalize first letter*/}
			{text.charAt(0).toUpperCase() + text.slice(1)}
		</Link>
	);
};

export const HelpSection = ({ study }: { study: string | undefined }): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [versions, setVersions] = useState({ app_version: '', rdb_version: '', last_update: '' });
	const [openCguModal, setOpenCguModal] = useState(false);
	const { pages } = useStaticPages();
	const helpButtonRef = useRef<HTMLDivElement>(null);
	const helpPanelRef = useRef<HTMLDivElement>(null);

	const generalInfo = pages.filter(page => page.category === 'GENERAL_INFO');
	const appUsage = pages.filter(page => page.category === 'APP_USAGE');
	const advancedDocs = pages.filter(page => page.category === 'ADVANCED_DOCS');

	// Open the panel and set initial position
	const openHelpPanel = () => {
		if (helpButtonRef.current) {
			const rect = helpButtonRef.current.getBoundingClientRect();
			// Initial position
			setPosition({ x: rect.right + 10, y: rect.top });
		}
		setIsOpen(true);
	};

	const handleOpenCguModal = (open: boolean) => {
		setOpenCguModal(open);
		setIsOpen(false);
	};

	// Fetch versions
	useEffect(() => {
		fetch(`${BASE_APP_URL}/versions`).then(async res => {
			const data = await res.json();
			setVersions(data);
		});
	}, []);

	const calculatePosition = () => {
		if (!helpButtonRef.current || !helpPanelRef.current || !isOpen) return;

		const rect = helpButtonRef.current.getBoundingClientRect();
		// Get actual height
		const panelHeight = helpPanelRef.current.offsetHeight || 300;
		// Calculate available space
		const spaceBelow = window.innerHeight - rect.bottom;
		const spaceAbove = rect.top;

		// Default below the "help" button
		let yPosition = rect.top;

		// If not enough space below but enough above, position it above the button
		if (spaceBelow < panelHeight && spaceAbove > panelHeight) {
			yPosition = rect.top - panelHeight;
		} 
		// If neither above nor below have enough space, fit it within the viewport
		else if (spaceBelow < panelHeight) {
			yPosition = Math.max(10, window.innerHeight - panelHeight - 10);
		}
		setPosition({ x: rect.right + 10, y: yPosition });
	};

	// Handle focus trapping inside the help panel
	useEffect(() => {
		// Handle keydown events
		const handleKeyDown = (event: KeyboardEvent) => {	
			if (!isOpen || !helpPanelRef.current) return;

			if (event.key === 'Escape') {
				setIsOpen(false);
				helpButtonRef.current?.focus();
			} else if (event.key === 'Tab') {
				// Get all focusable elements
				const focusableElements = Array.from(
					helpPanelRef.current.querySelectorAll('a, button')
				) as HTMLElement[];

				if (focusableElements.length === 0) return;

				const firstElement = focusableElements[0];
				const lastElement = focusableElements[focusableElements.length - 1];

				if (event.shiftKey && document.activeElement === firstElement) {
					lastElement.focus();
					event.preventDefault();
				} else if (!event.shiftKey && document.activeElement === lastElement) {
					firstElement.focus();
					event.preventDefault();
				}
			}
		};

		// Add event listener when the panel is open
		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
			(helpPanelRef.current?.querySelector('a, button') as HTMLElement)?.focus(); // Focus first item
		} else {
			// Remove event listener when the panel is closed
			document.removeEventListener('keydown', handleKeyDown);
		}

		calculatePosition();

		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [isOpen]);

	// Close the panel if clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isOpen &&
				helpPanelRef.current &&
				!helpPanelRef.current.contains(event.target as Node) &&
				helpButtonRef.current &&
				!helpButtonRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
				helpButtonRef.current?.focus();
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	return (
		<>
			<div
				ref={helpButtonRef}
				title='help'
				aria-label='help'
				className='sidebar_content_subtitle help'
				tabIndex={0}
				onClick={isOpen ? () => setIsOpen(false) : openHelpPanel}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						e.preventDefault();
						openHelpPanel();
					}
				}}
			>
				<i className='fa-regular fa-question' />
				{translate('help')}
			</div>
			{openCguModal && <CguModal setOpenModal={setOpenCguModal} />}
			{isOpen &&
				ReactDOM.createPortal(
					<div
						ref={helpPanelRef}
						className='help_panel'
						style={{ top: `${position.y}px`, left: `${position.x}px` }}
						role='dialog'
						aria-labelledby='help-title'
						aria-modal='true'
					>
						<div className='help_panel_content'>
							<button className='close_button' onClick={() => setIsOpen(false)}>
								<i className='fa-regular fa-times' />
							</button>
							<h2 id='help-title'>{translate('help')}</h2>
							<>
								<ul>
									{generalInfo.map((page) => (
										<Link to={linkStaticPageById(page.id)} key={page.id} aria-label={page.name}>
											{page.name}
										</Link>
									))}
									<ContactConsultants
										title={translate('sidebar.contactConsultants') as string}
										path={linkGenerator(study, null, null, null, 'contact')}
									/>
								</ul>
								<hr />
							</>
							{appUsage.length > 0 && (
								<>
									<ul>
										{appUsage.map((page) => (
											<Link to={linkStaticPageById(page.id)} key={page.id} aria-label={page.name}>{page.name}</Link>
										))}
									</ul>
									<hr />
								</>
							)}
							<>
								<ul>
									<Documentationlink />
									{advancedDocs.map((page) => (
										<Link to={linkStaticPageById(page.id)} key={page.id} aria-label={page.name}>{page.name}</Link>
									))}
									<CGULink setOpenCguModal={handleOpenCguModal}/>
								</ul>
								<hr />
							</>
							{versions.app_version && <p>Resilio Tech {versions.app_version}</p>}
							<p>NegaOctet v1.2 (2024)</p>
							{versions.rdb_version && <p>Resilio dB {versions.rdb_version}</p>}
							{versions.last_update && <p>
								{translate('sidebar.helpSection.lastUpdate', {
									time: () => {
										const lastUpdateDate = new Date(versions.last_update);
										const daysDifference = Math.floor((new Date().getTime() - lastUpdateDate.getTime()) / (1000 * 3600 * 24));
										return String(daysDifference);
									}
								})}
							</p>}
						</div>
					</div>,
					document.body
				)}
		</>
	);
};
