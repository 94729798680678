import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Template from '../viewComponents/template/template/template';
import { linkStaticPage } from './linkGenerator';
import staticPageSideBar from '../../src/views/StaticPageView/StaticPageSideBar';
import RedirectHandlerView from '../views/LoginView/RedirectHandler/RedirectHandlerView';
import { CguForm } from '../views/LoginView/CguPages/CguForm';
import Notifications from '../viewComponents/template/notification/Notifications';

const LoginView = React.lazy(() => import('../views/LoginView/LoginView'));
const PasswordSendResetView = React.lazy(() => import('../views/Password/PasswordSendResetView/PasswordSendResetView'));
const PasswordResetView = React.lazy(() => import('../views/Password/PasswordResetView/PasswordResetView'));
const LambdaUserCodeView = React.lazy(() => import('../views/LambdaUser/LambdaUserCodeView/LambdaUserCodeView'));
const LambdaUserAssignedLines = React.lazy(() => import('../views/LambdaUser/LambdaUserAssigned/LambdaUserAssignedLines'));
const StaticPageView = React.lazy(() => import('../views/StaticPageView/StaticPageView'));
const CguNotAccepted = React.lazy(() => import('../views/LoginView/CguPages/CguNotAccepted'));
const side = staticPageSideBar;

const WithNotifications = ({ children }: { children: JSX.Element }): JSX.Element => {
	return <>{children}<Notifications/></>;
};

const RouterExternal = (): JSX.Element => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/login'} element={<Suspense fallback={<Template loading={true}/>}><WithNotifications><LoginView /></WithNotifications></Suspense>} />
				<Route path={'/sso/redirect'} element={<Suspense fallback={<Template loading={true}/>}><RedirectHandlerView /></Suspense>} />
				<Route path={'/password/forgot'} element={<Suspense fallback={<Template loading={true}/>}><WithNotifications><PasswordSendResetView /></WithNotifications></Suspense>} />
				<Route path={'/password-reset/:token'} element={<Suspense fallback={<Template loading={true}/>}><WithNotifications><PasswordResetView /></WithNotifications></Suspense>} />
				<Route path={'/access/:token'} element={<Suspense fallback={<Template loading={true}/>}><WithNotifications><LambdaUserCodeView /></WithNotifications></Suspense>} />
				<Route path={'/access/:token/edit'} element={<Suspense fallback={<Template loading={true}/>}><WithNotifications><LambdaUserAssignedLines /></WithNotifications></Suspense>} />
				<Route path={'/cgu/notaccepted'} element={<Suspense fallback={<Template loading={true}/>}><CguNotAccepted /></Suspense>} />
				<Route path={'/cgu'} element={<Suspense fallback={<Template loading={true}/>}><CguForm /></Suspense>} />
				<Route path={linkStaticPage} element={<Suspense fallback={<Template loading={true}/>}><Template core={<StaticPageView />} side={side}/></Suspense>} />
				<Route path={'*'} element={<Navigate to={'/login'} />} />
			</Routes>
		</BrowserRouter>
	);
};

export default RouterExternal;
