import React, { useState } from 'react';
import './Tip.css';
import { Alert } from '../../Alerts';
import { translate } from '../../../infrastructure/translations/translate';

interface TipProps {
	children: string | React.ReactElement;
	fullContent?: string | React.ReactElement;
	style?: React.CSSProperties;
	type?: 'danger' | 'success' | 'warning' | 'info' | 'normal';
	icon?: string;
	border?: string;
}
export const Tip = ({
	children,
	fullContent,
	style,
	type,
	icon,
	border
}: TipProps): JSX.Element => {
	const [show, setShow] = useState(true);
	const [showMore, setShowMore] = useState(false);

	if (!show) return <></>;

	border = border ? `solid 2px ${border}` : 'none';

	return (
		<Alert variant={type ?? 'info'} style={{ ...style, border }}>
			<div className={'alert_content'}>
				{icon === 'archive' && <i className="icon_tip fa-solid fa-box-archive"/>}
				<div className='tip_container'>
					<pre className='tip_text' style={{ width: '100%', paddingTop: '5px', whiteSpace: 'break-spaces', fontFamily: 'inherit' }}>
						{fullContent ? (
							showMore ? fullContent : children
						) : (
							children
						)}
					</pre>
					{fullContent && (
						<div className="toggle-button-container">
							<button
								className={'toggle-button'}
								onClick={() => setShowMore(!showMore)}
							>
								{showMore ? translate('see.less') : translate('see.more')}
							</button>
						</div>
					)}
				</div>
			</div>
			<div>
				<button
					aria-label={translate('close') as string}
					type={'button'}
					className={'button_outline'}
					onClick={() => setShow(false)}
				>
					x
				</button>
			</div>
		</Alert>
	);
};