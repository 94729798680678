import React, { useEffect, useState } from 'react';
import './sidebar.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { translate } from '../../../infrastructure/translations/translate';
import { CORP_COLORS } from '../../../config';
import { linkGenerator } from '../../../router/linkGenerator';
import SidebarLogo from './sidebarLogo';
import UserManageViewsSidebar from '../../UserManageViewsSidebar';
import UserSettingsViewsSidebar from '../../UserSettingsViewsSidebar';
import { useUser } from '../../hooks/useUser';
import { useStudies } from '../../hooks/useStudies';
import { useDatasets } from '../../hooks/useDatasets';
import { CguModal } from '../../../views/LoginView/CguPages/CguModal';
import { HelpSection } from './helpSection';

/**
 * sidebarSubtitles : List of sidebar titles and associated path to display those titles dynamically
 */
const setSidebarSubtitles = (firstManagePath: string, firstSettingPath: string): { [key: string]: { title: string, path: string, sub?: string }[] } => {
	return {
		manageList: [
			{
				title: translate('sidebar.manage') as string,
				path: firstManagePath,
			},
			{
				title: translate('sidebar.settings') as string,
				path: firstSettingPath,
			}
		],
		dashboardList: [
			{
				title: translate('sidebar.dashboards') as string,
				path: 'overview',
			},
			{
				title: translate('sidebar.dashboard.evolution') as string,
				path: 'evolution',
			},
			{
				title: translate('sidebar.dashboard.comparison') as string,
				path: 'comparison',
			},
			{
				title: translate('sidebar.dashboard.simulation') as string,
				path: 'simulation',
			}
		],
		list: [
			{
				title: translate('sidebar.inventory') as string,
				path: 'inventory',
				sub: 'settings'
			},
			{
				title: translate('sidebar.maturity') as string,
				path: 'maturity'
			},
			{
				title: translate('sidebar.validation') as string,
				path: 'validation'
			},
			{
				title: translate('sidebar.results') as string,
				path: 'results'
			}
		]
	};
};

export type SideBarContentItemList = {
	content: {
		list: {
			id: string;
			name: string;
			shortName?: string;
			current: boolean;
			link: string;
			priority?: boolean;
		}[]
	}
}

/**
 * This component is used to display on the sidebar the dynamic pages (manage, inventory, maturity, validation, results)
 *
 * @param content : SideBarContentItemList. It contains the list of items to display for the current page.
 *
 * @author : Tanguy Pauvret && Yvan Petruzzi
 */
const SideBarDynamicPages = ({ content }: SideBarContentItemList): JSX.Element => {
	const { data: user } = useUser();
	const route = useLocation();

	const { dataset: datasetId } = useParams<{ dataset: string }>();
	const studyId = useSelector((state: State) => state.studyID);
	const { studies } = useStudies();
	const currentStudy = studies?.find(s => s.id === studyId);
	const { datasets } = useDatasets(currentStudy?.datasetsId ?? []);
	const currentDataset = datasets.find(d => d.id === datasetId);

	// Variables get from the url
	// action : allow us to know on which page we are (manage, inventory, maturity, validation, results)
	// study and dataset : allow us to know on which study and dataset we are
	// page : if defined, we are in manage view
	const {
		action,
		study,
		page,
		dataset,
		dashboard
	} = useParams<{ action: string, study: string, page: string, dataset: string, dashboard: string }>();

	// Get the array of manage views for the current user, and return the link to the first one
	const linkToManage = UserManageViewsSidebar().content.list[0].link;
	const linkToSettings = UserSettingsViewsSidebar().content.list[0].link;

	const sidebarSubtitles = setSidebarSubtitles(linkToManage, linkToSettings);
	// if there's no maturity in the study, remove the maturity item from the sidebar
	if (currentDataset?.metadata.maturityRate === null) {
		sidebarSubtitles.list = sidebarSubtitles.list.filter(s => s.path !== 'maturity');
	}
	// Get the list of subtitles to display according to the current page
	let subTitles;
	if (action) subTitles = sidebarSubtitles.list;
	else if (page) subTitles = sidebarSubtitles.manageList;
	else if (dashboard) subTitles = sidebarSubtitles.dashboardList;
	let currentLocation = action ?? page ?? dashboard ?? 'home';

	// Updating current location depending on the current page
	currentLocation = route.pathname.includes('dashboard') ? currentLocation
		: route.pathname.includes('manage') ? 'manage'
			: route.pathname.includes('settings') ? 'settings'
				: currentLocation === 'overview' ? 'inventory'
					: currentLocation;

	// Check if the user has the right permissions to display only the items he has the right to see
	// Also check if the dataset is ready to display each items
	if (studies && studyId && currentStudy && dataset) {
		const currentDataset = datasets?.find(d => d.id === dataset);
		if (currentDataset) {
			if (currentDataset.metadata === undefined) {
				return <></>;
			}
			const canValidateDataset = currentDataset.permissions.validate;
			const userCanValidate = user?.permissions.includes('login.can_validate_dataset');
			const resultsReady = currentDataset.status.results === 'ready';
			const viewResults = user?.permissions.includes('results.view_result');
			// This filter is used to remove items from the sidebar if the user doesn't have the right permissions
			// Condition maturity is only check for the maturity item for example
			if (subTitles)
				subTitles = subTitles?.filter(s =>
					(s.path !== 'results' || resultsReady && viewResults) &&
					(s.path !== 'validation' || canValidateDataset && userCanValidate)
				);
		}
	}
	// If current location is Manage, we want to check if the user has the right permissions to display the manage page
	// If page is undefined, we are not in manage view
	if (page && currentLocation === 'manage') {
		const canManage = user?.permissions.includes('login.can_access_manage');
		if (subTitles)
			subTitles = subTitles.filter(s => (s.path !== linkToManage || canManage));
	}

	const linkTitle = (subtitle: { title: string, path: string, sub?: string | undefined }) => {
		let link;
		if (route.pathname.includes('manage') || route.pathname.includes('user')) {
			link = subtitle.path;
		} else if (route.pathname.includes('dashboard')) {
			if (subtitle.path === 'overview') link = '/dashboard/overview';
			else link = `/dashboard/${subtitle.path}/new`;
		} else {
			link = linkGenerator(study, subtitle.path, dataset);
		}
		return <Link to={link}>
			<i className={`fa-solid ${subtitle.path.includes(currentLocation) ? 'fa-caret-up' : 'fa-caret-down'}`}/>
			{subtitle.title.toUpperCase()}
		</Link>;
	};

	const hasHash = (target: string):boolean => {
		const hasHash = target.split('#');
		return hasHash.length > 1;
	};

	const scrollToHash = (targetHash: string) => {
		const hasHash = targetHash.split('#');
		if (hasHash.length > 1) {
			const hash = hasHash[1];
			const defineHashElement = () => {
				if (hash) {
					const element = document.getElementById(hash);
					return element;
				} else {
					const element = document.getElementsByClassName('header')[0];
					return element;
				}
			};

			const hashElement = defineHashElement();
			if (hashElement) {
				hashElement.scrollIntoView({
					inline: 'nearest',
				});
			}
		}
	};

	// The hasHash part is there to avoid triggering any scroll event based on hash route
	// If we push the link we are targeting, the current display will bounce all over the place
	// because the link will change to the target and then the link will be updated depending on what
	// category will travel through the screen

	return (<>
		<div className={'sidebar_dynamic_pages'}>
			{subTitles ? subTitles.map((subtitle, index) => {
				return (
					<div className={'sidebar_content_elem'} key={index}>
						<div className={'sidebar_content_title'}>
							{linkTitle(subtitle)}
						</div>
						{subtitle.path.includes(currentLocation) || subtitle.sub?.includes(currentLocation) ?
							content.list.map((list) =>
								<div className={`sidebar_content_subtitle ${list.current ? 'current' : ''}`} key={`${list.id}_${list.name}`}>
									{!hasHash(list.link) && <Link to={list.link}>
										{list.name}
									</Link>}
									{hasHash(list.link) &&
										<a
											href={list.link}
											onClick={(e) => {
												e.preventDefault();
												scrollToHash(list.link);
											}}
										>
											{list.name}
										</a>}
								</div>
							)
							: null}
					</div>);
			}) : null}
		</div>
		{subTitles ? <hr className={'line_separator'}/> : null}
	</>);
};


const SidebarHeadTitle = ({ title, path, icon }: { title: string, path: string, icon?: string }): JSX.Element => {

	return (
		<Link to={path}>
			{icon ? <i
				className={icon}
				color={CORP_COLORS.gray}
			/> : null}
			{title}
		</Link>
	);
};

/**
 *
 * @param content : SideBarContentItemList. It contains the list of items to display for the current page.
 * If you are on the inventory page, the list will contain the items to display in the sidebar for the inventory page.
 *
 * @author : Tanguy Pauvret && Yvan Petruzzi
 */
const Sidebar = ({ content } : SideBarContentItemList ): JSX.Element => {
	const { entity: userEntity, data: user } = useUser();
	const canExplore = user?.permissions.includes('login.can_view_equipment_groupby');
	const canHaveDashboard = user?.permissions.includes('login.can_have_dashboard');
	// Declare a new state variable for navigateTo
	const [navigateTo, setNavigateTo] = useState('');

	const [openCguModal, setOpenCguModal] = useState(false);

	// Get the current URL from React Router
	const location = useLocation();

	// Update navigateTo whenever the URL changes
	useEffect(() => {
		setNavigateTo(location.pathname);
	}, [location]);

	const setFR = () => {
		userEntity?.setLanguage('fr').then(() => window.location.reload());
	};
	const setEN = () => {
		userEntity?.setLanguage('en').then(() => window.location.reload());
	};

	// Get current study and dataset infos
	// Page is a variable found in route pathname. If defined, we are in manage view
	const { page, study } = useParams<{ page: string, study: string }>();
	const { studies } = useStudies();
	const currentStudy = studies?.find(s => s.id === study);

	return (
		<nav className='sidebar' id='nav'>
			<div className={'sidebar_container'}>
				{openCguModal && <CguModal setOpenModal={setOpenCguModal}/>}
				<div className={'sidebar_head'}>
					<SidebarHeadTitle title={translate('sidebar.yourStudies') as string} path={'/'} icon={page ? 'fa-solid fa-arrow-left' : 'fa-solid fa-chart-column'}/>
					{currentStudy && !page ?
						<SidebarHeadTitle title={currentStudy.name} path={`/study/${currentStudy.id}`} icon={'fa-solid fa-folder-open'}/> : null}
					{canExplore && <SidebarHeadTitle
						title={translate('sidebar.explore') as string}
						path={'/explore'}
						icon={'fa-solid fa-search'}
					/>}
					{canHaveDashboard && <SidebarHeadTitle
						title={translate('sidebar.dashboards') as string}
						path={'/dashboard/overview'}
						icon={'fa-regular fa-desktop'}
					/>}
					<hr className={'line_separator'}/>
				</div>
				<div className='sidebar_content'>
					<SideBarDynamicPages content={content}/>
					<HelpSection study={study}/>
				</div>
				<div className={'sidebar_footer'}>
					<div className={'sidebar_static_container'}>
						<div className={'language'}>
							<a href={navigateTo} onClick={setFR} aria-label={'Français'}>fr</a>
							- <a href={navigateTo} onClick={setEN} aria-label={'English'}>en</a>
						</div>
					</div>
					<hr className={'line_separator'}/>
					<SidebarLogo/>
				</div>
			</div>
		</nav>
	);
};


export default Sidebar;
